import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../all.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-sale-proxy-page',
  templateUrl: './sale-proxy-page.component.html',
  styleUrls: ['./sale-proxy-page.component.scss']
})
export class SaleProxyPageComponent implements OnInit {

  deviceData: any = []
  deviceAddForm!: FormGroup
  deviceEditForm!: FormGroup
  deviceFilterForm!: FormGroup
  page: any
  search: any
  addTable = false
  editTable = false
  tableId: any
  isLoading = false
  permitActions = true

  constructor(private request: RequestsService) {}

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.deviceFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      host: new FormControl('', Validators.required),
      port: new FormControl('', Validators.required),
      user: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    })

    this.deviceAddForm = new FormGroup({
      account_id: new FormControl('', Validators.required),
      service_id: new FormControl(1, Validators.required),
      price_cf: new FormControl('', Validators.required),
    })

    this.deviceEditForm = new FormGroup({
      id: new FormControl('', Validators.required),
      account_id: new FormControl('', Validators.required),
      service_id: new FormControl('', Validators.required),
      price_cf: new FormControl('', Validators.required),
    })





  }




  filterTable() {
    const deviceFilterFormData = {...this.deviceFilterForm.value}
    this.isLoading = true
    this.request.getFilterSaleProxyRequest(deviceFilterFormData.id,deviceFilterFormData.type,deviceFilterFormData.name, deviceFilterFormData.host, deviceFilterFormData.port, deviceFilterFormData.host, deviceFilterFormData.port, deviceFilterFormData.host).subscribe( (response: any) => {
      this.deviceData = response.reverse()
      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }

  addNewTable() {
    const deviceAddFormData = {...this.deviceAddForm.value}
    this.isLoading = true
    this.request.postSaleProxyRequest(deviceAddFormData.account_id,deviceAddFormData.service_id, deviceAddFormData.price_cf).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.deviceEditForm.patchValue(this.deviceData.filter( (res: any) => res.id ==  id)[0])
  }

  editNewTable() {
    const deviceEditFormData = {...this.deviceEditForm.value}
    this.isLoading = true
    this.request.putSaleProxyRequest(this.tableId,deviceEditFormData.account_id,deviceEditFormData.service_id, deviceEditFormData.price_cf).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }


  deleteVlanRequest(id: string) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный ID: " + id)
    if(deleteConf == true) {
      this.request.deleteSaleProxyRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }


}




<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="addModal_content" *ngIf="addTable">
  <div class="addModal_block">
    <i class="fas fa-times" (click)="addTable = false"></i>
    <form class="addForm" [formGroup]="accountsAddForm">

      <div class="add_block_items">
        <h1 class="add_title">ФИО</h1>
        <input class="addtable" type="text" formControlName="fio">
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Тарифы</h1>
        <select class="addtable" formControlName="tarif_id">
          <option *ngFor="let tarifs of tarifsData" [value]="tarifs.id" >{{ tarifs.name }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Телефон</h1>
        <input class="addtable" type="number" formControlName="phone_number">
      </div>

        <div class="add_block_items">
          <h1 class="add_title">Дата окончание</h1>
          <input class="addtable" type="date" formControlName="end_date">
        </div>

        <div class="add_block_items">
          <h1 class="add_title">Коэфицент цены</h1>
          <input class="addtable" type="number" formControlName="price_cf">
        </div>

        <div class="add_block_items">
          <h1 class="add_title">Коэфицент скороста</h1>
          <input class="addtable" type="number" formControlName="speed_cf">
        </div>

        <div class="add_block_items">
          <h1 class="add_title">IP Адресс</h1>
          <div class="ip_block">
            <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress1()"  maxlength="3" formControlName="ipaddress1">
            <span class="ip_span">.</span>
            <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress2()" maxlength="3" formControlName="ipaddress2">
            <span class="ip_span">.</span>
            <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress3()" maxlength="3" formControlName="ipaddress3">
            <span class="ip_span">.</span>
            <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress4()" maxlength="3" formControlName="ipaddress4">
          </div>
        </div>

      <div class="add_block_items">
        <h1 class="add_title">IP Адресс</h1>
        <select formControlName="autoIpaddress">
          <option *ngFor="let IPGroup of IPGroupData" [value]="IPGroup.id">{{ IPGroup.ip_start }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Адрес</h1>
        <input class="addtable" type="text" formControlName="acc_info">
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Файрволл</h1>
        <select formControlName="conf_firewall_id">
          <option *ngFor="let fireWall of fireWallData" [value]="fireWall.id">{{ fireWall.firewall_host }} - {{ fireWall.comment }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Паспорт</h1>
        <input class="addtable" type="text" formControlName="passport">
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Год рождения</h1>
        <input class="addtable" type="date" formControlName="age">
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Пол</h1>
        <select formControlName="gender">
          <option value="M">Man</option>
          <option value="F">Female</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Девайс</h1>
        <select formControlName="device">
          <option *ngFor="let device of deviceData" [value]="device.id">{{ device.name }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Овердрафта</h1>
        <select formControlName="overdraft">
          <option value="2">Да</option>
          <option value="1">Нет</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Тип клиента</h1>
        <select formControlName="customr_type">
          <option>B2C</option>
          <!-- <option>B2B</option> -->
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Кто подключил</h1>
        <select formControlName="connect_by">
          <option *ngFor="let item of usersData | userFilter: 4" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Кто привёл</h1>
        <select formControlName="contact_by">
          <option *ngFor="let item of usersData" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Table ID</h1>
        <select formControlName="table_id">
          <option>3</option>
          <option>4</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Тип подключения</h1>
        <select formControlName="connect_type">
          <option *ngFor="let connectType of connectTypeData" [value]="connectType.id">{{ connectType.name }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Акция</h1>
        <select formControlName="promo_id">
          <option></option>
          <option *ngFor="let promotion of promotionData" [value]="promotion.id">{{ promotion.name }}</option>
        </select>
      </div>

      <div class="add_block_items">
        <h1 class="add_title">Onu/Port</h1>
        <input class="addtable" type="text" formControlName="comment">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">ID друга</h1>
        <input class="addtable" type="number" formControlName="friend_id">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Коментария</h1>
        <input class="addtable" type="text" formControlName="comment_1">
      </div>


      <div class="add_block_items">
        <h1 class="add_title">Город</h1>
        <select name="" id=""  formControlName="city_s">
          <option value="Душанбе-А. Сино">Душанбе-А. Сино</option>
          <option value="Душанбе-А. Фирдавси">Душанбе-А. Фирдавси</option>
          <option value="Душанбе-И. Сомони">Душанбе-И. Сомони</option>
          <option value="Душанбе-Шохмансур">Душанбе-Шохмансур</option>
          <option value="Худжанд">Худжанд</option>
          <option value="Бохтар">Бохтар</option>
          <option value="Пенджикент">Пенджикент</option>
          <option value="Район Дж. Расулов">Район Дж. Расулов</option>
          <option value="Канибадам">Канибадам</option>
          <option value="Турсунзаде">Турсунзаде</option>
          <option value="г.Гулистон">г.Гулистон</option>
          <option value="Район Б. Гафуров">Район Б. Гафуров</option>
          <option value="-"> -</option>
        </select>
        <!-- <input class="addtable" type="text" formControlName="city_s"> -->
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Район</h1>
        <select name="" id=""  formControlName="district_s" *ngIf="accountsAddForm.value.city_s === 'Душанбе'">
            <option value="Сино">Сино</option>
            <option value="Фирдавси">Фирдавси</option>
            <option value="И. Сомони">И. Сомони</option>
            <option value="Шохмансур">Шохмансур</option>
        </select>
        <input class="addtable" type="text" formControlName="district_s" *ngIf="accountsAddForm.value.city_s !== 'Душанбе'">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Микрорайон</h1>
        <input class="addtable" type="number" formControlName="microdistrict_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Улица</h1>
        <input class="addtable" type="text" formControlName="street_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Дом</h1>
        <input class="addtable" type="text" formControlName="home_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Квартира</h1>
        <input class="addtable" type="text" formControlName="apartment_s">
      </div>
      <div class="add_block">

        <div class="add_block_items">
          <h1 class="add_title">mac_adress</h1>
          <input class="addtable" type="text" [(ngModel)]="macAddress" (input)="changeDeviceId()" onblur="showSuggestionList=false" formControlName="mac_addresss" >

        </div>


          <ul *ngIf="showSuggestionList">
            <li *ngFor="let data of filteredSuggestions" (click)="addlistToDeviceId(data.mac_address)">{{data.mac_address}}</li>
          </ul>

      </div>

      <button class="addButton" [disabled]="accountsAddForm.invalid" [ngStyle]="{opacity: accountsAddForm.invalid ? '.6' : '1'}"  (click)="addNewTable()">OK</button>

    </form>
  </div>
</div>

<div class="editModal_content" *ngIf="editTable">
  <div class="editModal_block">
    <i class="fas fa-times" (click)="editTable = false" ></i>
    <form class="editForm" [formGroup]="accountsEditForm">

      <div class="edit_block_items">
          <h1 class="edit_title">ФИО</h1>
          <input class="addtable" type="text" formControlName="fio">
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Тарифы</h1>
          <select class="addtable" formControlName="tarif_id">
            <option *ngFor="let tarifs of tarifsData" [value]="tarifs.id" >{{ tarifs.name }}</option>
          </select>
      </div>

        <div class="edit_block_items">
            <h1 class="edit_title">Коэфиценть цены</h1>
            <input class="addtable" type="number" formControlName="price_cf">
        </div>

        <div class="edit_block_items">
            <h1 class="edit_title">Коэфиценть скороста</h1>
            <input class="addtable" type="number" formControlName="speed_cf">
        </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Телефон</h1>
          <input class="addtable" type="number" formControlName="phone_number">
      </div>

        <div class="edit_block_items">
            <h1 class="edit_title">Дата окончание</h1>
            <input class="addtable" type="date" formControlName="end_date">
        </div>

        <div class="edit_block_items">
            <h1 class="edit_title">IP Адресс</h1>
            <div class="ip_block">
              <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress1()"  maxlength="3" formControlName="ipaddress1">
              <span class="ip_span">.</span>
              <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress2()" maxlength="3" formControlName="ipaddress2">
              <span class="ip_span">.</span>
              <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress3()" maxlength="3" formControlName="ipaddress3">
              <span class="ip_span">.</span>
              <input class="ip_input" type="number" placeholder="000" (keyup)="ipadress4()" maxlength="3" formControlName="ipaddress4">
            </div>
        </div>

        <div class="edit_block_items">
          <h1 class="edit_title">IP Адресс</h1>
          <select formControlName="autoIpaddress">
            <option *ngFor="let IPGroup of IPGroupData" [value]="IPGroup.id">{{ IPGroup.ip_start }}</option>
          </select>
        </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Адрес</h1>
          <input class="addtable" type="text" formControlName="acc_info">
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Файрволл</h1>
          <select formControlName="conf_firewall_id">
            <option *ngFor="let fireWall of fireWallData" [value]="fireWall.id">{{ fireWall.firewall_host }} - {{ fireWall.comment }}</option>
          </select>
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Паспорт</h1>
          <input class="addtable" type="text" formControlName="passport">
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Год рождения</h1>
          <input class="addtable" type="date" formControlName="age">
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Пол</h1>
          <select formControlName="gender">
            <option value="M">Man</option>
            <option value="F">Female</option>
          </select>
      </div>

      <div class="edit_block_items">
        <h1 class="edit_title">Девайс</h1>
        <select formControlName="device">
          <option *ngFor="let device of deviceData" [value]="device.id">{{ device.name }}</option>
        </select>
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Овердрафта</h1>
          <select formControlName="overdraft">
            <option value="2">Да</option>
            <option value="1">Нет</option>
          </select>
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Тип клиента</h1>
          <select formControlName="customr_type">
            <option>B2C</option>
            <!-- <option>B2B</option> -->
          </select>
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Кто подключил</h1>
          <select formControlName="connect_by">
            <option *ngFor="let item of usersData | userFilter: 4" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
          </select>
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Кто привёл</h1>
          <select formControlName="contact_by">
            <option *ngFor="let item of usersData" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
          </select>
      </div>

      <div class="edit_block_items">
        <h1 class="edit_title">Table ID</h1>
        <select formControlName="table_id">
          <option>3</option>
          <option>4</option>
        </select>
      </div>

      <div class="edit_block_items">
        <h1 class="edit_title">Тип подключения</h1>
        <select formControlName="connect_type">
          <option *ngFor="let connectType of connectTypeData" [value]="connectType.id">{{ connectType.name }}</option>
        </select>
      </div>

      <div class="edit_block_items">
        <h1 class="edit_title">Акция</h1>
        <select formControlName="promo_id">
          <option *ngFor="let promotion of promotionData" [value]="promotion.id">{{ promotion.name }}</option>
        </select>
      </div>

      <div class="edit_block_items">
          <h1 class="edit_title">Onu/Port</h1>
          <input class="addtable" type="text" placeholder="Onu/Port" formControlName="comment">
      </div>
      <div class="edit_block_items">
          <h1 class="edit_title">Коментария</h1>
          <input class="addtable" type="text" placeholder="Коментария" formControlName="comment_1">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Город</h1>
        <select name="" id=""  formControlName="city_s">
          <option value="Душанбе-А. Сино">Душанбе-А. Сино</option>
          <option value="Душанбе-А. Фирдавси">Душанбе-А. Фирдавси</option>
          <option value="Душанбе-И. Сомони">Душанбе-И. Сомони</option>
          <option value="Душанбе-Шохмансур">Душанбе-Шохмансур</option>
          <option value="Худжанд">Худжанд</option>
          <option value="Бохтар">Бохтар</option>
          <option value="Пенджикент">Пенджикент</option>
          <option value="Район Дж. Расулов">Район Дж. Расулов</option>
          <option value="Канибадам">Канибадам</option>
          <option value="Турсунзаде">Турсунзаде</option>
          <option value="г.Гулистон">г.Гулистон</option>
          <option value="Район Б. Гафуров">Район Б. Гафуров</option>
          <option value="-"> -</option>
      </select>
        <!-- <input class="addtable" type="text" formControlName="city_s"> -->
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Район</h1>
        <select name="" id=""  formControlName="district_s" *ngIf="accountsEditForm.value.city_s === 'Душанбе'">
          <option value="Сино">Сино</option>
          <option value="Фирдавси">Фирдавси</option>
          <option value="И. Сомони">И. Сомони</option>
          <option value="Шохмансур">Шохмансур</option>
      </select>
      <input class="addtable" type="text" formControlName="district_s" *ngIf="accountsEditForm.value.city_s !== 'Душанбе'">
        <!-- <input class="addtable" type="text" formControlName="district_s"> -->
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Микрорайон</h1>
        <input class="addtable" type="text" formControlName="microdistrict_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Улица</h1>
        <input class="addtable" type="text" formControlName="street_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Дом</h1>
        <input class="addtable" type="text" formControlName="home_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Квартира</h1>
        <input class="addtable" type="text" formControlName="apartment_s">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Device_id</h1>
        <input class="addtable" type="text" formControlName="mac_addresss">
      </div>
      <button class="editButton" [disabled]="accountsEditForm.invalid" [ngStyle]="{opacity: accountsEditForm.invalid ? '0.6' : '1'}" (click)="editNewTable()">ОК</button>

    </form>
  </div>
</div>
<div class="uslugi_modal-container" *ngIf="sharesModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="sharesModal = false"></i>
    <div class="uslugi_modal-block">
      <h1>Турбокнопка</h1>
    <form class="payment_add-block" [formGroup]="addsharesForm">
      <select name="" id="" formControlName="tarif">
        <option value=""></option>
        <option [value]="tarifs.id" *ngFor="let tarifs of tarif">{{tarifs.name}}-{{tarifs.field_2}}</option>
      </select>
      <button (click)="addNewshares()" [disabled]="addsharesForm.invalid" [ngStyle]="{opacity: addsharesForm.invalid ? '.6' : '1'}">Добавить</button>
    </form>
    </div>
  </div>
</div>
<div class="uslugi_modal-container" *ngIf="tarifModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="tarifModal = false"></i>
    <div class="uslugi_modal-block">


    <form style="align-items: center;" class="service_add-block" [formGroup]="changeTarifs">

      <input style="width: 100%;" class="addtable" [type]="StartCreateData"  (focus)="changeStartCreateData()" (blur)="changeStartCreateText()"   formControlName="update_date" placeholder="Дата изменение тарифа">
      <select formControlName="tarifId" style="width: 100%;">
        <option *ngFor="let tarifs of tarifsData" [value]="tarifs.id" >{{ tarifs.name }}</option>
      </select>
      <div class="tarif_checkbox">
        <input   type="checkbox" formControlName="send_sms">
        <label for="">Отправить сообщение</label>
      </div>
      <div class="tarif_checkbox">
        <input   type="checkbox" formControlName="rever_tarif">
        <label for="">Вернуть остаток тарифа</label>
      </div>
      <div class="tarif_checkbox">
        <input   type="checkbox" formControlName="free">
        <label for="">Бесплатно сменить тариф</label>
      </div>
      <button (click)="changeTarif()">Добавить</button>
    </form>
    </div>
  </div>
</div>
  <div class="uslugi_modal-container" *ngIf="uslugiModal">
    <div class="uslugi_modal-content">
      <i class="fas fa-times" (click)="uslugiModal = false"></i>
      <div class="uslugi_modal-block">
        <table class="resp-tab-modal">
          <thead>
              <tr>
                  <th>Название</th>
                  <th>Дата начало</th>
                  <th>Дата окончания</th>
                  <th>Цена</th>
                  <th>Статус</th>
                  <th>Действия</th>
                  <th>Передоплата</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let servicesLink of servicesLinkData">
                  <td><span class="table_span-modal">Название</span>{{ servicesLink.service?.name }}</td>
                  <td><span class="table_span-modal">Дата начало</span>{{ servicesLink.created_at | date: 'dd-MM-yyyy' }}</td>
                  <td><span class="table_span-modal">Дата окончания</span>{{ servicesLink.expire_at | date: 'dd-MM-yyyy' }}</td>
                  <td><span class="table_span-modal">Цена</span>{{ servicesLink.service?.price }}</td>
                  <td><span class="table_span-modal">Статус</span>{{ servicesLink.deleted ? 'Удаленно' : 'Активный' }}</td>
                  <td><span class="table_span-modal">Действия</span><h1 style="font-size: 12px;" (click)="deleteService(servicesLink.id)">Удалить</h1></td>
                  <td *ngIf="showService(servicesLink.next_payment, servicesLink.expire_at)"><span class="table_span-modal">Передоплата</span><h1 style="font-size: 12px;" (click)="Service(servicesLink.id)">Передоплата</h1></td>
              </tr>
          </tbody>
      </table>

    <form class="service_add-block" [formGroup]="addServiceForm">
      <input class="addtable" type="date" formControlName="created_at">
      <select formControlName="name">
        <option *ngFor="let servicesData of servicesData" [value]="servicesData.id">{{ servicesData.name }}</option>
      </select>
      <button [disabled]="addServiceForm.invalid" [ngStyle]="{opacity: addServiceForm.invalid ? '.6' : '1'}" (click)="addNewService()">Добавить</button>
    </form>
    </div>
  </div>
</div>





<div class="uslugi_modal-container" *ngIf="packetModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="packetModal = false"></i>
    <div class="uslugi_modal-block">
      <table class="resp-tab-modal">
        <thead>
            <tr>
                <th>Название</th>
                <th>Дата</th>
                <th>Цена</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let packet of packets">
                <td><span class="table_span-modal">Название</span>{{ packet.additional_traffic.name }}</td>
                <td><span class="table_span-modal">Дата</span>{{ packet.created_at | date: 'dd-MM-yyyy' }}</td>
                <td><span class="table_span-modal">Цена</span>{{ packet.additional_traffic.price }}</td>
            </tr>
        </tbody>
    </table>
     <form class="service_add-block" [formGroup]="addPacketForm">

      <select formControlName="additional_traffic_id">
        <option *ngFor="let packetData of packetsData" [value]="packetData.id">{{ packetData.name }} / {{ packetData.customr_type }}</option>
      </select>
      <button [disabled]="addServiceForm.invalid" [ngStyle]="{opacity: addServiceForm.invalid ? '.6' : '1'}" (click)="addNewPacket()">Добавить</button>
    </form>
    </div>
  </div>
</div>
<div class="uslugi_modal-container" *ngIf="rpostModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="rpostModal = false"></i>
    <div class="uslugi_modal-block">

     <form class="service_add-block" [formGroup]="addRepostsForm">
      <input type="number" formControlName="count">
      <select formControlName="product_id">
        <option *ngFor="let reportData of reportData" [value]="reportData.id">{{ reportData.name }}</option>
      </select>
      <button [disabled]="addServiceForm.invalid" [ngStyle]="{opacity: addServiceForm.invalid ? '.6' : '1'}" (click)="addNewReporte()">Добавить</button>
    </form>
    </div>
  </div>
</div>





<div class="uslugi_modal-container" *ngIf="paymentModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="paymentModal = false"></i>
    <div class="uslugi_modal-block">
    <form class="payment_add-block" [formGroup]="addPaymentForm">
      <input type="number" placeholder="Сумма" formControlName="summa">
      <input type="text" placeholder="Коментарии" formControlName="comment">
      <button (click)="addNewPayment()" [disabled]="addPaymentForm.invalid" [ngStyle]="{opacity: addPaymentForm.invalid ? '.6' : '1'}">Добавить</button>
    </form>
    </div>
  </div>
</div>

  <div class="uslugi_modal-container" *ngIf="creditModal">
    <div class="uslugi_modal-content">
      <i class="fas fa-times" (click)="creditModal = false"></i>
      <div class="uslugi_modal-block">
        <table class="resp-tab-modal">
          <thead>
              <tr>
                  <th>ID</th>
                  <th>Дата начало</th>
                  <th>Дата окончания</th>
                  <th>Коментарии</th>
                  <th>Статус</th>
                  <th>Действия</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let credit of creditData">
                  <td><span class="table_span-modal">ID</span>{{ credit.id }}</td>
                  <td><span class="table_span-modal">Дата начало</span>{{ credit.created_at | date: 'dd-MM-yyyy' }}</td>
                  <td><span class="table_span-modal">Дата окончания</span>{{ credit.expire_at | date: 'dd-MM-yyyy' }}</td>
                  <td><span class="table_span-modal">Коментарии</span>{{ credit.comment }}</td>
                  <td><span class="table_span-modal">Статус</span>{{ credit.deleted ? 'Удаленно' : 'Активный' }}</td>
                  <td style="display: flex;"><span class="table_span-modal">Действия</span>
                    <h1 style="font-size: 12px;" (click)="changeCredit(credit.id)">Изменить</h1>
                  </td>
              </tr>
          </tbody>
      </table>

      <form style="align-items: center;" class="service_add-block" [formGroup]="addCreditForm">
        <textarea style="width: 230px; height: 56px; padding: 10px; outline: none;" placeholder="Коментарии" formControlName="comment"></textarea>
        <input style="width: 100%;" class="addtable" type="date" formControlName="expire_at">
        <button *ngIf="!editCredit" [disabled]="addCreditForm.invalid" [ngStyle]="{opacity: addCreditForm.invalid ? '.6' : '1'}" (click)="addNewCredit()">Добавить</button>
        <button *ngIf="editCredit" [disabled]="addCreditForm.invalid" [ngStyle]="{opacity: addCreditForm.invalid ? '.6' : '1'}" (click)="addEditCredit()">Изменить</button>
      </form>
      </div>
    </div>
  </div>

<div class="uslugi_modal-container" *ngIf="paymentModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="paymentModal = false"></i>
    <div class="uslugi_modal-block">
    <form class="payment_add-block" [formGroup]="addPaymentForm">
      <input type="number" placeholder="Сумма" formControlName="summa">
      <input type="text" placeholder="Коментарии" formControlName="comment">
      <button (click)="addNewPayment()" [disabled]="addPaymentForm.invalid" [ngStyle]="{opacity: addPaymentForm.invalid ? '.6' : '1'}">Добавить</button>
    </form>
    </div>
  </div>
</div>
<div class="uslugi_modal-container" *ngIf="disabled">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="disabled = false"></i>
    <div class="uslugi_modal-block">
    <form class="payment_add-block" [formGroup]="disabledForm">
      <input type="text" placeholder="Инфо" formControlName="info">
      <button (click)="lockAccountDisabled()" [disabled]="disabledForm.invalid" [ngStyle]="{opacity: disabledForm.invalid ? '.6' : '1'}">Добавить</button>
    </form>
    </div>
  </div>
</div>

<div class="uslugi_modal-container" *ngIf="additionalIpModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="additionalIpModal = false"></i>
    <div class="uslugi_modal-block">
      <table class="resp-tab-modal">
        <thead>
            <tr>
                <th>Account ID</th>
                <th>ID</th>
                <th>IP</th>
                <th>Адрес</th>
                <th>Коментария</th>
                <th>Действия</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let additionalIp of additionalIpData">
                <td><span class="table_span-modal">Account ID</span>{{ additionalIp.account_id }}</td>
                <td><span class="table_span-modal">ID</span>{{ additionalIp.id }}</td>
                <td><span class="table_span-modal">IP</span>{{ additionalIp.ip }}</td>
                <td><span class="table_span-modal">Адрес</span>{{ additionalIp.address }}</td>
                <td><span class="table_span-modal">Коментария</span>{{ additionalIp.comment }}</td>
                <td style="display: flex;"><span class="table_span-modal">Действия</span>
                  <h1 style="font-size: 12px;" (click)="deleteAdditionalIp(additionalIp.id)">Удалить</h1>
                  <h1 style="font-size: 12px; margin-left: 10px;" (click)="updateAdditionalIp(additionalIp.id)">Изменить</h1>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="ip" *ngIf="modalIp">

            <div class="ip-uslugi_modal-block" >
              <i class="fas fa-times" (click)="modalIp = false"></i>
              <!-- <form style="align-items: center;" class="service_add-block" [formGroup]="addAdditionalIpForm" *ngIf="editingIp === false">
                <input style="width: 100%;" class="addtable" type="text" placeholder="ip" formControlName="ip">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Адрес" formControlName="address">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Коментария" formControlName="comment">
                <button [disabled]="addAdditionalIpForm.invalid" [ngStyle]="{opacity: addAdditionalIpForm.invalid ? '.6' : '1'}" (click)="addNewAdditionalIp()">Добавить</button>
              </form> -->
              <form style="align-items: center;" class="service_add-block" [formGroup]="updateAdditionalIpForm" *ngIf="editingIp">
                <input style="width: 100%;" class="addtable" type="text" placeholder="ip" formControlName="ip">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Адрес" formControlName="address">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Коментария" formControlName="comment">
                <button [disabled]="addAdditionalIpForm.invalid" [ngStyle]="{opacity: addAdditionalIpForm.invalid ? '.6' : '1'}" (click)="updateIP()">Изменить</button>
              </form>
            </div>
          </div>
    </div>
  </div>
</div>
<div class="uslugi_modal-container" *ngIf="additionalGeneralIpModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="additionalGeneralIpModal = false"></i>
    <div class="uslugi_modal-block">
      <table class="resp-tab-modal">
        <thead>
            <tr>
                <th>Account ID</th>
                <th>ID</th>
                <th>IP</th>
                <th>Адрес</th>
                <th>Коментария</th>
                <th>Mac Adddress</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
          <button style="font-size: 18px; margin-left: 10px; text-align: center; cursor: pointer; margin: 50px 20px;" (click)="addAdditionalIpo()" >Добавить</button>
            <tr *ngFor="let additionalIp of additionalIpData">
                <td><span class="table_span-modal">Account ID</span>{{ additionalIp.account_id }}</td>
                <td><span class="table_span-modal">ID</span>{{ additionalIp.id }}</td>
                <td><span class="table_span-modal">IP</span>{{ additionalIp.ip }}</td>
                <td><span class="table_span-modal">Адрес</span>{{ additionalIp.address }}</td>
                <td><span class="table_span-modal">Коментария</span>{{ additionalIp.comment }}</td>
                <td><span class="table_span-modal">Коментария</span>{{ additionalIp.mac_address }}</td>
                <td style="display: flex;"><span class="table_span-modal">Действия</span>
                  <h1 style="font-size: 12px;" (click)="deleteAdditionalIp(additionalIp.id)">Удалить</h1>
                  <h1 style="font-size: 12px; margin-left: 10px;" (click)="updateAdditionalIp(additionalIp.id)">Изменить</h1>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="ip" *ngIf="modalIp">

            <div class="ip-uslugi_modal-block" >
              <i class="fas fa-times" (click)="modalIp = false"></i>
              <form style="align-items: center;" class="service_add-block" [formGroup]="addAdditionalIpForm" *ngIf="editingIp === false">
                <input style="width: 100%;" class="addtable" type="text" placeholder="ip" formControlName="ip" *ngIf="firewallId !== 'radius'">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Адрес" formControlName="address">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Коментария" formControlName="comment">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Mac adress" formControlName="mac_address" (input)="changeIpMac()" [(ngModel)]="macAddressIp" *ngIf="firewallId === 'radius'">
                <ul *ngIf="showSuggestionListIP">
                  <li *ngFor="let servicesData of filteredSuggestionsIP" (click)="addlistToDeviceIdIpMac(servicesData.mac_address)">{{servicesData.mac_address}}</li>
                </ul>
                <button [disabled]="addAdditionalIpForm.invalid" [ngStyle]="{opacity: addAdditionalIpForm.invalid ? '.6' : '1'}" (click)="addNewAdditionalIp()">Добавить</button>
              </form>
              <form style="align-items: center;" class="service_add-block" [formGroup]="updateAdditionalIpForm" *ngIf="editingIp">
                <input style="width: 100%;" class="addtable" type="text" placeholder="ip" formControlName="ip" *ngIf="firewallId !== 'radius'">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Адрес" formControlName="address">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Коментария" formControlName="comment">
                <input style="width: 100%;" class="addtable" type="text" placeholder="Mac adress" formControlName="mac_address" *ngIf="firewallId === 'radius'">
                <button [disabled]="addAdditionalIpForm.invalid" [ngStyle]="{opacity: addAdditionalIpForm.invalid ? '.6' : '1'}" (click)="updateIP()">Изменить</button>
              </form>
            </div>
          </div>
    </div>
  </div>
</div>

<div class="uslugi_modal-container" *ngIf="additionalInfoModal">
  <div class="uslugi_modal-content">
    <i class="fas fa-times" (click)="additionalInfoModal = false"></i>
    <div class="uslugi_modal-block">
      <table class="resp-tab-modal">
        <thead>
            <tr>
              <th>Аккаунт ID</th>
                <th>ID</th>
                <th>Город</th>
                <th>Тип подключения</th>
                <th>Подключения пользователья</th>
                <th>Контакты пользователья</th>
                <th>Округ</th>
                <th>Улица</th>
                <th>Номер улицы</th>
                <th>Действия</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let additionalInfo of additionalInfoData">
                <td><span class="table_span-modal">Аккаунт ID</span>{{ additionalInfo.account_id }}</td>
                <td><span class="table_span-modal">ID</span>{{ additionalInfo.id }}</td>
                <td><span class="table_span-modal">Город</span>{{ additionalInfo.city?.name }}</td>
                <td><span class="table_span-modal">Тип подключения</span>{{ additionalInfo.connect_type?.name }}</td>
                <td><span class="table_span-modal">Подключения пользователья</span>{{ additionalInfo.connect_user?.fio }}</td>
                <td><span class="table_span-modal">Контакты пользователья</span>{{ additionalInfo.contact_user?.fio }}</td>
                <td><span class="table_span-modal">Округ</span>{{ additionalInfo.district?.name }}</td>
                <td><span class="table_span-modal">Улица</span>{{ additionalInfo.street?.name }}</td>
                <td><span class="table_span-modal">Номер улицы</span>{{ additionalInfo.street_number }}</td>
                <td style="display: flex;"><span class="table_span-modal">Действия</span>
                  <h1 style="font-size: 12px;" (click)="changeAdditionalInfo(additionalInfo.id)">Изменить</h1>
                </td>
            </tr>
        </tbody>
    </table>

    <form style="align-items: center;" class="service_add-block" [formGroup]="addAdditionalInfoForm">
      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <select>
        <option value="">1</option>
        <option value="">2</option>
      </select>

      <button *ngIf="!editAdditionalInfo" [disabled]="addAdditionalInfoForm.invalid" [ngStyle]="{opacity: addAdditionalInfoForm.invalid ? '.6' : '1'}" (click)="addNewAdditionalInfo()">Добавить</button>
      <button *ngIf="editAdditionalInfo" [disabled]="addAdditionalInfoForm.invalid" [ngStyle]="{opacity: addAdditionalInfoForm.invalid ? '.6' : '1'}" (click)="EditAdditionalInfo()">Изменить</button>
    </form>
    </div>
  </div>
</div>

  <div class="uslugi_modal-container" *ngIf="extandsModal">
    <div class="uslugi_modal-content">
      <i class="fas fa-times" (click)="extandsModal = false"></i>
      <div class="uslugi_modal-block">
      <form class="payment_add-block" [formGroup]="addExtandsForm">
        <input type="date" formControlName="end_date">
        <textarea name="" id="" cols="30" rows="10" placeholder="Коментария"></textarea>
        <button (click)="addNewExtands()" [disabled]="addExtandsForm.invalid" [ngStyle]="{opacity: addExtandsForm.invalid ? '.6' : '1'}">Добавить</button>
      </form>
      </div>
    </div>
  </div>
  <div class="uslugi_modal-container" *ngIf="promotionModal">
    <div class="uslugi_modal-content">
      <i class="fas fa-times" (click)="promotionModal = false"></i>
      <div class="uslugi_modal-block">
      <form class="payment_add-block" [formGroup]="promotionForm">
         <label style="margin-left: 10px;">Акции</label>
        <select formControlName="promo_id" style="margin: 5px 0px 5px 0px; width: 100%;">
          <option></option>
          <option *ngFor="let promotion of promotionData" [value]="promotion.id">{{ promotion.name }}</option>
        </select>
        <button (click)="addNewPromotion()" [disabled]="promotionForm.invalid" [ngStyle]="{opacity: promotionForm.invalid ? '.6' : '1'}">Добавить</button>
      </form>
      </div>
    </div>
  </div>
  <div class="uslugi_modal-container" *ngIf="IpinfoAccountModal">
    <div class="uslugi_modal-content">
      <i class="fas fa-times" (click)="IpinfoAccountModal = false"></i>
      <div class="uslugi_modal-block">
       <div class="infoclient_block" *ngFor="let item of infoAccountArr">
        <div class="infoclient_item" >
          <p>ip address</p>
          <p>{{item.ip}}</p>
        </div>
        <div class="infoclient_item" >
          <p>mac address</p>
          <p>{{item.mac}}</p>
        </div>



       </div>
       <p  *ngIf="infoAccountArr.length === 0">Пусто</p>
       <p  *ngIf="loaddingInfoAccountModal">Loadding ....</p>
      </div>
    </div>
  </div>
  <div class="uslugi_modal-container" *ngIf="infoAccauntModal">
    <div class="uslugi_modal-content">
      <i class="fas fa-times" (click)="infoAccauntModal = false"></i>
      <div class="uslugi_modal-block">
       <div class="infoclient_block">
        <div class="infoclient_item">
          <p>год рождения</p>
          <p>{{age_modal | date: 'dd-MM-yyyy'}}</p>
        </div>
        <div class="infoclient_item">
          <p>пол</p>
          <p>{{gender_modal}}</p>
        </div>
        <div class="infoclient_item">
          <p>девайс</p>
          <p>{{device_type_modal}}</p>
        </div>
        <div class="infoclient_item">
          <p>тип клиента</p>
          <p>{{customr_type_modal}}</p>
        </div>
        <div class="infoclient_item">
          <p>тип подключения</p>
          <p>{{connect_type_modal}}</p>
        </div>
        <div class="infoclient_item">
          <p>тейбл ид</p>
          <p>{{table_id_modal}}</p>
        </div>
        <div class="infoclient_item">
          <p>коментария</p>
          <p>{{comment_1_modal}}</p>
        </div>

        <div class="infoclient_item">
          <p>Город</p>
          <p>{{city_s}}</p>
        </div>
        <div class="infoclient_item">
          <p>Район</p>
          <p>{{district_s}}</p>
        </div>
        <div class="infoclient_item">
          <p>Микрорайон</p>
          <p>{{microdistrict_s}}</p>
        </div>
        <div class="infoclient_item">
          <p>Улица</p>
          <p>{{street_s}}</p>
        </div>
        <div class="infoclient_item">
          <p>Дом</p>
          <p>{{home_s}}</p>
        </div>
        <div class="infoclient_item">
          <p>Квартира</p>
          <p>{{apartment_s}}</p>
        </div>

       </div>
      </div>
    </div>
  </div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Аккаунты</h1>
        <button (click)="addTable = true" *ngIf="permitActions">Добавить</button>
        <button (click)="openAddiitionalGeneralIpModal('')" *ngIf="permitActions">Cписок доп.Ip</button>
        <div class="title_item">
          <input type="text" placeholder="Отчиска портов по mac" [(ngModel)]="mac">
          <button (click)="clearPortMac()" *ngIf="permitActions">Отчистить</button>
        </div>
      </div>

        <div class="filter_content">
          <form class="filter_form">
            <div class="filter_block" [formGroup]="accountsFilterForm">
              <input type="number" placeholder="Лицевой Счёт" formControlName="id">
              <input type="text" placeholder="ФИО" formControlName="fio">
              <select class="addtable" formControlName="tarif_id">
                <option value="">Тариф</option>
                <option *ngFor="let tarifs of tarifsData" [value]="tarifs.id">{{ tarifs.name }}</option>
              </select>
              <input type="number" placeholder="Скорость" formControlName="speed_cf">
              <input type="number" placeholder="Телефон" formControlName="phone_number">
              <input type="text" placeholder="Ip Адрес" formControlName="ipaddress">
              <input type="text" placeholder="Адрес" formControlName="acc_info">
              <select formControlName="conf_firewall_id">
                <option value="">Файрволл</option>
                <option *ngFor="let fireWall of fireWallData" [value]="fireWall.id">{{ fireWall.firewall_host }} - {{ fireWall.comment }}</option>
              </select>
              <input type="text" placeholder="Паспорт" formControlName="passport">
              <input [type]="ageDate" placeholder="Год рождения" formControlName="age" (focus)="changeAgeData()" (blur)="changeAgeText()" [(ngModel)]="age">
              <select formControlName="gender">
                <option value="">Пол</option>
                <option value="M">Man</option>
                <option value="F">Female</option>
              </select>
              <select formControlName="device">
                <option value="">Устройство</option>
                <option *ngFor="let device of deviceData" [value]="device.id">{{ device.name }}</option>
              </select>
              <select formControlName="overdraft">
                <option value="">Безлимитный режим</option>
                <option value="2">Да</option>
                <option value="1">Нет</option>
              </select>
              <select formControlName="customr_type">
                <option value="">Тип пользователья</option>
                <option>B2C</option>
                <option>B2B</option>
              </select>
              <input type="text" placeholder="ONU/PORT" formControlName="comment">
              <select formControlName="status">
                <option value="">Статус</option>
                <option>On</option>
                <option>Off</option>
              </select>
              <input [type]="StartCreateData" formControlName="start_create" [(ngModel)]="StartCreate" placeholder="Дата создание от" (focus)="changeStartCreateData()" (blur)="changeStartCreateText()">
              <input [type]="andCreateData" formControlName="end_create" (focus)="changeAndCreateData()" (blur)="changeAndCreateText()" [(ngModel)]="andCreate" placeholder="Дата создание до">
              <input [type]="startData" formControlName="start_date" (focus)=" changeStartData()" (blur)=" changeStartText()" [(ngModel)]="start" placeholder="Дата окончания от">
              <input [type]="typeData" formControlName="end_date" (focus)="changeTypedata()" (blur)="changeTypetext()" placeholder="Дата окончания до" [(ngModel)]="binding">
              <input type="number" placeholder="0" formControlName="page_size">
              <input type="text" placeholder="Город" formControlName="city_s">
              <input type="text" placeholder="Район" formControlName="district_s">
              <input type="number" placeholder="Микрорайон" formControlName="microdistrict_s">
              <input type="text" placeholder="Улица" formControlName="street_s">
              <input type="text" placeholder="Дом" formControlName="home_s">
              <input type="text" placeholder="Квартира" formControlName="apartment_s">

            <select formControlName="connect_by">
              <option value="">Кто подключил</option>
              <option *ngFor="let item of usersData" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
            </select>
            <select formControlName="contact_by">
              <option value="">Кто привел</option>
              <option *ngFor="let item of usersData" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
            </select>
            <select formControlName="account_friend_id">
              <option value="">Нет</option>
              <option value="0">Да</option>
            </select>
            <button (click)="filterTable()">ОК</button>
          </div>

          <!-- <div class="pagination_block">
            <input type="number" placeholder="0">
          </div> -->
        </form>
      </div>

      <div class="table_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>Лиц.Счёт</th>
              <th>ФИО</th>
              <th>Тариф</th>
              <th>Баланс</th>
              <th>Статус</th>
              <th>Цена</th>
              <th>Скорость</th>
              <th>Телефон</th>
              <th>Дата Создания</th>
              <th>Дата Окончания</th>
              <th>IP Адрес</th>
              <th>Адрес</th>
              <th>Файрволл</th>
              <th>Паспорт</th>
              <!-- <th>Год Рождения</th> -->
              <!-- <th>Пол</th> -->
              <!-- <th>Девайс</th> -->
              <th>Овердрафта</th>
              <!-- <th>Тип клиента</th> -->
              <th>Onu/Port</th>
              <!-- <th>Тип подключения</th> -->
              <th>Кто подключил</th>
              <th>Кто привел</th>
              <!-- <th>Table ID</th> -->
              <th>Пароль</th>
              <th>device_id</th>
              <!-- <th>Коментария</th> -->
              <th>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle class="c1" cx="10" cy="10" r="9.5" stroke="black"/>
                  <circle class="c2" cx="10" cy="10" r="3" fill="black"/>
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let accounts of accountsData | paginate: {itemsPerPage: 15, currentPage: page }"  [formGroup]="accountsEditForm" >
              <td ><span class="table_span">Лиц.Счёт</span>
                <h1>{{ accounts.id }}</h1>
              </td>

              <td><span class="table_span">ФИО</span>
                <h1>{{ accounts.fio }}</h1>
              </td>

              <td><span class="table_span">Тариф</span>
                <h1>{{ accounts.tarif.name }}</h1>
              </td>

              <td><span class="table_span">Баланс</span>
                <h1>{{ accounts.balance }}</h1>
              </td>

              <td><span class="table_span">Статус</span>
                <h1 [style.background-color]="accounts.account_status.status == 'Архивный'? 'orange': accounts.account_status_id== 1 || accounts.account_status_id== 3 ? 'red' : 'green'">{{ accounts.account_status?.status }}</h1>
              </td>

              <td><span class="table_span">Цена</span>
                <h1>{{ accounts.price_cf }}</h1>
              </td>

              <td><span class="table_span">Скорость</span>
                <h1>{{ accounts.speed_cf }}</h1>
              </td>

              <td><span class="table_span">Телефон</span>
                <h1>{{ accounts.phone_number }}</h1>
              </td>

              <td><span class="table_span">Дата Создания</span>
                <h1>{{ accounts.created_at | date: 'dd-MM-yyyy' }}</h1>
              </td>

              <td><span class="table_span">Дата Окончания</span>
                <h1>{{ accounts.end_date | date: 'dd-MM-yyyy' }}</h1>
              </td>

              <td><span class="table_span">IP Адрес</span>
                <h1>{{ accounts.ipaddress }}</h1>
              </td>

              <td><span class="table_span">Адрес</span>
                <h1>{{ accounts.acc_info }}</h1>
              </td>

              <td><span class="table_span">Файрволл</span>
                <h1>{{ accounts.fireWall4Table.firewall_host }} {{ accounts.fireWall4Table.comment }}</h1>
              </td>

              <td><span class="table_span">Паспорт</span>
                <h1>{{ accounts.passport }}</h1>
              </td>

              <!-- <td><span class="table_span">Год Рождения</span>
                <h1>{{ accounts.age | date: 'dd-MM-yyyy' }}</h1>
              </td> -->

              <!-- <td><span class="table_span">Пол</span>
                <h1>{{ accounts.gender }}</h1>
              </td> -->

              <!-- <td><span class="table_span">Девайс</span>
                <h1>{{ accounts.device_type?.name }}</h1>
              </td> -->

              <td *ngIf="accounts.overdraft < 2"><span class="table_span">Овердрафта</span>
                <h1>Нет</h1>
              </td>

              <td *ngIf="accounts.overdraft > 1"><span class="table_span">Овердрафта</span>
                <h1>Да</h1>
              </td>

              <!-- <td><span class="table_span">Тип клиента</span>
                <h1>{{ accounts.customr_type }}</h1>
              </td> -->

              <td><span class="table_span">Onu/Port</span>
                <h1>{{ accounts.comment }}</h1>
              </td>

              <!-- <td><span class="table_span">Тип подключения</span>
                <h1>{{ accounts.connect_type?.name }}</h1>
              </td> -->

              <td><span class="table_span">Мастер по подключению</span>
                <h1>{{ accounts.connect_user?.fio }}</h1>
              </td>

              <td><span class="table_span">Кто привел</span>
                <h1>{{ accounts.contact_user?.fio }}</h1>
              </td>

              <!-- <td><span class="table_span">Table ID</span>
                <h1>{{ accounts.table_id }}</h1>
              </td> -->
              <td><span class="table_span">Пароль</span>
                <h1>{{ accounts.password }}</h1>
              </td>
              <td><span class="table_span">device id</span>
                <h1>{{ accounts.device_id }}</h1>
              </td>
              <!-- <td><span class="table_span">Город</span>
                <h1>{{ accounts.city_s }}</h1>
              </td>
              <td><span class="table_span">Район</span>
                <h1>{{ accounts.district_s }}</h1>
              </td>
              <td><span class="table_span">Микрорайон</span>
                <h1>{{ accounts.microdistrict_s }}</h1>
              </td>
              <td><span class="table_span">Улица</span>
                <h1>{{ accounts.street_s }}</h1>
              </td>
              <td><span class="table_span">Дом</span>
                <h1>{{ accounts.home_s }}</h1>
              </td>
              <td><span class="table_span">Квартира</span>
                <h1>{{ accounts.apartment_s }}</h1>
              </td> -->
              <!-- <td><span class="table_span">Коментария</span>
                <h1>{{ accounts.comment_1 }}</h1>
              </td> -->


              <td><span class="table_span">Действия</span>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openEditTable(accounts.id)" *ngIf="permitActions">
                    <mat-icon>edit</mat-icon>
                    <span>Изменить</span>
                  </button>

                  <button mat-menu-item (click)="deleteTable(accounts.id)" *ngIf="permitActions">
                    <mat-icon>delete</mat-icon>
                    <span>Удалить</span>
                  </button>

                  <button mat-menu-item (click)="openUslugiModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>rss_feed</mat-icon>
                    <span>Услуги</span>
                  </button>
                  <button mat-menu-item (click)="openRepostsModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>business</mat-icon>
                    <span>Продажа продукта</span>
                  </button>

                  <button mat-menu-item (click)="openPcketModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>Bolt</mat-icon>
                    <span>Пакеты</span>
                  </button>

                  <button mat-menu-item (click)="openPaymentModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>payments</mat-icon>
                    <span>Оплата</span>
                  </button>

                  <button mat-menu-item *ngIf="(accounts.account_status?.status == 'On') && (permitActions)" (click)="lockAccountOff(accounts.id)">
                    <mat-icon>lock</mat-icon>
                    <span>Заблокировать</span>
                  </button>

                  <button mat-menu-item *ngIf="(accounts.account_status?.status == 'Off') && (permitActions)" (click)="lockAccountOn(accounts.id)">
                    <mat-icon>lock_open</mat-icon>
                    <span>Разблокировать </span>
                  </button>

                  <button mat-menu-item  (click)="lockAccountDisabledOpen(accounts.id)">
                    <mat-icon>lock</mat-icon>
                    <span>Отказ</span>
                  </button>

                  <button mat-menu-item  [routerLink]="['/historyDisabled', accounts.id]">
                    <mat-icon>lock</mat-icon>
                    <span>История отказа</span>
                  </button>

                  <button mat-menu-item (click)="refreshAccount(accounts.id)">
                    <mat-icon>refresh</mat-icon>
                    <span>Обновить</span>
                  </button>

                  <button mat-menu-item [routerLink]="['/transaction-history', accounts.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>История транзакции</span>
                  </button>

                    <button mat-menu-item [routerLink]="['/historyAccount', accounts.id]">
                      <mat-icon>update</mat-icon>
                      <span>История изменение</span>
                    </button>

                  <button mat-menu-item (click)="openCreditModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>account_balance</mat-icon>
                    <span>Кредит</span>
                  </button>

                  <button mat-menu-item (click)="openAddiitionalIpModal(accounts.id , accounts.fireWall4Table.firewall_type)" *ngIf="permitActions">
                    <mat-icon>dns</mat-icon>
                    <span>Дополнительный ip</span>
                  </button>

                  <!-- Хозирашда Коммент -->

                  <!-- <button mat-menu-item (click)="openAdditionalInfoModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>add_chart</mat-icon>
                    <span>Дополнительная информация</span>
                  </button> -->

                  <button mat-menu-item (click)="openSharesModal(accounts.tarif_id, accounts.id)" *ngIf="permitActions">
                    <mat-icon>shutter_speed</mat-icon>
                    <span>Доп скорость</span>
                  </button>
                  <button mat-menu-item (click)="openExtandsModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>event</mat-icon>
                    <span>Продлить</span>
                  </button>

                  <button mat-menu-item (click)=" openTarifModal(accounts.id , accounts.tarif_id , accounts.account_status_id)" *ngIf="permitActions">
                    <mat-icon>autorenew icon</mat-icon>
                    <span>Сменить тариф</span>
                  </button>
                  <button mat-menu-item (click)="openInfoAccauntModal(accounts.age,accounts.gender,accounts.device_type?.name, accounts.customr_type,accounts.connect_type?.name,accounts.table_id,accounts.comment_1, accounts.city_s, accounts.district_s, accounts.microdistrict_s, accounts.street_s, accounts.home_s, accounts.apartment_s)" *ngIf="permitActions">
                    <mat-icon>assignment</mat-icon>
                    <span>Доп инфо</span>
                  </button>
                  <button mat-menu-item (click)="openPromotionModal(accounts.id)" *ngIf="permitActions">
                    <mat-icon>event</mat-icon>
                    <span>Добавить акцию</span>
                  </button>
                  <button mat-menu-item (click)="openIpinfoAccountModal(accounts.id)" *ngIf="accounts.fireWall4Table.firewall_type === 'radius'" >
                    <mat-icon>account_circle</mat-icon>
                    <span>Узнать ip address</span>
                  </button>
                  <button mat-menu-item (click)="clearPortId(accounts.id)" *ngIf="accounts.fireWall4Table.firewall_type === 'radius'" >
                    <mat-icon>account_circle</mat-icon>
                    <span>Отчиска портов</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="paginations__block">
          <pagination-controls previousLabel="Назад" nextLabel="Далее" (pageChange)="page = $event"></pagination-controls>
      </div>
      </div>
      <div class="pagination_block">
        <div class="exel_container">
          <button style="padding: 5px; cursor: pointer;" (click)="exportexcelAccount()">Аккаунт</button>
          <button style="padding: 5px; margin: 0 10px; cursor: pointer;" routerLink="/report1">Отчёт 1</button>
          <button style="padding: 5px; margin-right: 10px; cursor: pointer;" routerLink="/report2">Отчёт 2</button>
          <button style="padding: 5px; cursor: pointer;" routerLink="/report3">Отчёт 3</button>
        </div>
        Количество аккаунтов: {{ numAccounts }}
        Общая сумма : {{ summ }}
      </div>
    </div>
  </div>


  <!-- Account Exel -->
  <table class="resp-tab" id="excel-table-account" style="display: none;">
    <thead>
      <tr>
        <th>Лиц.Счёт</th>
        <th>ФИО</th>
        <th>Тариф</th>
        <th>Баланс</th>
        <th>Статус</th>
        <th>Цена</th>
        <th>Скорость</th>
        <th>Телефон</th>
        <th>Дата Создания</th>
        <th>Дата Окончания</th>
        <th>IP Адрес</th>
        <th>Адрес</th>
        <th>Паспорт</th>
        <th>Год Рождения</th>
        <th>Пол</th>
        <th>Девайс</th>
        <th>Овердрафта</th>
        <th>Тип клиента</th>
        <th>Onu/Port</th>
        <th>Кто подключил</th>
        <th>Кто привел</th>
        <th>Город</th>
        <th>Район</th>
        <th>Микрорайон</th>
        <th>Улица</th>
        <th>Дом</th>
        <th>Квартира</th>
        <th>DEVICE_ID</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let accounts of accountsData">
        <td>
          <h1>{{ accounts.id }}</h1>
        </td>

        <td>
          <h1>{{ accounts.fio }}</h1>
        </td>

        <td>
          <h1>{{ accounts.tarif.name }}</h1>
        </td>

        <td>
          <h1>{{ accounts.balance }}</h1>
        </td>

        <td>
          <h1>{{ accounts.account_status?.status }}</h1>
        </td>
        <td>
          <h1>{{ accounts.price_cf }}</h1>
        </td>

        <td>
          <h1>{{ accounts.speed_cf }}</h1>
        </td>

        <td>
          <h1>{{ accounts.phone_number }}</h1>
        </td>

        <td>
          <h1>{{ accounts.created_at | date }}</h1>
        </td>

        <td>
          <h1>{{ accounts.end_date | date }}</h1>
        </td>

        <td>
          <h1>{{ accounts.ipaddress }}</h1>
        </td>

        <td>
          <h1>{{ accounts.acc_info }}</h1>
        </td>

        <td>
          <h1>{{ accounts.passport }}</h1>
        </td>

        <td>
          <h1>{{ accounts.age | date: 'dd-MM-yyyy' }}</h1>
        </td>

        <td>
          <h1>{{ accounts.gender }}</h1>
        </td>

        <td>
          <h1>{{ accounts.device_type?.name }}</h1>
        </td>

        <td *ngIf="accounts.overdraft < 2">
          <h1>Нет</h1>
        </td>

        <td *ngIf="accounts.overdraft > 1">
          <h1>Да</h1>
        </td>

        <td>
          <h1>{{ accounts.customr_type }}</h1>
        </td>

        <td>
          <h1>{{ accounts.comment }}</h1>
        </td>

        <td>
          <h1>{{ accounts.connect_user?.fio }}</h1>
        </td>
        <td>
          <h1>{{ accounts.contact_user?.fio }}</h1>
        </td>
        <td>
          <h1>{{ accounts.city_s }}</h1>
        </td>
        <td>
          <h1>{{ accounts.district_s }}</h1>
        </td>
        <td>
          <h1>{{ accounts.microdistrict_s }}</h1>
        </td>
        <td>
          <h1>{{ accounts.street_s }}</h1>
        </td>
        <td>
          <h1>{{ accounts.home_s }}</h1>
        </td>
        <td>
          <h1>{{ accounts.apartment_s }}</h1>
        </td>
        <td>
          <h1>{{ accounts.device_id }}</h1>
        </td>







      </tr>
    </tbody>
  </table>

  <!-- End of Account Exel -->
</div>





<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Own report</h1>
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="accountsFilterForm">

            <input [type]="StartCreateData" formControlName="date_from" [(ngModel)]="StartCreate" placeholder="От" (focus)="changeStartCreateData()" (blur)="changeStartCreateText()">
            <input [type]="andCreateData" formControlName="date_to" (focus)="changeAndCreateData()" (blur)="changeAndCreateText()" [(ngModel)]="andCreate" placeholder="До">
            <!-- <input type="number" alt="" placeholder="Base B2B Db." formControlName="b2b_d_actv_count">
            <input type="number" alt="" placeholder="Rev.B2B Db." formControlName="b2b_d_actv_price">
            <input type="number" alt="" placeholder="Chern Db. B2B" formControlName="b2b_d_dis_count">
            <input type="number" alt="" placeholder="Chern cost Db. B2B" formControlName="b2b_d_dis_price">
            <input type="number" alt="" placeholder="Base B2B Sgd." formControlName="b2b_k_actv_count">
            <input type="number" alt="" placeholder="Rev.B2B Sgd." formControlName="b2b_k_actv_price">
            <input type="number" alt="" placeholder="Chern Sgd. B2B" formControlName="b2b_k_dis_count">
            <input type="number" alt="" placeholder="Chern cost Sgd. B2B" formControlName="b2b_k_dis_price">
            <input type="number" alt="" placeholder="Nets 1 base" formControlName="tn1_b2c_d_count">
            <input type="number" alt="" placeholder="Chern1" formControlName="tn1_b2c_d_dis_count">
            <input type="number" alt="" placeholder="Revenue (99 TJS)" formControlName="tn1_b2c_d_price">
            <input type="number" alt="" placeholder="Nets 1 base Sgd." formControlName="tn1_b2c_k_count">
            <input type="number" alt="" placeholder="Chern1 Sgd." formControlName="tn1_b2c_k_dis_count">
            <input type="number" alt="" placeholder="Revenue (99 TJS). sgd" formControlName="tn1_b2c_k_price">
            <input type="number" alt="" placeholder="Nets 2 base." formControlName="tn2_b2c_d_count">
            <input type="number" alt="" placeholder="Chern2" formControlName="tn2_b2c_d_dis_count">
            <input type="number" alt="" placeholder="Revenue (125 TJS)" formControlName="tn2_b2c_d_price">
            <input type="number" alt="" placeholder="Nets 2 base Sgd." formControlName="tn2_b2c_k_count">
            <input type="number" alt="" placeholder="Chern2 Sgd." formControlName="tn2_b2c_k_dis_count">
            <input type="number" alt="" placeholder="Revenue (125 TJS) Sgd." formControlName="tn2_b2c_k_price">
            <input type="number" alt="" placeholder="Nets 3 base" formControlName="tn3_b2c_d_count">
            <input type="number" alt="" placeholder="Chern3" formControlName="tn3_b2c_d_dis_count">
            <input type="number" alt="" placeholder="Revenue (225 TJS)" formControlName="tn3_b2c_d_price">
            <input type="number" alt="" placeholder="Nets 3 base Sgd." formControlName="tn3_b2c_k_count">
            <input type="number" alt="" placeholder="Chern3 Sgd." formControlName="tn3_b2c_k_dis_count">
            <input type="number" alt="" placeholder="Revenue (225 TJS) Sgd." formControlName="tn3_b2c_k_price">
            <input type="number" alt="" placeholder="Nets 4 base." formControlName="tn4_b2c_d_count">
            <input type="number" alt="" placeholder="Chern4" formControlName="tn4_b2c_d_dis_count">
            <input type="number" alt="" placeholder="Revenue (375 TJS)" formControlName="tn4_b2c_d_price">
            <input type="number" alt="" placeholder="Nets 4 base Sgd." formControlName="tn4_b2c_k_count">
            <input type="number" alt="" placeholder="Chern4 Sgd." formControlName="tn4_b2c_k_dis_count">
            <input type="number" alt="" placeholder="Revenue (375 TJS) Sgd.." formControlName="tn4_b2c_k_price"> -->
            <button [disabled]="accountsFilterForm.invalid" [ngStyle]="{opacity: accountsFilterForm.invalid ? '.6' : '1'}" (click)="filterTable()">ОК</button>
          </div>

          <!-- <div class="pagination_block">
            <input type="number" placeholder="0">
          </div> -->
        </form>
      </div>
      <div class="table_container">
        <table class="resp-tab">

          <thead>

              <tr>
                  <th colspan="28" style="background: white;"></th>
                  <th colspan="25" style="background: #F9F4CE;">Tariff plans Dushanbe</th>
                  <th colspan="5" style="background: white;"></th>
                  <th colspan="25" style="background: #F9F4CE;">Tariff plans Sogd</th>
                  <th colspan="21" style="background: white;">B2B DATA</th>
              </tr>

            <tr>
              <th>Date</th>
              <th>Total Rev.B2C</th>
              <th>Rev.data B2C Db.</th>
              <th>%CR</th>
              <th>Rev.data B2C Sgd.</th>
              <th>%CR</th>
              <th>Total Rev.В2В</th>
              <th>Rev.data B2B Db.</th>
              <th>%CR</th>
              <th>Rev.data B2B Sgd.</th>
              <th>%CR</th>
              <th>Activе base В2С</th>
              <th>Active base B2C Db.</th>
              <th>%CR</th>
              <th>Active base B2C Sgd.</th>
              <th>%CR</th>
              <th>Active base B2B </th>
              <th>Active base B2B Db.</th>
              <th>%CR</th>
              <th>Active base B2B Sgd.</th>
              <th>%CR</th>
              <th>Gross base В2С (Db+Sgd)</th>
              <th>Gross Rev. B2C</th>
              <th>B2C Db.</th>
              <th>%CR</th>
              <th>Chern Db. B2C</th>
              <th>Chern cost Db. B2C</th>
              <th>Gross Rev.B2C Db.</th>
              <th>%CR</th>
              <th>Nets 1 base</th>
              <th>%CR</th>
              <th>Revenue (99 TJS)</th>
              <th>CR%</th>
              <th>Chern1</th>
              <th>CR%</th>
              <th>Nets 2 base</th>
              <th>%CR</th>
              <th>Revenue (125 TJS)</th>
              <th>CR%</th>
              <th>Chern2</th>
              <th>CR%</th>
              <th>Nets 3 base</th>
              <th>%CR</th>
              <th>Revenue (225 TJS)</th>
              <th>CR%</th>
              <th>Chern3</th>
              <th>CR%</th>
              <th>Nets 4 base</th>
              <th>%CR</th>
              <th>Revenue (375 TJS)</th>
              <th>CR%</th>
              <th>Chern4</th>
              <th>CR%</th>
              <th>B2C Sgd.</th>
              <th>%CR</th>
              <th>Chern Sgd. B2C</th>
              <th>Chern cost B2C</th>
              <th>Gross Rev.B2C Sgd.</th>
              <th>%CR</th>
              <th>Nets 1 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (99 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern1 Sgd.</th>
              <th>CR%</th>
              <th>Nets 2 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (125 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern2 Sgd.</th>
              <th>CR%</th>
              <th>Nets 3 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (225 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern3 Sgd.</th>
              <th>CR%</th>
              <th>Nets 4 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (375 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern4 Sgd.</th>
              <th>CR%</th>
              <th>Gross Rev. В2В</th>
              <th>Gross base B2B</th>
              <th>Chern B2B</th>
              <th>Chern cost B2B</th>
              <th>Base B2B Db.</th>
              <th>%CR</th>
              <th>Rev.B2B Db.</th>
              <th>%CR</th>
              <th>Chern Db. B2B</th>
              <th>%CR</th>
              <th>Chern cost Db. B2B</th>
              <th>%CR</th>
              <th>Base B2B Sgd.</th>
              <th>%CR</th>
              <th>Rev.B2B Sgd.</th>
              <th>%CR</th>
              <th>Chern Sgd. B2B</th>
              <th>%CR</th>
              <th>Chern cost Sgd. B2B</th>
              <th>%CR</th>
            </tr>
          </thead>
          <tbody id="tables">

            <tr *ngIf="last_data != null">
              <td><span class="table_span">Date</span>
                <h1>{{ last_data.created_at | date: 'MMMM' }}</h1>
              </td>
              <td><span class="table_span">Total Rev.B2C</span>
                <h1>{{(last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) + (last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375))}}</h1>
              </td>

              <td><span class="table_span">Rev.data B2C Db.</span>
                <h1>{{last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)) / ((last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) + (last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Rev.data B2C Sgd.</span>
                <h1>{{last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) / ((last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) + (last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Total Rev.В2В</span>
                <h1>{{(last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) + (last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price)}}</h1>
              </td>

              <td><span class="table_span">Rev.data B2B Db.</span>
                <h1>{{last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price) / ((last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) + (last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Rev.data B2B Sgd.</span>
                <h1>{{last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) / ((last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) + (last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Activе base В2С</span>
                <h1>{{(last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) + (last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)}}</h1>
              </td>

              <td><span class="table_span">Active base B2C Db.</span>
                <h1>{{last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count) / ((last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) + (last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Active base B2C Sgd.</span>
                <h1>{{last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) / ((last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) + (last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td ><span class="table_span">Active base B2B</span>
                <h1>{{(last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) + (last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count)}}</h1>
              </td>

              <td ><span class="table_span">Active base B2B Db.</span>
                <h1>{{last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count) / ((last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) + (last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Active base B2B Sgd.</span>
                <h1>{{ last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{((last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) / ((last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) + (last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Gross base В2С (Db+Sgd)</span>
                <h1>{{last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count}}</h1>
              </td>
              <td><span class="table_span">Gross Rev. B2C</span>
                <h1>{{last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">B2C Db.</span>
                <h1>{{last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count) / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern Db. B2C</span>
                <h1>{{last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">Chern cost Db. B2C</span>
                <h1>{{(last_data.tn1_b2c_d_dis_count * 99)+(last_data.tn2_b2c_d_dis_count * 125) + (last_data.tn3_b2c_d_dis_count * 225) + (last_data.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>
              <td><span class="table_span">Gross Rev.B2C Db.</span>
                <h1>{{last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Nets 1 base</span>
                <h1>{{last_data.tn1_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn1_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (99 TJS)</span>
                <h1>{{last_data.tn1_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price == 0 ? 0 : last_data.tn1_b2c_k_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern1</span>
                <h1>{{ last_data.tn1_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn1_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 2 base</span>
                <h1>{{last_data.tn2_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn2_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (125 TJS)</span>
                <h1>{{last_data.tn2_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price == 0 ? 0 : last_data.tn2_b2c_d_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern2</span>
                <h1>{{ last_data.tn2_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn2_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 3 base</span>
                <h1> {{ last_data.tn3_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{( last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn3_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (225 TJS)</span>
                <h1>{{ last_data.tn3_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn3_b2c_d_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern3</span>
                <h1>{{ last_data.tn3_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn3_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count))}}%</h1>
              </td>
              <td><span class="table_span">Nets 4 base</span>
                <h1>{{ last_data.tn4_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn4_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (375 TJS)</span>
                <h1>{{ last_data.tn4_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price == 0 ? 0 : last_data.tn4_b2c_d_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern4</span>
                {{ last_data.tn4_b2c_d_dis_count}}
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn4_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">B2C Sgd.</span>
                <h1>{{last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ ((last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern Sgd. B2C</span>
                <h1>{{last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count}}</h1>
              </td>
              <td><span class="table_span">Chern cost B2C</span>
                <h1>{{(last_data.tn1_b2c_k_dis_count * 99) + (last_data.tn2_b2c_k_dis_count * 125) + (last_data.tn3_b2c_k_dis_count * 225) + (last_data.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>
              <td><span class="table_span">Gross Rev.B2C Sgd.</span>
                <h1>{{ last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ ((last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)  / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Nets 1 base Sgd.</span>
                <h1>{{ last_data.tn1_b2c_k_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn1_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (99 TJS) Sgd.</span>
                <h1>{{ last_data.tn1_b2c_k_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn1_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern1 Sgd.</span>
                <h1>{{ last_data.tn1_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn1_b2c_k_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 2 base Sgd.</span>
                <h1>{{ last_data.tn2_b2c_k_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn2_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (125 TJS) Sgd.</span>
                <h1>{{ last_data.tn2_b2c_k_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn2_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern2 Sgd.</span>
                <h1>{{ last_data.tn2_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn2_b2c_k_dis_count  / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 3 base Sgd.</span>
                <h1>{{ last_data.tn3_b2c_k_count }}</h1>
              </td>
              <td><span class="table_span">%CR.</span>
                <h1>{{(last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn3_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (225 TJS) Sgd.</span>
                <h1>{{ last_data.tn3_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">%CR.</span>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn3_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern3 Sgd.</span>
                <h1>{{ last_data.tn3_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn3_b2c_k_dis_count  / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 4 base Sgd.</span>
                <h1>{{ last_data.tn4_b2c_k_count}}
                </h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn4_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (375 TJS) Sgd.</span>
                <h1>{{ last_data.tn4_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn4_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern4 Sgd.</span>
                <h1>{{ last_data.tn4_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn4_b2c_k_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Gross Rev. В2В</span>
                <h1>{{ last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price }}</h1>
              </td>
              <td><span class="table_span">Gross base B2B</span>
                <h1>{{ last_data.b2b_k_actv_count + last_data.b2b_d_actv_count }}</h1>
              </td>
              <td><span class="table_span">Chern B2B</span>
                <h1>{{ last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">Chern cost B2B</span>
                <h1>{{ last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price}}</h1>
              </td>
              <td><span class="table_span">Base B2B Db.</span>
                <h1>{{ last_data.b2b_d_actv_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count == 0 ? 0 : last_data.b2b_d_actv_count / (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Rev.B2B Db.</span>
                <h1>{{ last_data.b2b_d_actv_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price == 0 ? 0 : last_data.b2b_d_actv_price / (last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern Db. B2B</span>
                <h1>{{ last_data.b2b_d_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count == 0 ? 0 : last_data.b2b_d_dis_count / (last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Chern cost Db. B2B</span>
                <h1>{{ last_data.b2b_d_dis_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price == 0 ? 0 : last_data.b2b_d_dis_price  / (last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Base B2B Sgd.</span>
                <h1>{{ last_data.b2b_k_actv_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count == 0 ? 0 : last_data.b2b_k_actv_count / (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Rev.B2B Sgd.</span>
                <h1>{{ last_data.b2b_k_actv_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price == 0 ? 0 : last_data.b2b_k_actv_price / (last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price)) * 100 | number: '1.1-2' }}%</h1>
              </td>
              <td><span class="table_span">Chern Sgd. B2B</span>
                <h1>{{ last_data.b2b_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count == 0 ? 0 : last_data.b2b_k_dis_count / (last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Chern cost Sgd. B2B</span>
                <h1>{{ last_data.b2b_k_dis_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price == 0 ? 0 : last_data.b2b_k_dis_price / (last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price)) * 100}}%</h1>
              </td>


            </tr>

            <tr *ngFor="let reportsActive of reportsActiveData">
              <td><span class="table_span">Date</span>
                <h1>{{ reportsActive.created_at| date: 'dd.MM.yyyy' }}</h1>
              </td>
              <td><span class="table_span">Total Rev.B2C</span>
                <h1>{{(reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) + (reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375))}}</h1>
              </td>

              <td><span class="table_span">Rev.data B2C Db.</span>
                <h1>{{reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)) / ((reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) + (reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Rev.data B2C Sgd.</span>
                <h1>{{reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) / ((reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) + (reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Total Rev.В2В</span>
                <h1>{{(reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) + (reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price)}}</h1>
              </td>

              <td><span class="table_span">Rev.data B2B Db.</span>
                <h1>{{reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price) / ((reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) + (reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Rev.data B2B Sgd.</span>
                <h1>{{reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) / ((reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) + (reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Activе base В2С</span>
                <h1>{{(reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) + (reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)}}</h1>
              </td>

              <td><span class="table_span">Active base B2C Db.</span>
                <h1>{{reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count) / ((reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) + (reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Active base B2C Sgd.</span>
                <h1>{{reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) / ((reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) + (reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td ><span class="table_span">Active base B2B</span>
                <h1>{{(reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) + (reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count)}}</h1>
              </td>

              <td ><span class="table_span">Active base B2B Db.</span>
                <h1>{{reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count}}</h1>
              </td>

              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count) / ((reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) + (reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td><span class="table_span">Active base B2B Sgd.</span>
                <h1>{{ reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) / ((reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) + (reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Gross base В2С (Db+Sgd)</span>
                <h1>{{reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count}}</h1>
              </td>
              <td><span class="table_span">Gross Rev. B2C</span>
                <h1>{{reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">B2C Db.</span>
                <h1>{{ reportsActive.B2C_Db }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count) / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern Db. B2C</span>
                <h1>{{reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">Chern cost Db. B2C</span>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count * 99)+(reportsActive.tn2_b2c_d_dis_count * 125) + (reportsActive.tn3_b2c_d_dis_count * 225) + (reportsActive.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>
              <td><span class="table_span">Gross Rev.B2C Db.</span>
                <h1>{{reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Nets 1 base</span>
                <h1>{{reportsActive.tn1_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn1_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (99 TJS)</span>
                <h1>{{reportsActive.tn1_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price == 0 ? 0 : reportsActive.tn1_b2c_k_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern1</span>
                <h1>{{ reportsActive.tn1_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn1_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100 }}%</h1>
              </td>
              <td><span class="table_span">Nets 2 base</span>
                <h1>{{reportsActive.tn2_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn2_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (125 TJS)</span>
                <h1>{{reportsActive.tn2_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price == 0 ? 0 : reportsActive.tn2_b2c_d_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern2</span>
                <h1>{{ reportsActive.tn2_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn2_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)}}</h1>
              </td>
              <td><span class="table_span">Nets 3 base</span>
                <h1> {{ reportsActive.tn3_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn3_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (225 TJS)</span>
                <h1>{{ reportsActive.tn3_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn3_b2c_d_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern3</span>
                <h1>{{ reportsActive.tn3_b2c_d_dis_count}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn3_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100 }}%</h1>
              </td>
              <td><span class="table_span">Nets 4 base</span>
                <h1>{{ reportsActive.tn4_b2c_d_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn4_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (375 TJS)</span>
                <h1>{{ reportsActive.tn4_b2c_d_price}}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{ (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price == 0 ? 0 : reportsActive.tn4_b2c_d_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern4</span>
                {{ reportsActive.tn4_b2c_d_dis_count}}
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn4_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">B2C Sgd.</span>
                <h1>{{reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern Sgd. B2C</span>
                <h1>{{reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count}}</h1>
                <h1>{{reportsActive.chernSgdb2c}}</h1>
              </td>
              <td><span class="table_span">Chern cost B2C</span>
                <h1>{{(reportsActive.tn1_b2c_k_dis_count * 99) + (reportsActive.tn2_b2c_k_dis_count * 125) + (reportsActive.tn3_b2c_k_dis_count * 225) + (reportsActive.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>
              <td><span class="table_span">Gross Rev.B2C Sgd.</span>
                <h1>{{ reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price}}</h1>
                <h1>{{ reportsActive.grossRevb2c }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{((reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)  / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Nets 1 base Sgd.</span>
                <h1>{{ reportsActive.tn1_b2c_k_count}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn1_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (99 TJS) Sgd.</span>
                <h1>{{ reportsActive.tn1_b2c_k_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn1_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern1 Sgd.</span>
                <h1>{{ reportsActive.tn1_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn1_b2c_k_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)}}</h1>
              </td>
              <td><span class="table_span">Nets 2 base Sgd.</span>
                <h1>{{ reportsActive.tn2_b2c_k_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn2_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (125 TJS) Sgd.</span>
                <h1>{{ reportsActive.tn2_b2c_k_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn2_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern2 Sgd.</span>
                <h1>{{ reportsActive.tn2_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn2_b2c_k_dis_count  / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 3 base Sgd.</span>
                <h1>{{ reportsActive.tn3_b2c_k_count }}</h1>
              </td>
              <td><span class="table_span">%CR.</span>
                <h1>{{(reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn3_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (225 TJS) Sgd.</span>
                <h1>{{ reportsActive.tn3_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">%CR.</span>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn3_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Chern3 Sgd.</span>
                <h1>{{ reportsActive.tn3_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn3_b2c_k_dis_count  / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td><span class="table_span">Nets 4 base Sgd.</span>
                <h1>{{ reportsActive.tn4_b2c_k_count}}
                </h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn4_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td><span class="table_span">Revenue (375 TJS) Sgd.</span>
                <h1>{{ reportsActive.tn4_b2c_k_price}}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn4_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
                <!-- <h1>{{ reportsActive.Cr10 | number: '1.1-2' }}%</h1> -->
              </td>
              <td><span class="table_span">Chern4 Sgd.</span>
                <h1>{{ reportsActive.tn4_b2c_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">CR%</span>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn4_b2c_k_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
                <!-- <h1>{{ reportsActive.Cr9 }}%</h1> -->
              </td>
              <td><span class="table_span">Gross Rev. В2В</span>
                <h1>{{ reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price }}</h1>
                <!-- <h1>{{ reportsActive.gross }}</h1> -->
              </td>
              <td><span class="table_span">Gross base B2B</span>
                <h1>{{ reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count }}</h1>
                <!-- <h1>{{ reportsActive.grossBaseb2b }}</h1> -->
              </td>
              <td><span class="table_span">Chern B2B</span>
                <h1>{{ reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count}}</h1>
                <!-- <h1>{{ reportsActive.Chern_B2B}}</h1> -->
              </td>
              <td><span class="table_span">Chern cost B2B</span>
                <h1>{{ reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price}}</h1>
                <!-- <h1>{{ reportsActive.Chern_cost_B2B}}</h1> -->
              </td>
              <td><span class="table_span">Base B2B Db.</span>
                <h1>{{ reportsActive.b2b_d_actv_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count == 0 ? 0 : reportsActive.b2b_d_actv_count / (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
                <!-- <h1>{{ reportsActive.Cr8 | number: '1.1-2'}}%</h1> -->
              </td>
              <td><span class="table_span">Rev.B2B Db.</span>
                <h1>{{ reportsActive.b2b_d_actv_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price == 0 ? 0 : reportsActive.b2b_d_actv_price / (reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price)) * 100 | number: '1.1-2'}}%</h1>
                <!-- <h1>{{ reportsActive.Cr7 | number: '1.1-2'}}%</h1> -->
              </td>
              <td><span class="table_span">Chern Db. B2B</span>
                <h1>{{ reportsActive.b2b_d_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count == 0 ? 0 : reportsActive.b2b_d_dis_count / (reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count)) * 100}}%</h1>
                <!-- <h1>{{ reportsActive.Cr6 }}%</h1> -->
              </td>
              <td><span class="table_span">Chern cost Db. B2B</span>
                <h1>{{ reportsActive.b2b_d_dis_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price == 0 ? 0 : reportsActive.b2b_d_dis_price  / (reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price)) * 100}}%</h1>
                <!-- <h1>{{ reportsActive.Cr5 }}%</h1> -->
              </td>
              <td><span class="table_span">Base B2B Sgd.</span>
                <h1>{{ reportsActive.b2b_k_actv_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{ (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count == 0 ? 0 : reportsActive.b2b_k_actv_count / (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
                <!-- <h1>{{ reportsActive.Cr4 | number: '1.1-2' }}%</h1> -->
              </td>
              <td><span class="table_span">Rev.B2B Sgd.</span>
                <h1>{{ reportsActive.b2b_k_actv_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price == 0 ? 0 : reportsActive.b2b_k_actv_price / (reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price)) * 100 | number: '1.1-2' }}%</h1>
                <!-- <h1>{{ reportsActive.Cr3 | number: '1.1-2' }} %</h1> -->
              </td>
              <td><span class="table_span">Chern Sgd. B2B</span>
                <h1>{{ reportsActive.b2b_k_dis_count }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count == 0 ? 0 : reportsActive.b2b_k_dis_count / (reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count)) * 100}}%</h1>
                <!-- <h1>{{reportsActive.Cr2}}%</h1> -->
              </td>
              <td><span class="table_span">Chern cost Sgd. B2B</span>
                <h1>{{ reportsActive.b2b_k_dis_price }}</h1>
              </td>
              <td><span class="table_span">%CR</span>
                <h1>{{(reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price == 0 ? 0 : reportsActive.b2b_k_dis_price / (reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price)) * 100}}%</h1>
                <!-- <h1>{{reportsActive.Cr1}}%</h1> -->
              </td>


            </tr>

          </tbody>
        </table>
        <table class="resp-tab"  style="display: none;" id="excel-table-account">

          <thead>

              <tr>
                  <th colspan="28" style="background: white;"></th>
                  <th colspan="25" style="background: #F9F4CE;">Tariff plans Dushanbe</th>
                  <th colspan="5" style="background: white;"></th>
                  <th colspan="25" style="background: #F9F4CE;">Tariff plans Sogd</th>
                  <th colspan="21" style="background: white;">B2B DATA</th>
              </tr>

            <tr>
              <th>Date</th>
              <th>Total Rev.B2C</th>
              <th>Rev.data B2C Db.</th>
              <th>%CR</th>
              <th>Rev.data B2C Sgd.</th>
              <th>%CR</th>
              <th>Total Rev.В2В</th>
              <th>Rev.data B2B Db.</th>
              <th>%CR</th>
              <th>Rev.data B2B Sgd.</th>
              <th>%CR</th>
              <th>Activе base В2С</th>
              <th>Active base B2C Db.</th>
              <th>%CR</th>
              <th>Active base B2C Sgd.</th>
              <th>%CR</th>
              <th>Active base B2B </th>
              <th>Active base B2B Db.</th>
              <th>%CR</th>
              <th>Active base B2B Sgd.</th>
              <th>%CR</th>
              <th>Gross base В2С (Db+Sgd)</th>
              <th>Gross Rev. B2C</th>
              <th>B2C Db.</th>
              <th>%CR</th>
              <th>Chern Db. B2C</th>
              <th>Chern cost Db. B2C</th>
              <th>Gross Rev.B2C Db.</th>
              <th>%CR</th>
              <th>Nets 1 base</th>
              <th>%CR</th>
              <th>Revenue (99 TJS)</th>
              <th>CR%</th>
              <th>Chern1</th>
              <th>CR%</th>
              <th>Nets 2 base</th>
              <th>%CR</th>
              <th>Revenue (125 TJS)</th>
              <th>CR%</th>
              <th>Chern2</th>
              <th>CR%</th>
              <th>Nets 3 base</th>
              <th>%CR</th>
              <th>Revenue (225 TJS)</th>
              <th>CR%</th>
              <th>Chern3</th>
              <th>CR%</th>
              <th>Nets 4 base</th>
              <th>%CR</th>
              <th>Revenue (375 TJS)</th>
              <th>CR%</th>
              <th>Chern4</th>
              <th>CR%</th>
              <th>B2C Sgd.</th>
              <th>%CR</th>
              <th>Chern Sgd. B2C</th>
              <th>Chern cost B2C</th>
              <th>Gross Rev.B2C Sgd.</th>
              <th>%CR</th>
              <th>Nets 1 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (99 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern1 Sgd.</th>
              <th>CR%</th>
              <th>Nets 2 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (125 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern2 Sgd.</th>
              <th>CR%</th>
              <th>Nets 3 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (225 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern3 Sgd.</th>
              <th>CR%</th>
              <th>Nets 4 base Sgd.</th>
              <th>%CR</th>
              <th>Revenue (375 TJS) Sgd.</th>
              <th>%CR</th>
              <th>Chern4 Sgd.</th>
              <th>CR%</th>
              <th>Gross Rev. В2В</th>
              <th>Gross base B2B</th>
              <th>Chern B2B</th>
              <th>Chern cost B2B</th>
              <th>Base B2B Db.</th>
              <th>%CR</th>
              <th>Rev.B2B Db.</th>
              <th>%CR</th>
              <th>Chern Db. B2B</th>
              <th>%CR</th>
              <th>Chern cost Db. B2B</th>
              <th>%CR</th>
              <th>Base B2B Sgd.</th>
              <th>%CR</th>
              <th>Rev.B2B Sgd.</th>
              <th>%CR</th>
              <th>Chern Sgd. B2B</th>
              <th>%CR</th>
              <th>Chern cost Sgd. B2B</th>
              <th>%CR</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngIf="last_data != null">
              <td>
                <h1>{{ last_data.created_at | date: 'MMMM' }}</h1>
              </td>
              <td>
                <h1>{{(last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) + (last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375))}}</h1>
              </td>

              <td>
                <h1>{{last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)) / ((last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) + (last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) / ((last_data.prev.rev_data_b2c_k + (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price) - (last_data.tn1_b2c_k_dis_count * 99 + last_data.tn2_b2c_k_dis_count * 125 + last_data.tn3_b2c_k_dis_count * 225 + last_data.tn4_b2c_k_dis_count * 375)) + (last_data.prev.rev_data_b2c_d + (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) - (last_data.tn1_b2c_d_dis_count * 99 + last_data.tn2_b2c_d_dis_count * 125 + last_data.tn3_b2c_d_dis_count * 225 + last_data.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{(last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) + (last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price)}}</h1>
              </td>

              <td>
                <h1>{{last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price) / ((last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) + (last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) / ((last_data.prev.rev_data_b2b_k +  last_data.b2b_k_actv_price - last_data.b2b_k_dis_price) + (last_data.prev.rev_data_b2b_d +  last_data.b2b_d_actv_price - last_data.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{(last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) + (last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)}}</h1>
              </td>

              <td>
                <h1>{{last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count) / ((last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) + (last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) / ((last_data.prev.active_base_b2c_k - (last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count) + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) + (last_data.prev.active_base_b2c_d - (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count) + last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td >
                <h1>{{(last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) + (last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count)}}</h1>
              </td>

              <td >
                <h1>{{last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count}}</h1>
              </td>

              <td>
                <h1>{{((last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count) / ((last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) + (last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{ last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{((last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) / ((last_data.prev.active_base_b2b_k + last_data.b2b_k_actv_count -last_data.b2b_k_dis_count) + (last_data.prev.active_base_b2b_d + last_data.b2b_d_actv_count - last_data.b2b_d_dis_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count}}</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count) / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_d_dis_count * 99)+(last_data.tn2_b2c_d_dis_count * 125) + (last_data.tn3_b2c_d_dis_count * 225) + (last_data.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price) / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn1_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price == 0 ? 0 : last_data.tn1_b2c_k_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn1_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn1_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn2_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn2_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn2_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price == 0 ? 0 : last_data.tn2_b2c_d_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn2_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn2_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1> {{ last_data.tn3_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{( last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn3_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn3_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn3_b2c_d_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn3_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn3_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count))}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn4_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count == 0 ? 0 : last_data.tn4_b2c_d_count / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn4_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price == 0 ? 0 : last_data.tn4_b2c_d_price / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                {{ last_data.tn4_b2c_d_dis_count}}
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn4_b2c_d_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count}}</h1>
              </td>
              <td>
                <h1>{{ ((last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count) / (last_data.tn1_b2c_d_count + last_data.tn2_b2c_d_count + last_data.tn3_b2c_d_count + last_data.tn4_b2c_d_count + last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{last_data.tn1_b2c_k_dis_count + last_data.tn2_b2c_k_dis_count + last_data.tn3_b2c_k_dis_count  + last_data.tn4_b2c_k_dis_count}}</h1>
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_k_dis_count * 99) + (last_data.tn2_b2c_k_dis_count * 125) + (last_data.tn3_b2c_k_dis_count * 225) + (last_data.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>
              <td>
                <h1>{{ last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{ ((last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)  / (last_data.tn1_b2c_d_price + last_data.tn2_b2c_d_price + last_data.tn3_b2c_d_price + last_data.tn4_b2c_d_price + last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn1_b2c_k_count}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn1_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn1_b2c_k_price }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn1_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn1_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn1_b2c_k_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn2_b2c_k_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn2_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn2_b2c_k_price }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn2_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn2_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn2_b2c_k_dis_count  / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn3_b2c_k_count }}</h1>
              </td>
              <td>
                <h1>{{(last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn3_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn3_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn3_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn3_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn3_b2c_k_dis_count  / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn4_b2c_k_count}}
                </h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count == 0 ? 0 : last_data.tn4_b2c_k_count / (last_data.tn1_b2c_k_count + last_data.tn2_b2c_k_count + last_data.tn3_b2c_k_count + last_data.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn4_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price == 0 ? 0 : last_data.tn4_b2c_k_price / (last_data.tn1_b2c_k_price + last_data.tn2_b2c_k_price + last_data.tn3_b2c_k_price + last_data.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.tn4_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count == 0 ? 0 : last_data.tn4_b2c_k_dis_count / (last_data.tn1_b2c_d_dis_count + last_data.tn2_b2c_d_dis_count + last_data.tn3_b2c_d_dis_count  + last_data.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price }}</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_actv_count + last_data.b2b_d_actv_count }}</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price}}</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_d_actv_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count == 0 ? 0 : last_data.b2b_d_actv_count / (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_d_actv_price }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price == 0 ? 0 : last_data.b2b_d_actv_price / (last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_d_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count == 0 ? 0 : last_data.b2b_d_dis_count / (last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_d_dis_price }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price == 0 ? 0 : last_data.b2b_d_dis_price  / (last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_actv_count }}</h1>
              </td>
              <td>
                <h1>{{ (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count == 0 ? 0 : last_data.b2b_k_actv_count / (last_data.b2b_k_actv_count + last_data.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_actv_price }}</h1>
              </td>
              <td>
                <h1>{{(last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price == 0 ? 0 : last_data.b2b_k_actv_price / (last_data.b2b_d_actv_price  +  last_data.b2b_k_actv_price)) * 100 | number: '1.1-2' }}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{(last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count == 0 ? 0 : last_data.b2b_k_dis_count / (last_data.b2b_k_dis_count  +  last_data.b2b_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ last_data.b2b_k_dis_price }}</h1>
              </td>
              <td>
                <h1>{{(last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price == 0 ? 0 : last_data.b2b_k_dis_price / (last_data.b2b_k_dis_price  +  last_data.b2b_d_dis_price)) * 100}}%</h1>
              </td>


            </tr>

            <tr *ngFor="let reportsActive of reportsActiveData">
              <td>
                <h1>{{ reportsActive.created_at| date: 'dd.MM.yyyy' }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) + (reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375))}}</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)) / ((reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) + (reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) / ((reportsActive.prev.rev_data_b2c_k + (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price) - (reportsActive.tn1_b2c_k_dis_count * 99 + reportsActive.tn2_b2c_k_dis_count * 125 + reportsActive.tn3_b2c_k_dis_count * 225 + reportsActive.tn4_b2c_k_dis_count * 375)) + (reportsActive.prev.rev_data_b2c_d + (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) - (reportsActive.tn1_b2c_d_dis_count * 99 + reportsActive.tn2_b2c_d_dis_count * 125 + reportsActive.tn3_b2c_d_dis_count * 225 + reportsActive.tn4_b2c_d_dis_count * 375)))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{(reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) + (reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price)}}</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price) / ((reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) + (reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) / ((reportsActive.prev.rev_data_b2b_k +  reportsActive.b2b_k_actv_price - reportsActive.b2b_k_dis_price) + (reportsActive.prev.rev_data_b2b_d +  reportsActive.b2b_d_actv_price - reportsActive.b2b_d_dis_price))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{(reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) + (reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)}}</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count) / ((reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) + (reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) / ((reportsActive.prev.active_base_b2c_k - (reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count) + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) + (reportsActive.prev.active_base_b2c_d - (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count) + reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{(reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) + (reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count)}}</h1>
              </td>

              <td>
                <h1>{{reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count}}</h1>
              </td>

              <td>
                <h1>{{((reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count) / ((reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) + (reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count))) * 100 | number: '1.1-2'}}%</h1>
              </td>

              <td>
                <h1>{{ reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{((reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) / ((reportsActive.prev.active_base_b2b_k + reportsActive.b2b_k_actv_count -reportsActive.b2b_k_dis_count) + (reportsActive.prev.active_base_b2b_d + reportsActive.b2b_d_actv_count - reportsActive.b2b_d_dis_count))) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count}}</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count) / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count * 99)+(reportsActive.tn2_b2c_d_dis_count * 125) + (reportsActive.tn3_b2c_d_dis_count * 225) + (reportsActive.tn4_b2c_d_dis_count * 375)}}</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price) / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn1_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100  | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price == 0 ? 0 : reportsActive.tn1_b2c_k_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn1_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100 }}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn2_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn2_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn2_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price == 0 ? 0 : reportsActive.tn2_b2c_d_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn2_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn2_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)}}</h1>
              </td>
              <td>
                <h1> {{ reportsActive.tn3_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn3_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn3_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn3_b2c_d_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn3_b2c_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn3_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100 }}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn4_b2c_d_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count == 0 ? 0 : reportsActive.tn4_b2c_d_count / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn4_b2c_d_price}}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price == 0 ? 0 : reportsActive.tn4_b2c_d_price / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                {{ reportsActive.tn4_b2c_d_dis_count}}
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn4_b2c_d_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count}}</h1>
              </td>
              <td>
                <h1>{{((reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count) / (reportsActive.tn1_b2c_d_count + reportsActive.tn2_b2c_d_count + reportsActive.tn3_b2c_d_count + reportsActive.tn4_b2c_d_count + reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{reportsActive.tn1_b2c_k_dis_count + reportsActive.tn2_b2c_k_dis_count + reportsActive.tn3_b2c_k_dis_count  + reportsActive.tn4_b2c_k_dis_count}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_dis_count * 99) + (reportsActive.tn2_b2c_k_dis_count * 125) + (reportsActive.tn3_b2c_k_dis_count * 225) + (reportsActive.tn4_b2c_k_dis_count * 375)}}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{((reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)  / (reportsActive.tn1_b2c_d_price + reportsActive.tn2_b2c_d_price + reportsActive.tn3_b2c_d_price + reportsActive.tn4_b2c_d_price + reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_k_count}}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn1_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_k_price }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn1_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn1_b2c_k_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)}}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn2_b2c_k_count }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn2_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn2_b2c_k_price }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn2_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn2_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn2_b2c_k_dis_count  / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn3_b2c_k_count }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn3_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn3_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn3_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn3_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn3_b2c_k_dis_count  / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn4_b2c_k_count}}
                </h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count == 0 ? 0 : reportsActive.tn4_b2c_k_count / (reportsActive.tn1_b2c_k_count + reportsActive.tn2_b2c_k_count + reportsActive.tn3_b2c_k_count + reportsActive.tn4_b2c_k_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn4_b2c_k_price}}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price == 0 ? 0 : reportsActive.tn4_b2c_k_price / (reportsActive.tn1_b2c_k_price + reportsActive.tn2_b2c_k_price + reportsActive.tn3_b2c_k_price + reportsActive.tn4_b2c_k_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.tn4_b2c_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count == 0 ? 0 : reportsActive.tn4_b2c_k_dis_count / (reportsActive.tn1_b2c_d_dis_count + reportsActive.tn2_b2c_d_dis_count + reportsActive.tn3_b2c_d_dis_count  + reportsActive.tn4_b2c_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price }}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count }}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count}}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price}}</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_d_actv_count }}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count == 0 ? 0 : reportsActive.b2b_d_actv_count / (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_d_actv_price }}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price == 0 ? 0 : reportsActive.b2b_d_actv_price / (reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_d_dis_count }}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count == 0 ? 0 : reportsActive.b2b_d_dis_count / (reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_d_dis_price }}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price == 0 ? 0 : reportsActive.b2b_d_dis_price  / (reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_actv_count }}</h1>
              </td>
              <td>
                <h1>{{ (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count == 0 ? 0 : reportsActive.b2b_k_actv_count / (reportsActive.b2b_k_actv_count + reportsActive.b2b_d_actv_count)) * 100 | number: '1.1-2'}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_actv_price }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price == 0 ? 0 : reportsActive.b2b_k_actv_price / (reportsActive.b2b_d_actv_price  +  reportsActive.b2b_k_actv_price)) * 100 | number: '1.1-2' }}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_dis_count }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count == 0 ? 0 : reportsActive.b2b_k_dis_count / (reportsActive.b2b_k_dis_count  +  reportsActive.b2b_d_dis_count)) * 100}}%</h1>
              </td>
              <td>
                <h1>{{ reportsActive.b2b_k_dis_price }}</h1>
              </td>
              <td>
                <h1>{{(reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price == 0 ? 0 : reportsActive.b2b_k_dis_price / (reportsActive.b2b_k_dis_price  +  reportsActive.b2b_d_dis_price)) * 100}}%</h1>
              </td>


            </tr>

          </tbody>
        </table>




      </div>


        <div class="exel_container">
          <button style="padding: 5px; cursor: pointer; margin: 20px 0px;" (click)="exportexcelAccount()">export OwnReport</button>
        </div>


    </div>
  </div>
</div>

<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>История изменений аккаунта</h1>
      </div>

      <div class="tables_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>Лиц.Счёт</th>
              <th>ФИО</th>
              <th>Тариф</th>
              <th>Баланс</th>
              <th>Статус</th>
              <th>Цена</th>
              <th>Скорость</th>
              <th>Телефон</th>
              <th>Дата Создания</th>
              <!-- <th>Дата Окончания</th> -->
              <th>IP Адрес</th>
              <th>Адрес</th>
              <th>Паспорт</th>
              <th>Год Рождения</th>
              <th>Пол</th>
              <th>Девайс</th>
              <th>Овердрафта</th>
              <th>Тип клиента</th>
              <th>Onu/Port</th>
              <th>Тип подключения</th>
              <th>Кто подключил</th>
              <th>Кто привел</th>
              <th>Table ID</th>
              <th>Дата изменения</th>
              <th>Кто изменил</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let accounts of changeHistoryData">
              <td><span class="table_span">Лиц.Счёт</span>
                <h1>{{ accounts.account_id }}</h1>
              </td>

              <td><span class="table_span">ФИО</span>
                <h1>{{ accounts.fio }}</h1>
              </td>

              <td><span class="table_span">Тариф</span>
                <h1>{{ accounts.tarif.name }}</h1>
              </td>

              <td><span class="table_span">Баланс</span>
                <h1>{{ accounts.balance }}</h1>
              </td>

              <td><span class="table_span">Статус</span>
                <h1 [style.background-color]="accounts.account_status_id==1 ? 'red': 'green'">{{ accounts.account_status?.status }}</h1>
              </td>

              <td><span class="table_span">Цена</span>
                <h1>{{ accounts.price_cf }}</h1>
              </td>

              <td><span class="table_span">Скорость</span>
                <h1>{{ accounts.speed_cf }}</h1>
              </td>

              <td><span class="table_span">Телефон</span>
                <h1>{{ accounts.phone_number }}</h1>
              </td>

              <td><span class="table_span">Дата Создания</span>
                <h1>{{ accounts.created_at | date: 'dd-MM-yyyy' }}</h1>
              </td>

              <!-- <td><span class="table_span">Дата Окончания</span>
                <h1>{{ formatDateIntl(accounts.end_date) }}</h1>
              </td> -->

              <td><span class="table_span">IP Адрес</span>
                <h1>{{ accounts.ipaddress }}</h1>
              </td>

              <td><span class="table_span">Адрес</span>
                <h1>{{ accounts.acc_info }}</h1>
              </td>


              <td><span class="table_span">Паспорт</span>
                <h1>{{ accounts.passport }}</h1>
              </td>

              <td><span class="table_span">Год Рождения</span>
                <h1>{{ accounts.age | date: 'dd-MM-yyyy' }}</h1>
              </td>

              <td><span class="table_span">Пол</span>
                <h1>{{ accounts.gender }}</h1>
              </td>

              <td><span class="table_span">Девайс</span>
                <h1>{{ accounts.device_type?.name }}</h1>
              </td>

              <td *ngIf="accounts.overdraft < 2"><span class="table_span">Овердрафта</span>
                <h1>Нет</h1>
              </td>

              <td *ngIf="accounts.overdraft > 1"><span class="table_span">Овердрафта</span>
                <h1>Да</h1>
              </td>

              <td><span class="table_span">Тип клиента</span>
                <h1>{{ accounts.customr_type }}</h1>
              </td>

              <td><span class="table_span">Onu/Port</span>
                <h1>{{ accounts.comment }}</h1>
              </td>

              <td><span class="table_span">Тип подключения</span>
                <h1>{{ accounts.connect_type?.name }}</h1>
              </td>

              <td><span class="table_span">Мастер по подключению</span>
                <h1>{{ accounts.connect_user?.fio }}</h1>
              </td>

              <td><span class="table_span">Кто привел</span>
                <h1>{{ accounts.contact_user?.fio }}</h1>
              </td>

              <td><span class="table_span">Table ID</span>
                <h1>{{ accounts.table_id }}</h1>
              </td>
              <td><span class="table_span">Table ID</span>
                <h1>{{ accounts.created_at| date: 'dd-MM-yyyy' }}</h1>
              </td>
              <td><span class="table_span">Table ID</span>
                <h1>{{ accounts.user?.fio }}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>




</div>

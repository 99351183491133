import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

  transform(items:any, arg: number): any {
    return items.filter((item:any) => item.group_id == arg);
  }

}

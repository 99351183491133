import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";
import { RequestsService } from '../all.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  deviceData: any = []
  deviceAddForm!: FormGroup
  deviceEditForm!: FormGroup
  deviceFilterForm!: FormGroup
  page: any
  search: any
  addTable = false
  editTable = false
  tableId: any
  isLoading = false
  permitActions = true

  constructor(private request: RequestsService) {}

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.deviceFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      ip_start: new FormControl('', Validators.required),
      last_ip: new FormControl('', Validators.required),
    })

    this.deviceAddForm = new FormGroup({
      address: new FormControl('', Validators.required),
      ip_start1: new FormControl(''),
      ip_start2: new FormControl(''),
      ip_start3: new FormControl(''),
      ip_start4: new FormControl(''),

    })

    this.deviceEditForm = new FormGroup({
      id: new FormControl('', Validators.required),
      ip_start1: new FormControl(''),
      ip_start2: new FormControl(''),
      ip_start3: new FormControl(''),
      ip_start4: new FormControl(''),
      address: new FormControl(''),

    })

    // this.isLoading = true
    // this.request.getUsersRequest().subscribe( (response: any) => {
    //   this.usersData = response.reverse()
    //   this.isLoading = false
    // }, error => {
    //   this.isLoading = false
    //   alert(error.error.Error)
    //    if(error.status == 401) {
    //     this.request.refreshRequest(localStorage.getItem('access_token')).subscribe( (response: any) => {
    //       localStorage.setItem('access_token', response.access_token)
    //       this.isLoading = false
    //       location.reload()
    //     })
    //   }
    // })

    var token: any = localStorage.getItem('access_token')
    var decoded: any = jwt_decode(token);
    console.log(decoded);

    if (decoded.user_role == 'admin') {
      this.permitActions = true
    } else if (decoded.user_role == 'sales') {
      this.permitActions = false
    }

  }
  // ip_start1(){
  //   let ip_start1 = this.deviceAddForm.value.ip_start1
  //   let ipaddressEdit1 = this.deviceEditForm.value.ip_start1

  //   if(ip_start1*1>255){
  //     this.deviceAddForm.controls['ip_start1'].setValue("255")
  //   }
  //   if(ipaddressEdit1*1>255){
  //     this.deviceEditForm.controls['ip_start1'].setValue("255")
  //   }
  //   // if(a.length>2){
  //   // }
  // }
  // ip_start2(){
  //   let ipaddress2 = this.deviceAddForm.value.ip_start2
  //   let ipaddressEdit2 = this.deviceEditForm.value.ip_start2


  //   if(ipaddress2*1>255){
  //     this.deviceAddForm.controls['ip_start2'].setValue("255")
  //   }
  //   if(ipaddressEdit2*1>255){
  //     this.deviceEditForm.controls['ip_start2'].setValue("255")
  //   }
  //   // if(a.length>2){
  //   // }
  // }
  // ip_start3(){
  //   let ipaddress3 = this.deviceAddForm.value.ip_start3
  //   let ipaddressEdit3 = this.deviceEditForm.value.ip_start3


  //   if(ipaddress3*1>255){
  //     this.deviceAddForm.controls['ip_start3'].setValue("255")
  //   }
  //   if(ipaddressEdit3*1>255){
  //     this.deviceEditForm.controls['ip_start3'].setValue("255")
  //   }
  //   // if(a.length>2){
  //   // }
  // }
  // ip_start4(){
  //   let ipaddress4 = this.deviceAddForm.value.ip_start4
  //   let ipaddressEdit4 = this.deviceEditForm.value.ip_start4

  //   if(ipaddress4*1>255){
  //     this.deviceAddForm.controls['ip_start4'].setValue("255")
  //   }
  //   if(ipaddressEdit4*1>255){
  //     this.deviceEditForm.controls['ipaddress4'].setValue("255")
  //   }
  //   // if(a.length>2){
  //   // }
  // }




  ip_start1(){
    let ip_start1 = this.deviceAddForm.value.ip_start1
    let ipaddressEdit1 = this.deviceEditForm.value.ip_start1

    if(ip_start1*1>255){
      this.deviceAddForm.controls['ip_start1'].setValue("255")
    }
    if(ipaddressEdit1*1>255){
      this.deviceEditForm.controls['ip_start1'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ip_start2(){
    let ipaddress2 = this.deviceAddForm.value.ip_start2
    let ipaddressEdit2 = this.deviceEditForm.value.ip_start2


    if(ipaddress2*1>255){
      this.deviceAddForm.controls['ip_start2'].setValue("255")
    }
    if(ipaddressEdit2*1>255){
      this.deviceEditForm.controls['ip_start2'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ip_start3(){
    let ipaddress3 = this.deviceAddForm.value.ip_start3
    let ipaddressEdit3 = this.deviceEditForm.value.ip_start3


    if(ipaddress3*1>255){
      this.deviceAddForm.controls['ip_start3'].setValue("255")
    }
    if(ipaddressEdit3*1>255){
      this.deviceEditForm.controls['ip_start3'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ip_start4(){
    let ipaddress4 = this.deviceAddForm.value.ip_start4
    let ipaddressEdit4 = this.deviceEditForm.value.ip_start4

    if(ipaddress4*1>255){
      this.deviceAddForm.controls['ip_start4'].setValue("255")
    }
    if(ipaddressEdit4*1>255){
      this.deviceEditForm.controls['ipaddress4'].setValue("255")
    }
    // if(a.length>2){
    // }
  }










  filterTable() {
    const deviceFilterFormData = {...this.deviceFilterForm.value}
    this.isLoading = true
    this.request.getFilterCameraRequest(deviceFilterFormData.id, deviceFilterFormData.ip_start, deviceFilterFormData.last_ip).subscribe( (response: any) => {
      this.deviceData = response.reverse()
      console.log(response)
      this.isLoading = false
    },error => {
      this.request.error(error)
    this.isLoading = false
    alert(error.error.Error)
  })
  }

  addNewTable() {

    const deviceAddFormData = {...this.deviceAddForm.value}
    this.isLoading = true
    let ip_start = `${this.deviceAddForm.controls['ip_start1'].value}.${this.deviceAddForm.controls['ip_start2'].value}.${this.deviceAddForm.controls['ip_start3'].value}.${this.deviceAddForm.controls['ip_start4'].value}`
    this.request.postCameraRequest(ip_start, deviceAddFormData.address).subscribe(response => {
      this.isLoading = false
      // location.reload()
    }, error => {
      this.request.error(error)
    this.isLoading = false
    alert(error.error.Error)
  })

  }
  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.deviceEditForm.patchValue(this.deviceData.filter( (res: any) => res.address ==  id)[0])
  }

  editNewTable() {

    this.isLoading = true
    let ip_start = `${this.deviceEditForm.controls['ip_start1'].value}.${this.deviceEditForm.controls['ip_start2'].value}.${this.deviceEditForm.controls['ip_start3'].value}.${this.deviceEditForm.controls['ip_start4'].value}`

    const deviceEditFormData = {...this.deviceEditForm.value}
    this.isLoading = true
    this.request.putCameraRequest(this.tableId, ip_start, deviceEditFormData.address).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
    this.isLoading = false
    alert(error.error.Error)
  })


  }
  deleteVlanRequest(id: string) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный ID: " + id)
    if(deleteConf == true) {
      this.request.deleteCameraRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
    } else {
      this.isLoading = false
    }
  }

}

<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="addModal_content" *ngIf="addTable">
  <div class="addModal_block">
    <i class="fas fa-times" (click)="addTable = false"></i>
    <form class="addForm" [formGroup]="deviceAddForm">
      <input  type="number"   class="addtable" placeholder="ID Акаунта" formControlName="account_id">
      <input  type="number"   class="addtable" placeholder="service Id" formControlName="service_id">
      <input  type="number"   class="addtable" placeholder="Цена" formControlName="price_cf">
      <button class="addButton"  (click)="addNewTable()">OK</button>
    </form>
  </div>
</div>

<div class="editModal_content" *ngIf="editTable">
  <div class="editModal_block">
    <i class="fas fa-times" (click)="editTable = false" ></i>
    <form class="editForm" [formGroup]="deviceEditForm">
      <input  type="number"   class="addtable" placeholder="ID Акаунта" formControlName="account_id">
      <input  type="number"   class="addtable" placeholder="ID Услуги" formControlName="service_id">
      <input  type="number"   class="addtable" placeholder="Цена" formControlName="price_cf">
      <button class="editButton"  (click)="editNewTable()">ОК</button>
    </form>
  </div>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Продукты</h1>
        <button (click)="addTable = true" *ngIf="permitActions">Добавить</button>
        <!-- <div class="search_block">
          <i class="fa fas fa-search"></i>
          <input type="search" placeholder="Поиск" [(ngModel)]="search">
        </div> -->
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="deviceFilterForm">
            <input type="text" placeholder="id" formControlName="type">
            <input type="text" placeholder="Название" formControlName="name">
            <input type="text" placeholder="Цена" formControlName="host">
            <input type="text" placeholder="Источник" formControlName="port">
            <input type="text" placeholder="Коментария" formControlName="user">
            <button (click)="filterTable()">ОК</button>
          </div>

          <!-- <div class="pagination_block">
            <input type="number" placeholder="0">
          </div> -->
        </form>
      </div>

      <div class="table_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>id</th>
              <th>Название</th>
              <th>Цена</th>
              <th>ID Услуги</th>
              <th>Коментария</th>
              <th>Коментария</th>
              <th>Коментария</th>
              <th>Коментария</th>
              <th *ngIf="permitActions">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle class="c1" cx="10" cy="10" r="9.5" stroke="black"/>
                  <circle class="c2" cx="10" cy="10" r="3" fill="black"/>
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let device of deviceData">
              <td><span>id</span>
                <h1>{{ device.id}}</h1>
              </td>
              <td><span>ID аккаунта</span>
                <h1>{{ device.account_id}}</h1>
              </td>

              <td><span>Цена</span>
                <h1>{{ device.service_id}}</h1>
              </td>
              <td><span>Источник</span>
                <h1>{{ device.created_by}}</h1>
              </td>
              <td><span>Коментария</span>
                <h1>{{ device.price_cf}}</h1>
              </td>
              <td><span>Коментария</span>
                <h1>{{ device.created_at | date}}</h1>
              </td>
              <td><span>Коментария</span>
                <h1>{{ device.next_payment | date}}</h1>
              </td>
              <td><span>Коментария</span>
                <h1>{{ device.deleted}}</h1>
              </td>

              <td *ngIf="permitActions"><span>Действия</span>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                 >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openEditTable(device.id)">
                    <mat-icon>edit</mat-icon>
                    <span>Изменить</span>
                  </button>

                  <button mat-menu-item (click)="deleteVlanRequest(device.id)">
                    <mat-icon>delete</mat-icon>
                    <span>Удалить</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination_block">
        <pagination-controls nextLabel="Далее" previousLabel="Назад" (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
  </div>
</div>



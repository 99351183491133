import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-routes-page',
  templateUrl: './routes-page.component.html',
  styleUrls: ['./routes-page.component.scss']
})
export class RoutesPageComponent implements OnInit {

  page: any
  search: any
  addTable = false
  editTable = false
  tableId: any
  isLoading = false
  permitActions = true
  routesFill: any = []
  formRoutes!: FormGroup
  constructor(private request: RequestsService, private router: Router) {}

  ngOnInit() {
  }
  addRoutes(){
  this.request.getRoutesRequest().subscribe(response => {
    console.log(response);
    this.routesFill = response
  },error => {
    this.request.error(error)
    alert(error.message)
  })}
}

import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../all.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reconciliation-report',
  templateUrl: './reconciliation-report.component.html',
  styleUrls: ['./reconciliation-report.component.scss']
})
export class ReconciliationReportComponent implements OnInit {

  reportsActiveData: any = []
  tarifsData: any = []
  accountsFilterForm!: FormGroup
  isLoading = false
  numAccounts: any = 0
  fileName: any;
  andCreateData = 'text'
  andCreate = ''
  StartCreateData ='text'
  StartCreate = ''
  constructor(private request: RequestsService) { }

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.accountsFilterForm = new FormGroup({
      date_from: new FormControl('', Validators.required),
      date_to: new FormControl('', Validators.required),
    })

    this.request.getTarifsRequest().subscribe( (response: any) => {
      this.tarifsData = response
    })
  }

  filterTable() {
    const accountsFilterFormData = {...this.accountsFilterForm.value}
    let date_from = new Date(accountsFilterFormData.date_from)
    let date_to = new Date(accountsFilterFormData.date_to)
    this.isLoading = true
    this.request.getFilterOwnRequest( date_from.toISOString(), date_to.toISOString()).subscribe(response => {
      this.reportsActiveData = response
      // console.log(this.reportsActiveData.tn1_b2b_d_count)

      this.isLoading = false
      // this.numAccounts = this.reportsActiveData.length
    },error => {
      this.request.error(error)
    })
  }
  changeAndCreateData(){
    this.andCreateData = 'date'
  }
  changeAndCreateText(){
    if(this.andCreate == ''){
      this.andCreateData = 'text'
    }else{
      this.andCreateData = 'date'
    }
  }
  changeStartCreateData(){
    this.StartCreateData = 'date'
  }
  changeStartCreateText(){
    if(this.StartCreate == ''){
      this.StartCreateData = 'text'
    }else{
      this.StartCreateData = 'date'
    }
  }
  // exportexcelAccount(): void {
  //   this.fileName = 'report-own.xlsx'
  //    /* table id is passed over here */
  //    let element = document.getElementById('excel-table-account');
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  //    var wscols = [
  //     {wch:8},
  //     {wch:40},
  //     {wch:20},
  //     {wch:20},
  //     {wch:20},
  //     {wch:20},
  //     {wch:30},
  //     {wch:30},
  //     {wch:30},
  //     {wch:30},
  //     {wch:40},
  //     {wch:30},
  //     {wch:30},
  //     {wch:10},
  //     {wch:20},
  //     {wch:10},
  //     {wch:15},
  //     {wch:40},
  //     {wch:40},
  //     {wch:20},
  //     {wch:15},
  // ];

  // ws['!cols'] = wscols;

  //    /* generate workbook and add the worksheet */
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //    /* save to file */
  //    XLSX.writeFile(wb, this.fileName);

  // }

}

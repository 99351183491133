import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-package-confirmation-page',
  templateUrl: './package-confirmation-page.component.html',
  styleUrls: ['./package-confirmation-page.component.scss']
})
export class PackageConfirmationPageComponent implements OnInit {
  packetConfirmation:any = []
  constructor(private request: RequestsService) { }

  ngOnInit(): void {

    this.request.getPackageConfirmationRequest().subscribe(response =>{
      this.packetConfirmation = response
      console.log(response)
    },error => {
      this.request.error(error)
    })
  }

  putConfirmationB2B(account_id: number){
    this.request.putConfirmationB2BRequest(account_id).subscribe(response =>{
      console.log(response)
      location.reload()
     }, error =>{
      this.request.error(error)
      alert(error)
      location.reload()
     })

  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';


@Component({
  selector: 'app-somon-tv',
  templateUrl: './somon-tv.component.html',
  styleUrls: ['./somon-tv.component.scss']
})
export class SomonTVComponent implements OnInit {
  deviceData: any = []
  deviceAddForm!: FormGroup
  deviceEditForm!: FormGroup
  deviceFilterForm!: FormGroup
  deviceNewEditForm!: FormGroup
  page: any
  search: any
  addTable = false
  editTable = false
  newEditTable = false
  tableId: any
  isLoading = false
  permitActions = true
  customer_id: any
  personal_account_id: any
  constructor(private request: RequestsService) {}

  ngOnInit() {
    // if(localStorage.getItem('isDark')) {
    //   document.body.classList.remove('dark-theme')
    // } else if(!localStorage.getItem('isDark')) {
    //   document.body.classList.toggle('dark-theme')
    // }
    this.deviceFilterForm = new FormGroup({
      login: new FormControl('', Validators.required),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      middle_name: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      birthday: new FormControl('', Validators.required),
      birthplace: new FormControl('', Validators.required),
      passport_issue_date: new FormControl('', Validators.required),
      passport_issue_place: new FormControl('', Validators.required),
      passport_number: new FormControl('', Validators.required),
      passport_series: new FormControl('', Validators.required),
      document_date: new FormControl('', Validators.required),
      document_number: new FormControl('', Validators.required),
      till: new FormControl('', Validators.required),
    })

    this.deviceAddForm = new FormGroup({
      login: new FormControl('', Validators.required),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      middle_name: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      birthday: new FormControl('', Validators.required),
      birthplace: new FormControl('', Validators.required),
      passport_issue_date: new FormControl('', Validators.required),
      passport_issue_place: new FormControl('', Validators.required),
      passport_number: new FormControl('', Validators.required),
      passport_series: new FormControl('', Validators.required),
      document_date: new FormControl('', Validators.required),
      document_number: new FormControl('', Validators.required),
      till: new FormControl('', Validators.required),
    })

    this.deviceEditForm = new FormGroup({
      login: new FormControl('', Validators.required),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      middle_name: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      birthday: new FormControl('', Validators.required),
      birthplace: new FormControl('', Validators.required),
      passport_issue_date: new FormControl('', Validators.required),
      passport_issue_place: new FormControl('', Validators.required),
      passport_number: new FormControl('', Validators.required),
      passport_series: new FormControl('', Validators.required),
      document_date: new FormControl('', Validators.required),
      document_number: new FormControl('', Validators.required),

    })
    this.deviceNewEditForm = new FormGroup({
      till: new FormControl('', Validators.required),
    })






  }




  filterTable() {
    const deviceFilterFormData = {...this.deviceFilterForm.value}
    this.isLoading = true
    this.request.getFilterProductSomonTVRequest(deviceFilterFormData.login,deviceFilterFormData.first_name,deviceFilterFormData.last_name, deviceFilterFormData.middle_name, deviceFilterFormData.password, deviceFilterFormData.comment, deviceFilterFormData.birthday,deviceFilterFormData.birthplace,deviceFilterFormData.passport_issue_date,deviceFilterFormData.passport_issue_place, deviceFilterFormData.passport_number, deviceFilterFormData.passport_series, deviceFilterFormData.document_date, deviceFilterFormData.document_number,).subscribe( (response: any) => {
      this.deviceData = response

      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }

  right_format_date(date: any){
    console.log(date)
    date = new Date(date)
    return `${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+(date.getDate())).slice(-2)}T${('0'+(date.getHours())).slice(-2)}:${('0'+(date.getMinutes())).slice(-2) }:${('0'+(date.getSeconds())).slice(-2)}+05:00`
    // return "2023-09-27T10:27:13+05:00"
  }

  addNewTable() {
    const deviceAddFormData = {...this.deviceAddForm.value}
    this.isLoading = true

    this.request.postSomonTVRequest(deviceAddFormData.login,deviceAddFormData.first_name, deviceAddFormData.last_name, deviceAddFormData.middle_name, deviceAddFormData.password, deviceAddFormData.comment, this.right_format_date(deviceAddFormData.birthday),deviceAddFormData.birthplace,this.right_format_date(deviceAddFormData.passport_issue_date), deviceAddFormData.passport_issue_place, deviceAddFormData.passport_number, deviceAddFormData.passport_series, this.right_format_date(deviceAddFormData.document_date), deviceAddFormData.document_number, this.right_format_date(deviceAddFormData.till)).subscribe(response => {
      this.isLoading = false
      console.log(deviceAddFormData.login, 'safajsfdasdfasdfsdfsdhud')
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
    })
  }

  openEditTable(id: number, customer_id: any, personal_account_id: any) {
    this.tableId = id
    this.editTable = true
    this.customer_id = customer_id
    this.personal_account_id = personal_account_id
    let res = this.deviceData.filter( (res: any) =>  res.login ==  id)[0]
    for(let i in res){
      console.log('---------------->',i)
      if(['passport_issue_date', 'document_date', 'till', 'birthday'].includes(i)){
        res[i] = new Date(res[i]).toLocaleDateString().split('.')
        res[i] = `${res[i][2]}-${res[i][1]}-${res[i][0]}`
      }
    }
    this.deviceEditForm.patchValue(res)
  }
  openNewEditTable(id: number) {
    this.tableId = id
    this.newEditTable = true
    let res = this.deviceData.filter( (res: any) =>  res.login ==  id)[0]
    for(let i in res){
      console.log('---------------->',i)
      if(['till'].includes(i)){
        res = new Date(res[i]).toLocaleDateString().split('.')
        res = `${res[i][2]}-${res[i][1]}-${res[i][0]}`
      }
    }
    this.deviceEditForm.patchValue(res)
  }

  editNewTable() {
    const deviceEditFormData = {...this.deviceEditForm.value}
    this.isLoading = true
    this.request.putSomonTVRequest(deviceEditFormData.login,deviceEditFormData.first_name, deviceEditFormData.last_name, deviceEditFormData.middle_name,this.right_format_date(deviceEditFormData.birthday), deviceEditFormData.comment, deviceEditFormData.password, deviceEditFormData.birthplace,this.right_format_date(deviceEditFormData.passport_issue_date), deviceEditFormData.passport_issue_place, deviceEditFormData.passport_number, deviceEditFormData.passport_series, this.right_format_date(deviceEditFormData.document_date), deviceEditFormData.document_number, this.right_format_date(deviceEditFormData.till),this.customer_id ,this.personal_account_id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  newEditNewTable() {
    const deviceNewEditFormData = {...this.deviceNewEditForm.value}
    this.isLoading = true
    this.request.putDateSomonTVRequest(this.tableId, this.right_format_date(deviceNewEditFormData.till)).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      // alert(error.error.Error)
    })
  }


  deleteVlanRequest(login: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный ID: " + login)
    console.log(deleteConf, 'state')
    if(deleteConf == true) {
      this.request.deleteSomonTvRequest(login).subscribe(response => {
        this.isLoading = false
        // location.reload()
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }



}

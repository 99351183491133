<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">

      <div class="title_block">
        <h1>Операция транзакции</h1>
      </div>
      <div class="uslugi_modal-container" *ngIf="paymentModal">
        <div class="uslugi_modal-content">
          <i class="fas fa-times" (click)="paymentModal = false"></i>
          <div class="uslugi_modal-block">
          <form class="payment_add-block" [formGroup]="addPaymentForm">
            <input type="number" placeholder="Сумма" [value]="serviceId">
            <input type="text" placeholder="Коментарии" formControlName="comment">
            <button (click)="addNewPayment()" [disabled]="addPaymentForm.invalid" [ngStyle]="{opacity: addPaymentForm.invalid ? '.6' : '1'}">Добавить</button>
          </form>
          </div>
        </div>
      </div>

      <div class="transaction-fill_content">
        <h1></h1>

        <table class="resp-tab">
          <thead>
            <tr>
                <th>Лиц.Счёт</th>
                <th>Оператор</th>
                <th>Сумма</th>
                <th>Дата</th>
                <th>Дата Транзакции</th>
                <th>ID Транзакции</th>
                <th>Комментарии</th>
                <th>Статус</th>
                <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transactionHistory of transactionHistoryData" [ngClass]="{'cancel': transactionHistory.status==0}">
              <td><span>Лиц.Счёт</span> {{ transactionHistory.account_id }} </td>
              <td><span>Оператор</span> {{ transactionHistory.operator }} </td>
              <td><span>Сумма</span> {{ transactionHistory.summ }} </td>
              <td><span>Дата</span> {{ transactionHistory.date | date: 'dd-MM-yyyy' }} </td>
              <td><span>Дата Транзакции</span> {{ transactionHistory.txn_date }} </td>
              <td><span>ID Транзакции</span> {{ transactionHistory.txn_id }} </td>
              <td><span>Комментарии</span> {{ transactionHistory.comment }} </td>
              <td><span>Статус</span> {{ transactionHistory.status }} </td>
              <td><span>Действия</span> <button [disabled]="!transactionHistory.status"  (click)="cancelTransaction(transactionHistory.id)">Отменить</button> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="total_summ-block" style="margin: 25px 0;">
        <h1 style="font-size: 19px; font-family: 'Roboto', sans-serif;">Полполнения: {{ summPlus }}</h1>
        <h1 style="font-size: 19px; font-family: 'Roboto', sans-serif;">Снятия: {{ summMinus }}</h1>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { TarifsPageComponent } from './tarifs-page/tarifs-page.component';
import { AccauntsPageComponent } from './accaunts-page/accaunts-page.component';
import { TransactionsPageComponent } from './transactions-page/transactions-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { UsersPageComponent } from './users-page/users-page.component';
import { HeaderComponent } from './header/header.component';
import { FirewallPageComponent } from './firewall-page/firewall-page.component';
import { TransactionHistoryPageComponent } from './transaction-history-page/transaction-history-page.component';
import { DevicesPageComponent } from './devices-page/devices-page.component';
import { ReportsActivePageComponent } from './reports-active-page/reports-active-page.component';
import { ReportsPassivePageComponent } from './reports-passive-page/reports-passive-page.component';
import { ReportsArchievePageComponent } from './reports-archieve-page/reports-archieve-page.component';
import { ReportsTarifsPageComponent } from './reports-tarifs-page/reports-tarifs-page.component';
import { ReportsAgePageComponent } from './reports-age-page/reports-age-page.component';
import { ReportsGenderPageComponent } from './reports-gender-page/reports-gender-page.component';
import { PermissionPageComponent } from './permission-page/permission-page.component';
import { RolesPageComponent } from './roles-page/roles-page.component';
import { HistoryChangePageComponent } from './history-change-page/history-change-page.component';
import { EquipmentPageComponent } from './equipment-page/equipment-page.component';
import { PromotionPageComponent } from './promotion-page/promotion-page.component';
import { IpGroupPageComponent } from './ip-group-page/ip-group-page.component';
import { Report1PageComponent } from './report1-page/report1-page.component';
import { Report2PageComponent } from './report2-page/report2-page.component';
import { Report3PageComponent } from './report3-page/report3-page.component';
import { RoutesPageComponent } from './routes-page/routes-page.component';
import { UserFilterPipe } from './user-filter.pipe';
import { ChangeTariffsPageComponent } from './change-tariffs-page/change-tariffs-page.component';
import { PackageConfirmationPageComponent } from './package-confirmation-page/package-confirmation-page.component';
import { AccountB2BPageComponent } from './account-b2-b-page/account-b2-b-page.component';
import { VlanPageComponent } from './vlan-page/vlan-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ReportProductPageComponent } from './report-product-page/report-product-page.component';
import { OltPageComponent } from './olt-page/olt-page.component';
import { CameraComponent } from './camera/camera.component';
import { AccessCameraComponent } from './access-camera/access-camera.component';
import { ReportRejectionComponent } from './report-rejection/report-rejection.component';
import { ReportOwnComponent } from './report-own/report-own.component';
import { ReconciliationReportComponent } from './reconciliation-report/reconciliation-report.component';
import { AccountHostingPageComponent } from './account-hosting-page/account-hosting-page.component';
import { VpsPageComponent } from './vps-page/vps-page.component';
import { IspPageComponent } from './isp-page/isp-page.component';
import { ProxyPageComponent } from './proxy-page/proxy-page.component';
import { SaleProxyPageComponent } from './sale-proxy-page/sale-proxy-page.component';
import { SaleIspPageComponent } from './sale-isp-page/sale-isp-page.component';
import { SaleVpsPageComponent } from './sale-vps-page/sale-vps-page.component';
import { SomonTVComponent } from './somon-tv/somon-tv.component';
import { HistoyDisabledComponent } from './histoy-disabled/histoy-disabled.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthPageComponent,
    TarifsPageComponent,
    AccauntsPageComponent,
    TransactionsPageComponent,
    ServicesPageComponent,
    UsersPageComponent,
    HeaderComponent,
    FirewallPageComponent,
    TransactionHistoryPageComponent,
    DevicesPageComponent,
    ReportsActivePageComponent,
    ReportsPassivePageComponent,
    ReportsArchievePageComponent,
    ReportsTarifsPageComponent,
    ReportsAgePageComponent,
    ReportsGenderPageComponent,
    PermissionPageComponent,
    RolesPageComponent,
    HistoryChangePageComponent,
    EquipmentPageComponent,
    PromotionPageComponent,
    IpGroupPageComponent,
    Report1PageComponent,
    Report2PageComponent,
    Report3PageComponent,
    RoutesPageComponent,
    UserFilterPipe,
    ChangeTariffsPageComponent,
    PackageConfirmationPageComponent,
    AccountB2BPageComponent,
    VlanPageComponent,
    ProductPageComponent,
    ReportProductPageComponent,
    OltPageComponent,
    CameraComponent,
    AccessCameraComponent,
    ReportRejectionComponent,
    ReportOwnComponent,
    ReconciliationReportComponent,
    AccountHostingPageComponent,
    VpsPageComponent,
    IspPageComponent,
    ProxyPageComponent,
    SaleProxyPageComponent,
    SaleIspPageComponent,
    SaleVpsPageComponent,
    SomonTVComponent,
    HistoyDisabledComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatExpansionModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import jwt_decode from "jwt-decode";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-account-b2-b-page',
  templateUrl: './account-b2-b-page.component.html',
  styleUrls: ['./account-b2-b-page.component.scss']
})
export class AccountB2BPageComponent implements OnInit {
  accountsData: any = []
  report1Data: any = []
  report2Data: any = []
  report3Data: any = []
  tarifsData: any = []
  reportData: any = []
  tarifId: any
  tarifModal = false
  changeTarifs!: FormGroup
  servicesData: any = []
  paymentData: any = []
  creditData: any = []
  servicesLinkData: any = []
  additionalIpData: any = []
  additionalInfoData: any = []
  fireWallData: any = []
  fireWall4Table: any = []
  deviceData: any = []
  status: any;
  rpostModal = false
  connectTypeData: any = []
  promotionData: any = []
  IPGroupData: any = []
  accountsAddForm!: FormGroup
  accountsEditForm!: FormGroup
  accountsFilterForm!: FormGroup
  addServiceForm!: FormGroup
  addPaymentForm!: FormGroup
  addCreditForm!: FormGroup
  addAdditionalIpForm!: FormGroup
  addAdditionalInfoForm!: FormGroup
  addExtandsForm!: FormGroup
  addRepostsForm!: FormGroup
  addTable = false
  editTable = false
  isLoading = false
  uslugiModal = false
  paymentModal = false
  creditModal = false
  editCredit = false
  additionalIpModal = false
  additionalInfoModal = false
  extandsModal = false
  editAdditionalInfo = false
  permitActions = true
  tableId: any
  page: any
  search: any
  serviceId: any
  pcketId: any
  numAccounts: any = 0
  summ = 0
  usersData: any = []
  fileName: any;
  typeData = 'text'
  binding = ''
  ageDate = 'text'
  age = ''
  startData = 'text'
  start = ''
  andCreateData = 'text'
  andCreate = ''
  StartCreateData ='text'
  StartCreate = ''
  packetModal = false
  packets: any = []
  packetsData: any = []
  addPacketForm!: FormGroup
  infoAccauntModal =false
  city_s: any
  district_s: any
  microdistrict_s: any
  street_s: any
  home_s: any
  apartment_s: any
  // ip:any
  additionalGeneralIpModal = false
  editingIp = false
  modalIp = false
  updateAdditionalIpForm!: FormGroup
  disabled = false
  disabledId: any
  disabledForm!: FormGroup
  macAddress: any
  macAddressIp: any
  suggestions: { mac_address: string }[] = [];
  filteredSuggestions: any = [];
  filteredSuggestionsIP: any = [];
  showSuggestionList = false;
  showSuggestionListIP = false;
  firewallId: any
  IpinfoAccountModal: boolean = false
  infoAccountArr: any = []
  mac: any
  constructor(private request: RequestsService) {}

  ngOnInit() {
    // if(localStorage.getItem('ip') != 'undefined'){
    //   this.addTable = true
    // }
    // this.ip = localStorage.getItem('ip')?.split('.')
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }
    this.disabledForm = new FormGroup({
      info: new FormControl(''),
    })
    this.addRepostsForm = new FormGroup({
      count: new FormControl('', Validators.required),
      product_id: new FormControl('', Validators.required)
    })

    this.accountsFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      speed_cf: new FormControl('', Validators.required),
      phone_number: new FormControl('', Validators.required),
      ipaddress: new FormControl('', Validators.required),
      acc_info: new FormControl('', Validators.required),
      conf_firewall_id: new FormControl('', Validators.required),
      passport: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl('', Validators.required),
      customr_type: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      start_create: new FormControl('', Validators.required),
      end_create: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      page_size: new FormControl('', Validators.required),
      org_name: new FormControl('', Validators.required),
      city_s: new FormControl(''),
      district_s: new FormControl(''),
      microdistrict_s: new FormControl(''),
      street_s: new FormControl(''),
      home_s: new FormControl(''),
      apartment_s: new FormControl(''),
    })

    this.accountsAddForm = new FormGroup({
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      price_cf: new FormControl(1, Validators.required),
      speed_cf: new FormControl(1, Validators.required),
      phone_number: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      inn: new FormControl(''),
      org_name: new FormControl(''),
      end_date: new FormControl(''),
      ipaddress1: new FormControl(''),
      ipaddress2: new FormControl(''),
      ipaddress3: new FormControl(''),
      ipaddress4: new FormControl(''),
      autoIpaddress: new FormControl(''),
      acc_info: new FormControl(''),
      conf_firewall_id: new FormControl(''),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl(1, Validators.required),
      customr_type: new FormControl('B2B', Validators.required),
      comment: new FormControl(''),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      table_id: new FormControl('4', Validators.required),
      connect_type: new FormControl(''),
      promo_id: new FormControl(''),
      comment_1: new FormControl(''),
      city_s: new FormControl(''),
      district_s: new FormControl(''),
      microdistrict_s: new FormControl(''),
      street_s: new FormControl(''),
      home_s: new FormControl(''),
      apartment_s: new FormControl(''),
      mac_addresss: new FormControl(''),

    })

    this.accountsEditForm = new FormGroup({
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      price_cf: new FormControl(1, Validators.required),
      speed_cf: new FormControl(1, Validators.required),
      phone_number: new FormControl('', Validators.required),
      inn: new FormControl(''),
      org_name: new FormControl(''),
      end_date: new FormControl(''),
      ipaddress1: new FormControl(''),
      ipaddress2: new FormControl(''),
      ipaddress3: new FormControl(''),
      ipaddress4: new FormControl(''),
      autoIpaddress: new FormControl(''),
      acc_info: new FormControl(''),
      conf_firewall_id: new FormControl(''),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl(''),
      customr_type: new FormControl('', Validators.required),
      comment: new FormControl(''),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      table_id: new FormControl('', Validators.required),
      connect_type: new FormControl(''),
      promo_id: new FormControl(''),
      comment_1: new FormControl(''),
      city_s: new FormControl(''),
      district_s: new FormControl(''),
      microdistrict_s: new FormControl(''),
      street_s: new FormControl(''),
      home_s: new FormControl(''),
      apartment_s: new FormControl(''),
      mac_addresss: new FormControl(''),
    })

    this.addServiceForm = new FormGroup({
      created_at: new FormControl(''),
      name: new FormControl('1', Validators.required)
    })

    this.changeTarifs = new FormGroup({
      update_date: new FormControl(''),
      tarifId: new FormControl(''),
      status: new FormControl(false),
      send_sms: new FormControl(false),
      rever_tarif: new FormControl(false),
      free: new FormControl(false),
    })
    this.addPaymentForm = new FormGroup({
      summa: new FormControl('', Validators.required),
      comment: new FormControl('')
    })

    this.addCreditForm = new FormGroup({
      comment: new FormControl(''),
      expire_at: new FormControl('')
    })

    this.addAdditionalIpForm = new FormGroup({
      ip: new FormControl(''),
      address: new FormControl(''),
      comment: new FormControl(''),
      mac_address: new FormControl(''),
    })

    this.addAdditionalInfoForm = new FormGroup({
      ip: new FormControl(''),
    })

    this.addExtandsForm = new FormGroup({
      end_date: new FormControl('', Validators.required),
    })

    // this.isLoading = true
    // this.request.getAccountsRequest().subscribe( (response: any) => {
    //   this.accountsData = response
    //   this.isLoading = false
    // }, error => {
    //   this.isLoading = false
    //   alert(error.error.Error)
    //    if(error.status == 401) {
    //     this.request.refreshRequest(localStorage.getItem('refresh_token')).subscribe( (response: any) => {
    //       localStorage.setItem('access_token', response.access_token)
    //       this.isLoading = false
    //       location.reload()
    //     })
    //   }
    // })

    this.request.getTarifsRequest().subscribe( (response: any) => {
      this.tarifsData = response
    },error=>{
        this.request.error(error)
       })

    this.request.getFilterUsersRequest('', '').subscribe( (response: any) => {
      this.usersData = response.reverse()
      this.isLoading = false
    },error=>{
        this.request.error(error)
       })

    this.request.getFireWallRequest().subscribe(response => {
      this.fireWallData = response

    },error=>{
      this.request.error(error)
     })
    var token: any = localStorage.getItem('access_token')
    var decoded: any = jwt_decode(token);

    if (decoded.user_role == 'admin') {
      this.permitActions = true
    } else if (decoded.user_role == 'sales') {
      this.permitActions = false
    }

    // this.request.getReport1Request().subscribe(response => {
    //   this.report1Data = response
    // })

    // this.request.getReport2Request().subscribe(response => {
    //   this.report2Data = response
    // })

    // this.request.getReport3Request().subscribe(response => {
    //   this.report3Data = response
    // })

    this.request.getContentTypeRequest().subscribe(response => {
      this.connectTypeData = response
    },error=>{
        this.request.error(error)
       })

    this.request.getDeviceRequest().subscribe(response => {
      this.deviceData = response
    },error=>{
        this.request.error(error)
       })

    this.request.getPromotionRequest().subscribe(response => {
      this.promotionData = response
    },error=>{
        this.request.error(error)
       })

    this.request.getIPGroupRequest().subscribe(response => {
      this.IPGroupData = response
    },error=>{
        this.request.error(error)
       })

    this.addPacketForm = new FormGroup({
      account_id: new FormControl(''),
      additional_traffic_id: new FormControl(''),
    })
    this.updateAdditionalIpForm = new FormGroup({
      ip: new FormControl(''),
      address: new FormControl(''),
      comment: new FormControl(''),
      mac_address: new FormControl(''),
    })
  }
  updateAdditionalIp(id: any) {
    this.tableId = id
    this.editingIp = true
    this.modalIp = true
    this.updateAdditionalIpForm.patchValue(this.additionalIpData.filter( (res: any) => res.id ==  id)[0])
  }
  ipadress1(){
    let ipaddress1 = this.accountsAddForm.value.ipaddress1
    let ipaddressEdit1 = this.accountsEditForm.value.ipaddress1

    if(ipaddress1*1>255){
      this.accountsAddForm.controls['ipaddress1'].setValue("255")
    }
    if(ipaddressEdit1*1>255){
      this.accountsEditForm.controls['ipaddress1'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  inn(){
    let ipaddress1 = this.accountsAddForm.value.inn
    let ipaddressEdit1 = this.accountsEditForm.value.inn

    if(ipaddress1*1>999999999){
      this.accountsAddForm.controls['inn'].setValue("999999999")
    }
    if(ipaddressEdit1*1>999999999){
      this.accountsEditForm.controls['inn'].setValue("999999999")
    }
    // if(a.length>2){
    // }
  }
  ipadress2(){
    let ipaddress2 = this.accountsAddForm.value.ipaddress2
    let ipaddressEdit2 = this.accountsEditForm.value.ipaddress2


    if(ipaddress2*1>255){
      this.accountsAddForm.controls['ipaddress2'].setValue("255")
    }
    if(ipaddressEdit2*1>255){
      this.accountsEditForm.controls['ipaddress2'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ipadress3(){
    let ipaddress3 = this.accountsAddForm.value.ipaddress3
    let ipaddressEdit3 = this.accountsEditForm.value.ipaddress3


    if(ipaddress3*1>255){
      this.accountsAddForm.controls['ipaddress3'].setValue("255")
    }
    if(ipaddressEdit3*1>255){
      this.accountsEditForm.controls['ipaddress3'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ipadress4(){
    let ipaddress4 = this.accountsAddForm.value.ipaddress4
    let ipaddressEdit4 = this.accountsEditForm.value.ipaddress4

    if(ipaddress4*1>255){
      this.accountsAddForm.controls['ipaddress4'].setValue("255")
    }
    if(ipaddressEdit4*1>255){
      this.accountsEditForm.controls['ipaddress4'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  addAdditionalIpo() {
    this.editingIp = false
    this.modalIp = true;
  }
  updateIP(){
    const updateAdditionalIpFormData = {...this.updateAdditionalIpForm.value}
    this.request.putAccountsIpRequest(this.tableId,updateAdditionalIpFormData.address, updateAdditionalIpFormData.comment, updateAdditionalIpFormData.ip, updateAdditionalIpFormData.mac_address).subscribe(res=>{
      location.reload();
      console.log(res)
    }, err=> { alert(err)})
  }
  filterTable() {
    const accountsFilterFormData = {...this.accountsFilterForm.value}
    if( (accountsFilterFormData.start_create && accountsFilterFormData.end_create) && (accountsFilterFormData.start_date && accountsFilterFormData.end_date)) {
      var start_create: any = new Date(accountsFilterFormData.start_create)
      var end_create: any = new Date(accountsFilterFormData.end_create)
      var start_date: any = new Date(accountsFilterFormData.start_date)
      var end_date: any = new Date(accountsFilterFormData.end_date)
      this.isLoading = true
      this.request.getFilterAccountsB2BRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, start_create.toLocaleDateString().split('.').reverse().join('.'), end_create.toLocaleDateString().split('.').reverse().join('.'), start_date.toLocaleDateString().split('.').reverse().join('.'), end_date.toLocaleDateString().split('.').reverse().join('.'), accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size, accountsFilterFormData.org_name,accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
        let n=0;
        this.accountsData = []
        response.forEach( (element: any) => {
          this.accountsData[n] = element;
          this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
          n++;
        });
        this.isLoading = false
        this.numAccounts = this.accountsData.length
        for (let i = 0; i < this.accountsData.length; i++) {
          this.summ += this.accountsData[i].tarif.price;

        }
        console.log(this.summ)
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
          // this.request.error(error)
      })
    } else if (accountsFilterFormData.start_create && accountsFilterFormData.end_create) {
        this.isLoading = true
        var start_create: any = new Date(accountsFilterFormData.start_create)
        var end_create: any = new Date(accountsFilterFormData.end_create)
        this.request.getFilterAccountsB2BRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, start_create.toLocaleDateString().split('.').reverse().join('.'), end_create.toLocaleDateString().split('.').reverse().join('.'), '', '', accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size,  accountsFilterFormData.org_name,accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
          let n=0;
          this.accountsData = []
          response.forEach( (element: any) => {
            this.accountsData[n] = element;
            this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
            n++;
          });
          this.isLoading = false
          this.numAccounts = this.accountsData.length
          for (let i = 0; i < this.accountsData.length; i++) {
            this.summ += this.accountsData[i].tarif.price;

          }
          console.log(this.summ)
        }, error => {
          this.isLoading = false
          // this.request.error(error)
          alert(error.error.Error)
        })
    } else if (accountsFilterFormData.start_date && accountsFilterFormData.end_date) {
        this.isLoading = true
        var start_date: any = new Date(accountsFilterFormData.start_date)
        var end_date: any = new Date(accountsFilterFormData.end_date)
        this.request.getFilterAccountsB2BRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, '', '', start_date.toLocaleDateString().split('.').reverse().join('.'), end_date.toLocaleDateString().split('.').reverse().join('.'), accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size, accountsFilterFormData.org_name,accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
          let n=0;
          this.accountsData = []
          response.forEach( (element: any) => {
            this.accountsData[n] = element;
            this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
            n++;
          });
          this.isLoading = false
          this.numAccounts = this.accountsData.length
          for (let i = 0; i < this.accountsData.length; i++) {
            this.summ += this.accountsData[i].tarif.price;

          }
          console.log(this.summ)
        }, error => {
          this.isLoading = false
          alert(error.error.Error)
            // this.request.error(error)
        })
    } else {
        this.isLoading = true
        this.request.getFilterAccountsB2BRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, '', '', '', '', accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size, accountsFilterFormData.org_name,accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
          let n=0;
          this.accountsData = []
          response.forEach( (element: any) => {
            this.accountsData[n] = element;
            this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
            n++;
          });
          this.isLoading = false
          this.numAccounts = this.accountsData.length
          for (let i = 0; i < this.accountsData.length; i++) {
            this.summ += this.accountsData[i].tarif.price;

          }
          console.log(this.summ)

        }, error => {
          this.isLoading = false
          alert(error.error.Error)
            // this.request.error(error)
        })
    }
  }
  changeTypedata() {
    this.typeData = 'date'
  }
  changeTypetext() {
    if(this.binding == ''){
      this.typeData = 'text'
    }else{
      this.typeData = 'date'
    }

  }
  changeAgeData(){
    this.ageDate = 'date'
  }
  changeAgeText(){
    if(this.age == ''){
      this.ageDate = 'text'
    }else{
      this.ageDate = 'date'
    }
  }
  changeStartData(){
    this.startData = 'date'
  }
  changeStartText(){
    if(this.start == ''){
      this.startData = 'text'
    }else{
      this.startData = 'date'
    }
  }
  changeAndCreateData(){
    this.andCreateData = 'date'
  }
  changeAndCreateText(){
    if(this.andCreate == ''){
      this.andCreateData = 'text'
    }else{
      this.andCreateData = 'date'
    }
  }
  changeStartCreateData(){
    this.StartCreateData = 'date'
  }
  changeStartCreateText(){
    if(this.StartCreate == ''){
      this.StartCreateData = 'text'
    }else{
      this.StartCreateData = 'date'
    }
  }
  addNewTable() {
    const accountsAddFormData = {...this.accountsAddForm.value}
    if(this.accountsAddForm.controls['ipaddress1'].value > 255 || this.accountsAddForm.controls['ipaddress2'].value > 255 || this.accountsAddForm.controls['ipaddress3'].value > 255 || this.accountsAddForm.controls['ipaddress4'].value > 255) {
      alert('Максимальное значение IP адресса не может превышать число 255')
    } else if( (this.accountsAddForm.controls['ipaddress1'].value == 255 && this.accountsAddForm.controls['ipaddress2'].value == 255 && this.accountsAddForm.controls['ipaddress3'].value == 255 && this.accountsAddForm.controls['ipaddress4'].value == 255) ) {
        alert('В IP адрессе не может быть 4 числа 255')
    } else {
        this.isLoading = true
        let ip_adress = `${this.accountsAddForm.controls['ipaddress1'].value}.${this.accountsAddForm.controls['ipaddress2'].value}.${this.accountsAddForm.controls['ipaddress3'].value}.${this.accountsAddForm.controls['ipaddress4'].value}`
        let age = new Date(accountsAddFormData.age)
        let end_date = new Date(accountsAddFormData.end_date)
        this.request.postAccountsB2BRequest(accountsAddFormData.fio, accountsAddFormData.tarif_id, accountsAddFormData.price_cf, accountsAddFormData.speed_cf, accountsAddFormData.phone_number,  accountsAddFormData.inn ,accountsAddFormData.org_name, ip_adress, accountsAddFormData.acc_info, accountsAddFormData.conf_firewall_id * 1,  accountsAddFormData.device * 1, parseInt(accountsAddFormData.overdraft), accountsAddFormData.customr_type, accountsAddFormData.comment, accountsAddFormData.connect_by, accountsAddFormData.contact_by, accountsAddFormData.table_id * 1, accountsAddFormData.connect_type * 1, accountsAddFormData.promo_id * 1, accountsAddFormData.comment_1, accountsAddFormData.city_s,accountsAddFormData.district_s,accountsAddFormData.microdistrict_s,accountsAddFormData.street_s,accountsAddFormData.home_s,accountsAddFormData.apartment_s,accountsAddFormData.mac_addresss).subscribe(response => {
          this.isLoading = false
          location.reload()
          this.request.getNextIPRequest(accountsAddFormData.autoIpaddress).subscribe(response => {
            console.log(response);
          },error=>{
            this.request.error(error)
           })
        }, error => {
          this.isLoading = false
          alert(error.error.Error)
            this.request.error(error)
        })
    }
  }
  lockAccountDisabledOpen(id: number) {
    this.disabled = true
    this.disabledId = id
  }
  lockAccountDisabled() {
    const disabledFormData = {... this.disabledForm.value}
    this.disabled = true
    this.isLoading = true
    this.request.getAccountDisabled(this.disabledId, disabledFormData.info).subscribe(response => {
      this.isLoading = false
      location.reload()
      console.log(response)
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }


  addNewReporte() {
    const addNewReporteFormData = {...this.addRepostsForm.value}
    this.isLoading = true
    this.request.postReporteRequest(addNewReporteFormData.product_id, this.serviceId, addNewReporteFormData.count).subscribe(response => {
      this.isLoading = false
      this.request.getReportsRequestByID(this.serviceId).subscribe(response => {
        this.reportData = response
        this.rpostModal = false;
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.isLoading = false
      alert(error.error.Error)
        this.request.error(error)
    })
  }
  openInfoAccauntModal(city_s : any,district_s : any,microdistrict_s : any,street_s : any,home_s : any,apartment_s : any, ){
    this.infoAccauntModal = true
    this.city_s = city_s
    this.district_s = district_s
    this.microdistrict_s = microdistrict_s
    this.street_s = street_s
    this.home_s =home_s
    this.apartment_s = apartment_s
  }
  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.accountsEditForm.patchValue(this.accountsData.filter( (res: any) => res.id ==  id)[0])
    let age = new Date(this.accountsData.filter( (res: any) => res.id ==  id)[0].age)
    let ageFormat = age.toLocaleDateString().split('.')
    // this.accountsEditForm.controls['age'].patchValue(`${ageFormat[2]}-${ageFormat[1]}-${ageFormat[0]}`)
    let end_date = new Date(this.accountsData.filter( (res: any) => res.id ==  id)[0].end_date)
    let endDateFormat = end_date.toLocaleDateString().split('.')
    this.accountsEditForm.controls['end_date'].patchValue(`${endDateFormat[2]}-${endDateFormat[1]}-${endDateFormat[0]}`)
    let activeItem = this.accountsData.filter( (res: any) => res.id ==  id)[0]
    let ip_adress = activeItem.ipaddress.split('.')
    this.accountsEditForm.controls['ipaddress1'].patchValue(ip_adress[0])
    this.accountsEditForm.controls['ipaddress2'].patchValue(ip_adress[1])
    this.accountsEditForm.controls['ipaddress3'].patchValue(ip_adress[2])
    this.accountsEditForm.controls['ipaddress4'].patchValue(ip_adress[3])
    this.accountsEditForm.controls['connect_by'].patchValue(activeItem.connect_user_id)
    this.accountsEditForm.controls['contact_by'].patchValue(activeItem.contact_user_id)
    this.accountsEditForm.controls['device'].patchValue(activeItem.device_type_id)
    this.accountsEditForm.controls['connect_type'].patchValue(activeItem.connect_type_id)
    this.accountsEditForm.controls['mac_addresss'].patchValue(activeItem.device_id)
  }
  openRepostsModal(id: number) {
    this.serviceId = id
    this.rpostModal = true
    this.isLoading = true
    this.request.getReportsRequestByID(id).subscribe( (response: any) => {
      this.reportData = response
      console.log(response)
      this.isLoading = false

    })

    this.request.getReporteRequest().subscribe( (response: any) => {
      this.reportData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }

  editNewTable() {
    const accountsEditFormData = {...this.accountsEditForm.value}
    if(this.accountsEditForm.controls['ipaddress1'].value > 255 || this.accountsEditForm.controls['ipaddress2'].value > 255 || this.accountsEditForm.controls['ipaddress3'].value > 255 || this.accountsEditForm.controls['ipaddress4'].value > 255) {
      alert('Максимальное значение IP адресса не может превышать число 255')
    } else if( (this.accountsEditForm.controls['ipaddress1'].value == 255 && this.accountsEditForm.controls['ipaddress2'].value == 255 && this.accountsEditForm.controls['ipaddress3'].value == 255 && this.accountsEditForm.controls['ipaddress4'].value == 255) ) {
      alert('В IP адрессе не может быть 4 числа 255')
    } else {
      this.isLoading = true
      let ip_adress = `${this.accountsEditForm.controls['ipaddress1'].value}.${this.accountsEditForm.controls['ipaddress2'].value}.${this.accountsEditForm.controls['ipaddress3'].value}.${this.accountsEditForm.controls['ipaddress4'].value}`
      let age = new Date(accountsEditFormData.age)
      let end_date = new Date(accountsEditFormData.end_date)
      this.request.putAccountsB2BRequest(this.tableId, accountsEditFormData.fio, accountsEditFormData.tarif_id, accountsEditFormData.price_cf, accountsEditFormData.speed_cf, accountsEditFormData.phone_number,accountsEditFormData.org_name,accountsEditFormData.inn, end_date.toISOString(), ip_adress, accountsEditFormData.acc_info, accountsEditFormData.conf_firewall_id * 1,  accountsEditFormData.device * 1, parseInt(accountsEditFormData.overdraft), accountsEditFormData.customr_type, accountsEditFormData.comment, accountsEditFormData.connect_by, accountsEditFormData.contact_by, accountsEditFormData.table_id * 1, accountsEditFormData.connect_type * 1, accountsEditFormData.promo_id * 1, accountsEditFormData.comment_1,accountsEditFormData.city_s,accountsEditFormData.district_s,accountsEditFormData.microdistrict_s,accountsEditFormData.street_s,accountsEditFormData.home_s,accountsEditFormData.apartment_s,accountsEditFormData.mac_addresss).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
    })
    }
  }

  deleteTable(id: number) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteAccountsRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
        this.request.error(error)
      })
    } else {
      this.isLoading = false
    }
  }

  openUslugiModal(id: any) {
    this.serviceId = id
    this.uslugiModal = true
    this.isLoading = true
    this.request.getServiceLinkRequestByID(id).subscribe( (response: any) => {
      this.servicesLinkData = response
      this.isLoading = false
    },error => {
      this.request.error(error)
    })

    this.request.getServicesRequest().subscribe( (response: any) => {
      this.servicesData = response
      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }
  openPcketModal(id: any) {
    this.pcketId = id
    this.packetModal = true
    this.isLoading = true
    this.request.getPacketByID(id).subscribe( (response: any) => {
      this.packets = response
      this.isLoading = false
    },error => {
      this.request.error(error)
    })

    this.request.getPacketRequest().subscribe( (response: any) => {
      console.log(response)
      this.packetsData = response
      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }


  deletePacket(id: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteServiceLinkRequest(id).subscribe(response => {
        this.isLoading = false
        this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
          this.servicesLinkData = response
        })
      }, error => {
        this.isLoading = false
        this.request.error(error)
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }



  addNewService() {
    const addServiceFormData = {...this.addServiceForm.value}
    this.isLoading = true
    let created_at = new Date(addServiceFormData.created_at)
    this.request.posServiceLinkRequest(this.serviceId, created_at.toISOString(), addServiceFormData.name).subscribe(response => {
      this.isLoading = false
      this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
        this.servicesLinkData = response
      },error => {
        this.request.error(error)
      })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  deleteService(id: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteServiceLinkRequest(id).subscribe(response => {
        this.isLoading = false
        this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
          this.servicesLinkData = response
        },error => {
          this.request.error(error)
        })
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }


  openPaymentModal(id: any) {
    this.serviceId = id
    this.paymentModal = true
  }

  addNewPayment() {
    const addPaymentFormData = {...this.addPaymentForm.value}
    this.isLoading = true
    this.request.postTransactions2Request(this.serviceId, addPaymentFormData.summa, addPaymentFormData.comment).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openCreditModal(id: any) {
    this.serviceId = id
    this.creditModal = true
    this.isLoading = true
    this.request.getcreditByID(id).subscribe( (response: any) => {
      this.creditData = response
      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }

  addNewCredit() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.postCreditRequest(this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
      },error => {
        this.request.error(error)
      })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  changeCredit(id: number) {
    this.editCredit = true
    let creditDataById = this.creditData.filter( (res: any) => res.id == id )[0]
    this.addCreditForm.patchValue(creditDataById)
    let expire_at = new Date(creditDataById.expire_at)
    let expire_atFormat = expire_at.toLocaleDateString().split('.')
    this.addCreditForm.controls['expire_at'].patchValue(`${expire_atFormat[2]}-${expire_atFormat[1]}-${expire_atFormat[0]}`)
    this.serviceId = creditDataById.id
  }

  addEditCredit() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.putCreditRequest(this.serviceId, this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
        this.editCredit = false
        this.addCreditForm.reset()
      },error => {
        this.request.error(error)
      })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openAddiitionalIpModal(id: any, firewallId: any) {
    this.serviceId = id
    this.firewallId = firewallId
    this.additionalGeneralIpModal = true
    this.isLoading = true
    this.request.getAdditionalIpRequests(id).subscribe( (response: any) => {
      this.additionalIpData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }
  openAddiitionalGeneralIpModal(id: any) {
    this.serviceId = id
    this.additionalIpModal = true

    this.isLoading = true
    this.request.getAdditionalIpRequests(id).subscribe( (response: any) => {
      this.additionalIpData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }

  addNewAdditionalIp() {
    const addAdditionalIpFormData = {...this.addAdditionalIpForm.value}
    this.isLoading = true
    this.request.postAdditionalIpRequest(this.serviceId, addAdditionalIpFormData.ip, addAdditionalIpFormData.address,addAdditionalIpFormData.comment, addAdditionalIpFormData.mac_address).subscribe(response => {
      this.isLoading = false
      location.reload()
      this.request.getAdditionalIpRequest().subscribe( (response: any) => {
        this.additionalIpData = response
      },error => {
        this.request.error(error)
      })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  deleteAdditionalIp(id: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteAdditionalIpRequest(id).subscribe(response => {
        this.isLoading = false
        this.request.getAdditionalIpRequest().subscribe(response => {
          this.additionalIpData = response
        },error => {
          this.request.error(error)
        })
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }

  openAdditionalInfoModal(id: any) {
    this.serviceId = id
    this.additionalInfoModal = true
    this.isLoading = true
    this.request.getAdditionalInfoRequest().subscribe( (response: any) => {
      this.additionalInfoData = response
      this.isLoading = false
    },error => {
      this.request.error(error)
    })

    // this.request.getUsersRequest().subscribe( (response: any) => {
    //   console.log(response);
    // })
  }

  addNewAdditionalInfo() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.postCreditRequest(this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
      },error => {
        this.request.error(error)
      })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  changeAdditionalInfo(id: number) {
    this.editCredit = true
    let creditDataById = this.creditData.filter( (res: any) => res.id == id )[0]
    this.addCreditForm.patchValue(creditDataById)
    let expire_at = new Date(creditDataById.expire_at)
    let expire_atFormat = expire_at.toLocaleDateString().split('.')
    this.addCreditForm.controls['expire_at'].patchValue(`${expire_atFormat[2]}-${expire_atFormat[1]}-${expire_atFormat[0]}`)
    this.serviceId = creditDataById.id
  }

  EditAdditionalInfo() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.putCreditRequest(this.serviceId, this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
        this.editCredit = false
        this.addCreditForm.reset()
      },error => {
        this.request.error(error)
      })
    }, error => {
        this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openExtandsModal(id: any) {
    this.serviceId = id
    this.extandsModal = true
  }

  addNewExtands() {
    const addExtandsFormData = {...this.addExtandsForm.value}
    this.isLoading = true
    let end_date = new Date(addExtandsFormData.end_date)
    this.request.putExtensionRequest(this.serviceId, end_date.toISOString()).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  refreshAccount(id: number) {
    this.isLoading = true
    this.request.getAccountRefresh(id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  lockAccountOff(id: number) {
    this.isLoading = true
    this.request.getAccountBlockOff(id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  lockAccountOn(id: number) {
    this.isLoading = true
    this.request.getAccountBlockOn(id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  openTarifModal(id: any, tarif_id: any, status: any) {
    this.serviceId = id
    this.tarifId = tarif_id
    this.status  = status
    console.log(tarif_id, status)
    this.tarifModal = true

  }
  changeTarif() {
    const changeTarifs = {...this.changeTarifs.value}

    let end_date = new Date(changeTarifs.update_date)
    console.log(end_date)
    console.log('fffffffffffffffffffffffffff',this.serviceId, changeTarifs.tarifId, changeTarifs.status,end_date.toLocaleDateString(), changeTarifs.send_sms , changeTarifs.rever_tarif,changeTarifs.free,)
    this.isLoading = true
    this.request.postChangeTarifRequest(this.serviceId, changeTarifs.tarifId, end_date.toISOString(), this.status, changeTarifs.send_sms , changeTarifs.rever_tarif,changeTarifs.free,).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false

    })

  }
  exportexcelAccount(): void {
      this.fileName = 'Аккаунт.xlsx'
       /* table id is passed over here */
       let element = document.getElementById('excel-table-account');
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       var wscols = [
        {wch:8},
        {wch:40},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:40},
        {wch:30},
        {wch:30},
        {wch:10},
        {wch:20},
        {wch:10},
        {wch:15},
        {wch:40},
        {wch:40},
        {wch:20},
        {wch:15},
    ];

    ws['!cols'] = wscols;

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);

    }

    addNewPacket(){
      const addPacketFormData = {...this.addPacketForm.value}
      this.request.postPacketRequest(this.pcketId, addPacketFormData.additional_traffic_id*1).subscribe(response =>
        {console.log(response)},error =>{
          alert(error.error.Error + ' !!!' )
            this.request.error(error)
        }
        )
    }
    changeDeviceId(){

      const accountsAddFormData = {...this.accountsAddForm.value}


          this.request.getDevice(accountsAddFormData.mac_addresss).subscribe(res => {
              this.filteredSuggestions = res
              this.showSuggestionList = true
          });
      }
    addlistToDeviceId(deviceId: String){
      const accountsAddFormData = {...this.accountsAddForm.value}
      this.macAddress = deviceId
      accountsAddFormData.mac_addresss = deviceId
      console.log(accountsAddFormData.mac_addresss)
      this.showSuggestionList = false
    }
    changeIpMac(){

      const accountsAddFormData = {...this.addAdditionalIpForm.value}


          this.request.getDevice(accountsAddFormData.mac_address).subscribe(res => {
              this.filteredSuggestionsIP = res
              this.showSuggestionListIP = true
          });
      }
      addlistToDeviceIdIpMac(deviceId: String){
        const accountsAddFormData = {...this.addAdditionalIpForm.value}
        this.macAddressIp = deviceId
        accountsAddFormData.mac_address = deviceId

        this.IpinfoAccountModal = true
      }
      openIpinfoAccountModal(id: any){
        this.IpinfoAccountModal = true
        this.request.getInfoIpAccount(id).subscribe((res: any)=> {
          this.infoAccountArr = res



        }, err=> {
          this.request.error(err)
        })
      }
      clearPortId(id: any){
        this.request.postClearPort(id+'').subscribe((res: any)=> {
            alert('ok')
        }, err=> {
          this.request.error(err)
        })
    }
      clearPortMac(){
        this.request.postClearPortMac(this.mac+'').subscribe((res: any)=> {
            alert('ok')
        }, err=> {
          this.request.error(err)
        })
    }
}

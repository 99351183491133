<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="addModal_content" *ngIf="addTable">
  <div class="addModal_block">
    <i class="fas fa-times" (click)="addTable = false"></i>
    <form class="addForm" [formGroup]="accountsAddForm">
      <div class="add_block_items">
        <h1 class="add_title">Логин</h1>
        <input class="addtable" type="text" formControlName="login">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">ФИО</h1>
        <input class="addtable" type="text" formControlName="fio">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Телефон</h1>
        <input class="addtable" type="number" formControlName="phone_number">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Коментария</h1>
        <input class="addtable" type="text" formControlName="comment">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Информация аккаунта</h1>
        <input class="addtable" type="text" formControlName="acc_info">
      </div>
      <button class="addButton" [disabled]="accountsAddForm.invalid" [ngStyle]="{opacity: accountsAddForm.invalid ? '.6' : '1'}"  (click)="addNewTable()">OK</button>

    </form>
  </div>
</div>

<div class="editModal_content" *ngIf="editTable">
  <div class="editModal_block">
    <i class="fas fa-times" (click)="editTable = false" ></i>
    <form class="editForm" [formGroup]="accountsEditForm">

      <div class="add_block_items">
        <h1 class="add_title">Логин</h1>
        <input class="addtable" type="text" formControlName="login">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">ФИО</h1>
        <input class="addtable" type="text" formControlName="fio">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Телефон</h1>
        <input class="addtable" type="number" formControlName="phone_number">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Коментария</h1>
        <input class="addtable" type="text" formControlName="comment">
      </div>
      <div class="add_block_items">
        <h1 class="add_title">Информация аккаунта</h1>
        <input class="addtable" type="text" formControlName="acc_info">
      </div>
      <button class="editButton" [disabled]="accountsEditForm.invalid" [ngStyle]="{opacity: accountsEditForm.invalid ? '0.6' : '1'}" (click)="editNewTable()">ОК</button>

    </form>
  </div>
</div>


<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Аккаунты</h1>
        <button (click)="addTable = true" *ngIf="permitActions">Добавить</button>
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="accountsFilterForm">
            <input type="number" placeholder="Лицевой Счёт" formControlName="id">
            <input type="text" placeholder="Логин" formControlName="login">
            <input type="text" placeholder="ФИО / КОМПАНИЯ" formControlName="fio">
            <input type="number" placeholder="Телефон" formControlName="phone_number">
            <input type="text" placeholder="ONU/PORT" formControlName="comment">
            <select formControlName="contact_by">
              <option value="">Кто привел</option>
              <option *ngFor="let item of usersData" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
            </select>
            <select formControlName="connect_by">
              <option value="">Кто подключил</option>
              <option *ngFor="let item of usersData" [value]="item.id">{{ item.login }}  - {{ item.fio }}</option>
            </select>
            <button (click)="filterTable()">ОК</button>
          </div>
        </form>
      </div>


      <div class="table_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>Лиц.Счёт</th>
              <th>Логин</th>
              <th>ФИО</th>
              <th>Баланс</th>
              <th>Телефон</th>
              <th>Кто привел</th>
              <th>Информация аккаунта</th>
              <th>Кто подключил</th>
              <th>Пароль</th>
              <th>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle class="c1" cx="10" cy="10" r="9.5" stroke="black"/>
                  <circle class="c2" cx="10" cy="10" r="3" fill="black"/>
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let accounts of accountsData | paginate: {itemsPerPage: 15, currentPage: page }" [formGroup]="accountsEditForm">
              <td><span class="table_span">Лиц.Счёт</span>
                <h1>{{ accounts.id }}</h1>
              </td>

              <td><span class="table_span">Логин</span>
                <h1>{{ accounts.login }}</h1>
              </td>

              <td><span class="table_span">ФИО</span>
                <h1>{{ accounts.fio }}</h1>
              </td>

              <td><span class="table_span">Баланс</span>
                <h1>{{ accounts.balance }}</h1>
              </td>

              <td><span class="table_span">Телефон</span>
                <h1>{{ accounts.phone_number }}</h1>
              </td>

              <td><span class="table_span">Кто привел</span>
                <h1>{{ accounts.comment }}</h1>
              </td>

              <td><span class="table_span">Информация аккаунта</span>
                <h1>{{ accounts.acc_info }}</h1>
              </td>
              <td><span class="table_span">Кто подключил</span>
                <h1>{{ accounts.user.login  }}</h1>
              </td>
              <td><span class="table_span">Пароль</span>
                <h1>{{ accounts.password }}</h1>
              </td>

              <td><span class="table_span">Действия</span>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openEditTable(accounts.id)" *ngIf="permitActions">
                    <mat-icon>edit</mat-icon>
                    <span>Изменить</span>
                  </button>

                  <button mat-menu-item (click)="deleteTable(accounts.id)" *ngIf="permitActions">
                    <mat-icon>delete</mat-icon>
                    <span>Удалить</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="paginations__block">
          <pagination-controls previousLabel="Назад" nextLabel="Далее" (pageChange)="page = $event"></pagination-controls>
      </div>
      </div>
      <div class="pagination_block">
        <div class="exel_container">
          <button style="padding: 5px; cursor: pointer;" (click)="exportexcelAccount()">Аккаунт</button>
          <button style="padding: 5px; margin: 0 10px; cursor: pointer;" routerLink="/report1">Отчёт 1</button>
          <button style="padding: 5px; margin-right: 10px; cursor: pointer;" routerLink="/report2">Отчёт 2</button>
          <button style="padding: 5px; cursor: pointer;" routerLink="/report3">Отчёт 3</button>
        </div>
        Количество аккаунтов: {{ numAccounts }}
        Общая сумма : {{ summ }}
      </div>
    </div>
  </div>
  <!-- -->

  <!-- Account Exel -->
  <table class="resp-tab"  id="excel-table-account" style="display: none;">
    <thead>
      <tr>
        <th>Лиц.Счёт</th>
        <th>Логин</th>
        <th>ФИО</th>
        <th>Баланс</th>
        <th>Телефон</th>
        <th>Кто привел</th>
        <th>Информация аккаунта</th>
        <th>Кто подключил</th>
        <th>Пароль</th>
        <th>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle class="c1" cx="10" cy="10" r="9.5" stroke="black"/>
            <circle class="c2" cx="10" cy="10" r="3" fill="black"/>
          </svg>
        </th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let accounts of accountsData" [formGroup]="accountsEditForm">
        <td>
          <h1>{{ accounts.id }}</h1>
        </td>

        <td>
          <h1>{{ accounts.fio }}</h1>
        </td>

        <td>
          <h1>{{ accounts.balance }}</h1>
        </td>

        <td>
          <h1>{{ accounts.phone_number }}</h1>
        </td>

        <td>
          <h1> {{ accounts.org_name }}</h1>
        </td>

        <td>
                <h1>111{{ accounts.comment }}</h1>
        </td>

        <td>
          <h1>{{ accounts.password }}</h1>
        </td>

      </tr>
    </tbody>
  </table>

  <!-- End of Account Exel -->
</div>


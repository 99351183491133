<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>История отключений аккаунта</h1>
      </div>

      <div class="tables_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>Лиц.Счёт</th>
              <th>Дата отключения</th>
              <th>Причина</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let accounts of accountsData">
              <td><span class="table_span">Лиц.Счёт</span>
                <h1>{{ accounts.account_id }}</h1>
              </td>

              <td><span class="table_span">Дата отключения</span>
                <h1>{{ accounts.created_at | date: 'dd-MM-yyyy'}}</h1>
              </td>

              <td><span class="table_span">Причина</span>
                <h1>{{ accounts.info }}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>




</div>

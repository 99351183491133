import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-change-tariffs-page',
  templateUrl: './change-tariffs-page.component.html',
  styleUrls: ['./change-tariffs-page.component.scss']
})
export class ChangeTariffsPageComponent implements OnInit {
  page: any
  search: any
  addTable = false
  editTable = false
  tableId: any
  isLoading = false
  permitActions = true
  changeTarrifs:any = []
  constructor(private request: RequestsService) { }

  ngOnInit(){







    this.request.getChangeTarifsRequest().subscribe( (response: any) => {
      this.changeTarrifs = response
      console.log(response)
      this.isLoading = false
    }, error=>{
      alert(error)
        this.request.error(error)
      })
    }
    tariffChange(account_id: number, status: number){
      this.request.putChangeTarifsRequest(account_id, status).subscribe(response =>{
        console.log(response)
      }, error =>{
        alert(error)
        this.request.error(error)
   })
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-histoy-disabled',
  templateUrl: './histoy-disabled.component.html',
  styleUrls: ['./histoy-disabled.component.scss']
})
export class HistoyDisabledComponent implements OnInit {

  accountsData: any = []
  changeHistoryData: any = []
  permitActions = true
  isLoading = false

  constructor(private route: ActivatedRoute, private request: RequestsService) {}

  ngOnInit() {


    this.route.params.subscribe( (param: any) => {
      this.isLoading = true
      this.request.getDisabledIdRequest(param.id).subscribe( (response: any) => {
        this.accountsData = response
        this.isLoading = false
        console.log(this.accountsData, 'ressssssss')
      },error => {
        this.request.error(error)
      })
    })



  }
}

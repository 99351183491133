import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-vlan-page',
  templateUrl: './vlan-page.component.html',
  styleUrls: ['./vlan-page.component.scss']
})
export class VlanPageComponent implements OnInit {
  deviceData: any = []
  deviceAddForm!: FormGroup
  deviceEditForm!: FormGroup
  deviceFilterForm!: FormGroup
  page: any
  search: any
  addTable = false
  editTable = false
  tableId: any
  isLoading = false
  permitActions = true

  constructor(private request: RequestsService) {}

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.deviceFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      ip_start: new FormControl('', Validators.required),
      last_ip: new FormControl('', Validators.required),
      gate_way: new FormControl('', Validators.required),
      mask: new FormControl('', Validators.required),
    })

    this.deviceAddForm = new FormGroup({
      id: new FormControl('', Validators.required),
      ip_start1: new FormControl(''),
      ip_start2: new FormControl(''),
      ip_start3: new FormControl(''),
      ip_start4: new FormControl(''),
      last_ip1: new FormControl(''),
      last_ip2: new FormControl(''),
      last_ip3: new FormControl(''),
      last_ip4: new FormControl(''),
      gate_way: new FormControl(''),
      mask: new FormControl(''),

    })

    this.deviceEditForm = new FormGroup({
      id: new FormControl('', Validators.required),
      ip_start1: new FormControl(''),
      ip_start2: new FormControl(''),
      ip_start3: new FormControl(''),
      ip_start4: new FormControl(''),
      last_ip1: new FormControl(''),
      last_ip2: new FormControl(''),
      last_ip3: new FormControl(''),
      last_ip4: new FormControl(''),
      gate_way: new FormControl(''),
      mask: new FormControl(''),
    })

    // this.isLoading = true
    // this.request.getUsersRequest().subscribe( (response: any) => {
    //   this.usersData = response.reverse()
    //   this.isLoading = false
    // }, error => {
    //   this.isLoading = false
    //   alert(error.error.Error)
    //    if(error.status == 401) {
    //     this.request.refreshRequest(localStorage.getItem('access_token')).subscribe( (response: any) => {
    //       localStorage.setItem('access_token', response.access_token)
    //       this.isLoading = false
    //       location.reload()
    //     })
    //   }
    // })

    var token: any = localStorage.getItem('access_token')
    var decoded: any = jwt_decode(token);
    console.log(decoded);

    if (decoded.user_role == 'admin') {
      this.permitActions = true
    } else if (decoded.user_role == 'sales') {
      this.permitActions = false
    }

  }
  ip_start1(){
    let ip_start1 = this.deviceAddForm.value.ip_start1
    let ipaddressEdit1 = this.deviceEditForm.value.ip_start1

    if(ip_start1*1>255){
      this.deviceAddForm.controls['ip_start1'].setValue("255")
    }
    if(ipaddressEdit1*1>255){
      this.deviceEditForm.controls['ip_start1'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ip_start2(){
    let ipaddress2 = this.deviceAddForm.value.ip_start2
    let ipaddressEdit2 = this.deviceEditForm.value.ip_start2


    if(ipaddress2*1>255){
      this.deviceAddForm.controls['ip_start2'].setValue("255")
    }
    if(ipaddressEdit2*1>255){
      this.deviceEditForm.controls['ip_start2'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ip_start3(){
    let ipaddress3 = this.deviceAddForm.value.ip_start3
    let ipaddressEdit3 = this.deviceEditForm.value.ip_start3


    if(ipaddress3*1>255){
      this.deviceAddForm.controls['ip_start3'].setValue("255")
    }
    if(ipaddressEdit3*1>255){
      this.deviceEditForm.controls['ip_start3'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ip_start4(){
    let ipaddress4 = this.deviceAddForm.value.ip_start4
    let ipaddressEdit4 = this.deviceEditForm.value.ip_start4

    if(ipaddress4*1>255){
      this.deviceAddForm.controls['ip_start4'].setValue("255")
    }
    if(ipaddressEdit4*1>255){
      this.deviceEditForm.controls['ipaddress4'].setValue("255")
    }
    // if(a.length>2){
    // }
  }




  last_ip1(){
    let last_ip1 = this.deviceAddForm.value.last_ip1
    let ipaddressEdit1 = this.deviceEditForm.value.last_ip1

    if(last_ip1*1>255){
      this.deviceAddForm.controls['last_ip1'].setValue("255")
    }
    if(ipaddressEdit1*1>255){
      this.deviceEditForm.controls['last_ip1'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  last_ip2(){
    let last_ip2 = this.deviceAddForm.value.last_ip2
    let ipaddressEdit2 = this.deviceEditForm.value.last_ip2


    if(last_ip2*1>255){
      this.deviceAddForm.controls['last_ip2'].setValue("255")
    }
    if(ipaddressEdit2*1>255){
      this.deviceEditForm.controls['last_ip2'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  last_ip3(){
    let last_ip3 = this.deviceAddForm.value.last_ip3
    let ipaddressEdit3 = this.deviceEditForm.value.last_ip3


    if(last_ip3*1>255){
      this.deviceAddForm.controls['last_ip3'].setValue("255")
    }
    if(ipaddressEdit3*1>255){
      this.deviceEditForm.controls['last_ip3'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  last_ip4(){
    let last_ip4 = this.deviceAddForm.value.last_ip4
    let ipaddressEdit4 = this.deviceEditForm.value.last_ip4

    if(last_ip4*1>255){
      this.deviceAddForm.controls['last_ip4'].setValue("255")
    }
    if(ipaddressEdit4*1>255){
      this.deviceEditForm.controls['last_ip4'].setValue("255")
    }
    // if(a.length>2){
    // }
  }











  filterTable() {
    const deviceFilterFormData = {...this.deviceFilterForm.value}
    this.isLoading = true
    this.request.getFilterVlanRequest(deviceFilterFormData.id, deviceFilterFormData.ip_start, deviceFilterFormData.last_ip).subscribe( (response: any) => {
      this.deviceData = response.reverse()
      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }

  addNewTable() {
    if(this.deviceAddForm.controls['ip_start1'].value > 255 || this.deviceAddForm.controls['ip_start2'].value > 255 || this.deviceAddForm.controls['ip_start3'].value > 255 || this.deviceAddForm.controls['ip_start4'].value > 255) {
      alert('Максимальное значение IP адресса не может превышать число 255')
    } else if( (this.deviceAddForm.controls['ip_start1'].value == 255 && this.deviceAddForm.controls['ip_start2'].value == 255 && this.deviceAddForm.controls['ip_start3'].value == 255 && this.deviceAddForm.controls['ip_start4'].value == 255) ) {
        alert('В IP адрессе не может быть 4 числа 255')
    } else {
    const deviceAddFormData = {...this.deviceAddForm.value}
    this.isLoading = true
    let ip_start = `${this.deviceAddForm.controls['ip_start1'].value}.${this.deviceAddForm.controls['ip_start2'].value}.${this.deviceAddForm.controls['ip_start3'].value}.${this.deviceAddForm.controls['ip_start4'].value}`
    let last_ip = `${this.deviceAddForm.controls['last_ip1'].value}.${this.deviceAddForm.controls['last_ip2'].value}.${this.deviceAddForm.controls['last_ip3'].value}.${this.deviceAddForm.controls['last_ip4'].value}`
    this.request.postVlanRequest(deviceAddFormData.id, ip_start, last_ip, deviceAddFormData.gate_way,deviceAddFormData.mask,).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  }
  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.deviceEditForm.patchValue(this.deviceData.filter( (res: any) => res.id ==  id)[0])
  }

  editNewTable() {
    if(this.deviceEditForm.controls['ip_start1'].value > 255 || this.deviceEditForm.controls['ip_start2'].value > 255 || this.deviceEditForm.controls['ip_start3'].value > 255 || this.deviceEditForm.controls['ip_start4'].value > 255) {
      alert('Максимальное значение IP адресса не может превышать число 255')
    } else if( (this.deviceEditForm.controls['ip_start1'].value == 255 && this.deviceEditForm.controls['ip_start2'].value == 255 && this.deviceEditForm.controls['ip_start3'].value == 255 && this.deviceEditForm.controls['ip_start4'].value == 255) ) {
        alert('В IP адрессе не может быть 4 числа 255')
    } else {
    this.isLoading = true
    let ip_start = `${this.deviceEditForm.controls['ip_start1'].value}.${this.deviceEditForm.controls['ip_start2'].value}.${this.deviceEditForm.controls['ip_start3'].value}.${this.deviceEditForm.controls['ip_start4'].value}`
    let last_ip = `${this.deviceEditForm.controls['last_ip1'].value}.${this.deviceEditForm.controls['last_ip2'].value}.${this.deviceEditForm.controls['last_ip3'].value}.${this.deviceEditForm.controls['last_ip4'].value}`
    const deviceEditFormData = {...this.deviceEditForm.value}
    this.isLoading = true
    this.request.putVlanRequest(this.tableId, ip_start, last_ip, deviceEditFormData.gate_way, deviceEditFormData.mask,).subscribe(response => {
      this.isLoading = false
      // location.reload()
    }, error => {
      this.isLoading = false
      alert(error.error.Error)
        // this.request.error(error)
    })
  }

  }
  deleteVlanRequest(id: string) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный ID: " + id)
    if(deleteConf == true) {
      this.request.deleteVlanRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }

}

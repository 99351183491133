import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-account-hosting-page',
  templateUrl: './account-hosting-page.component.html',
  styleUrls: ['./account-hosting-page.component.scss']
})
export class AccountHostingPageComponent implements OnInit {
  accountsData: any = []
  accountsAddForm!: FormGroup
  accountsEditForm!: FormGroup
  accountsFilterForm!: FormGroup
  addRepostsForm!: FormGroup
  addTable = false
  editTable = false
  isLoading = false
  editAdditionalInfo = false
  permitActions = true
  tableId: any
  page: any
  numAccounts: any = 0
  summ = 0
  usersData: any = []
  fileName: any;
  constructor(private request: RequestsService) {}

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }



    this.accountsFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      speed_cf: new FormControl('', Validators.required),
      phone_number: new FormControl('', Validators.required),
      ipaddress: new FormControl('', Validators.required),
      acc_info: new FormControl('', Validators.required),
      conf_firewall_id: new FormControl('', Validators.required),
      passport: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl('', Validators.required),
      customr_type: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      start_create: new FormControl('', Validators.required),
      end_create: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      page_size: new FormControl('', Validators.required)
    })

    this.accountsAddForm = new FormGroup({
      login: new FormControl('', Validators.required),
      fio: new FormControl('', Validators.required),
      phone_number: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      comment: new FormControl('', Validators.required),
      acc_info: new FormControl('',Validators.required),

    })

    this.accountsEditForm = new FormGroup({
      id: new FormControl('', Validators.required),
      login: new FormControl('', Validators.required),
      fio: new FormControl('', Validators.required),
      phone_number: new FormControl(1, [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      comment: new FormControl(1, Validators.required),
      acc_info: new FormControl('',Validators.required),
    })


  }


  filterTable() {
    const accountsFilterFormData = {...this.accountsFilterForm.value}
        this.isLoading = true
        this.request.getFilterAccountsHostingRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress).subscribe( (response: any) => {

          this.accountsData = response
          this.isLoading = false
          this.numAccounts = this.accountsData.length

        }, error => {
          this.isLoading = false
          alert(error.error.Error)
            this.request.error(error)
        })

  }
  addNewTable() {
    const accountsAddFormData = {...this.accountsAddForm.value}
        this.isLoading = true
        this.request.postAccountsHostingRequest(accountsAddFormData.login,accountsAddFormData.fio,accountsAddFormData.phone_number,accountsAddFormData.comment,accountsAddFormData.acc_info,).subscribe(response => {
          this.isLoading = false
          location.reload()

        }, error => {
          this.isLoading = false
          alert(error.error.Error)
            this.request.error(error)
        })

  }

  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.accountsEditForm.patchValue(this.accountsData.filter( (res: any) => res.id ==  id)[0])
    let age = new Date(this.accountsData.filter( (res: any) => res.id ==  id)[0].age)
    let ageFormat = age.toLocaleDateString().split('.')
    // this.accountsEditForm.controls['age'].patchValue(`${ageFormat[2]}-${ageFormat[1]}-${ageFormat[0]}`)
    let end_date = new Date(this.accountsData.filter( (res: any) => res.id ==  id)[0].end_date)
    let endDateFormat = end_date.toLocaleDateString().split('.')
    this.accountsEditForm.controls['end_date'].patchValue(`${endDateFormat[2]}-${endDateFormat[1]}-${endDateFormat[0]}`)
    let activeItem = this.accountsData.filter( (res: any) => res.id ==  id)[0]
    let ip_adress = activeItem.ipaddress.split('.')
    this.accountsEditForm.controls['ipaddress1'].patchValue(ip_adress[0])
    this.accountsEditForm.controls['ipaddress2'].patchValue(ip_adress[1])
    this.accountsEditForm.controls['ipaddress3'].patchValue(ip_adress[2])
    this.accountsEditForm.controls['ipaddress4'].patchValue(ip_adress[3])
    this.accountsEditForm.controls['connect_by'].patchValue(activeItem.connect_user_id)
    this.accountsEditForm.controls['contact_by'].patchValue(activeItem.contact_user_id)
    this.accountsEditForm.controls['device'].patchValue(activeItem.device_type_id)
    this.accountsEditForm.controls['connect_type'].patchValue(activeItem.connect_type_id)
  }
  editNewTable() {
    const accountsEditFormData = {...this.accountsEditForm.value}

      this.request.putAccountsHostingRequest(this.tableId, accountsEditFormData.login,accountsEditFormData.fio,accountsEditFormData.phone_number,accountsEditFormData.comment,accountsEditFormData.acc_info).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
    })

  }

  deleteTable(id: number) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteAccountsHostingRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
        this.request.error(error)
      })
    } else {
      this.isLoading = false
    }
  }





































  exportexcelAccount(): void {
      this.fileName = 'Аккаунт.xlsx'
       /* table id is passed over here */
       let element = document.getElementById('excel-table-account');
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       var wscols = [
        {wch:8},
        {wch:40},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:40},
        {wch:30},
        {wch:30},
        {wch:10},
        {wch:20},
        {wch:10},
        {wch:15},
        {wch:40},
        {wch:40},
        {wch:20},
        {wch:15},
    ];

    ws['!cols'] = wscols;

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);

    }



}
function jwt_decode(token: any): any {
  throw new Error('Function not implemented.');
}


<div class="devide_block">
    <app-header></app-header>
    <div class="container">
      <div class="container_content">
        <div class="title_block">
          <h1>Подтверждения пакета B2B</h1>
        </div>

        <div class="filter_content">
          <form class="filter_form">
            <div class="filter_block">
              <select name="" id="" placeholder="">
                <option value=""></option>
              </select>
              <select name="" id="" placeholder="">
                <option value=""></option>
              </select>

              <button>ОК</button>
            </div>
          </form>
        </div>

        <div class="table_container">
          <table class="resp-tab">
            <thead>
              <tr>
                <th>ЛС</th>
                <th>ФИО</th>
                <th>Тип</th>
                <th>Тариф</th>
                <th>Дата</th>
                <th>Статус</th>

              </tr>
            </thead>
            <tbody  >
                <tr  *ngFor="let packetConfirmations of packetConfirmation">
                <td>
                    <h1>{{packetConfirmations.account_id}}</h1>
                  </td>


                  <td>
                    <h1>{{packetConfirmations.account.fio}}</h1>
                  </td>

                  <td>
                    <h1>B2B</h1>
                  </td>

                  <td>
                    <h1>{{packetConfirmations.additional_traffic.name}}</h1>
                  </td>

                  <td>
                    <h1 class="status" >{{packetConfirmations.created_at| date}}</h1>
                  </td>
                  <td>
                    <h1  [style.background-color]="packetConfirmations.sell_user_id==2 ? 'green':  packetConfirmations.sell_user_id==0 ? 'red':'red'"  style="text-align: center;font-size: 20px; color: white !important;"  >{{packetConfirmations.sell_user_id }}</h1>
                  </td>
                <td>
                  <button class="btn-close" (click)="putConfirmationB2B(packetConfirmations.id)" [disabled]="packetConfirmations.sell_user_id==2"><img src="./assets/img/ok.png" alt=""></button>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination_block">
          <pagination-controls nextLabel="Далее" previousLabel="Назад"></pagination-controls>
        </div>
      </div>
    </div>



</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import * as XLSX from 'xlsx';
import { NONE_TYPE } from '@angular/compiler';


@Component({
  selector: 'app-report-own',
  templateUrl: './report-own.component.html',
  styleUrls: ['./report-own.component.scss']
})
export class ReportOwnComponent implements OnInit {
  last_data: any
  reportsActiveData: any = []
  tarifsData: any = []
  accountsFilterForm!: FormGroup
  isLoading = false
  numAccounts: any = 0
  fileName: any;
  andCreateData = 'text'
  andCreate = ''
  StartCreateData ='text'
  StartCreate = ''
  constructor(private request: RequestsService) { }

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.accountsFilterForm = new FormGroup({
      date_from: new FormControl('', Validators.required),
      date_to: new FormControl('', Validators.required),
      // b2b_d_actv_count: new FormControl('', Validators.required),
      // b2b_d_actv_price: new FormControl('', Validators.required),
      // b2b_d_dis_count: new FormControl('', Validators.required),
      // b2b_d_dis_price: new FormControl('', Validators.required),
      // b2b_k_actv_count: new FormControl('', Validators.required),
      // b2b_k_actv_price: new FormControl('', Validators.required),
      // b2b_k_dis_count: new FormControl('', Validators.required),
      // b2b_k_dis_price: new FormControl('', Validators.required),
      // tn1_b2c_d_count: new FormControl('', Validators.required),
      // tn1_b2c_d_dis_count: new FormControl('', Validators.required),
      // tn1_b2c_d_price: new FormControl('', Validators.required),
      // tn1_b2c_k_count: new FormControl('', Validators.required),
      // tn1_b2c_k_dis_count: new FormControl('', Validators.required),
      // tn1_b2c_k_price: new FormControl('', Validators.required),
      // tn2_b2c_d_count: new FormControl('', Validators.required),
      // tn2_b2c_d_dis_count: new FormControl('', Validators.required),
      // tn2_b2c_d_price: new FormControl('', Validators.required),
      // tn2_b2c_k_count: new FormControl('', Validators.required),
      // tn2_b2c_k_dis_count: new FormControl('', Validators.required),
      // tn2_b2c_k_price: new FormControl('', Validators.required),
      // tn3_b2c_d_count: new FormControl('', Validators.required),
      // tn3_b2c_d_dis_count: new FormControl('', Validators.required),
      // tn3_b2c_d_price: new FormControl('', Validators.required),
      // tn3_b2c_k_count: new FormControl('', Validators.required),
      // tn3_b2c_k_dis_count: new FormControl('', Validators.required),
      // tn3_b2c_k_price: new FormControl('', Validators.required),
      // tn4_b2c_d_count: new FormControl('', Validators.required),
      // tn4_b2c_d_dis_count: new FormControl('', Validators.required),
      // tn4_b2c_d_price: new FormControl('', Validators.required),
      // tn4_b2c_k_count: new FormControl('', Validators.required),
      // tn4_b2c_k_dis_count: new FormControl('', Validators.required),
      // tn4_b2c_k_price: new FormControl('', Validators.required),
    })

    this.request.getTarifsRequest().subscribe( (response: any) => {
      this.tarifsData = response
    })
  }

  active_base_b2b_k(data: any){
    return data.prev.active_base_b2b_k + data.b2b_k_actv_count - data.b2b_k_dis_count
  }
  active_base_b2b_d(data: any){
    return data.prev.active_base_b2b_d + data.b2b_d_actv_count - data.b2b_d_dis_count
  }
  active_base_b2c_k(data: any){
    let tmp = data.tn1_b2c_k_count + data.tn2_b2c_k_count + data.tn3_b2c_k_count + data.tn4_b2c_k_count
    let chern = data.tn1_b2c_k_dis_count + data.tn2_b2c_k_dis_count + data.tn3_b2c_k_dis_count  + data.tn4_b2c_k_dis_count
    return data.prev.active_base_b2c_k - chern + tmp
  }
  active_base_b2c_d(data: any){
    let tmp = data.tn1_b2c_d_count + data.tn2_b2c_d_count + data.tn3_b2c_d_count + data.tn4_b2c_d_count
    let chern = data.tn1_b2c_d_dis_count + data.tn2_b2c_d_dis_count + data.tn3_b2c_d_dis_count  + data.tn4_b2c_d_dis_count
    return data.prev.active_base_b2c_d - chern + tmp
  }
  rev_data_b2b_k(data: any){
    return  data.prev.rev_data_b2b_k + data.b2b_k_actv_price - data.b2b_k_dis_price
  }
  rev_data_b2b_d(data: any){
    return  data.prev.rev_data_b2b_d + data.b2b_d_actv_price - data.b2b_d_dis_price
  }
  rev_data_b2c_k(data: any){
    let chern = (data.tn1_b2c_k_dis_count * 99) + (data.tn2_b2c_k_dis_count * 125) + (data.tn3_b2c_k_dis_count * 225) + (data.tn4_b2c_k_dis_count * 375)
    let gross = data.tn1_b2c_k_price + data.tn2_b2c_k_price + data.tn3_b2c_k_price + data.tn4_b2c_k_price
    return data.prev.rev_data_b2c_k + gross - chern
  }
  rev_data_b2c_d(data: any){
    let chern = (data.tn1_b2c_d_dis_count * 99) + (data.tn2_b2c_d_dis_count * 125) + (data.tn3_b2c_d_dis_count * 225) + (data.tn4_b2c_d_dis_count * 375)
    let gross = data.tn1_b2c_d_price + data.tn2_b2c_d_price + data.tn3_b2c_d_price + data.tn4_b2c_d_price
    return data.prev.rev_data_b2c_d + gross - chern
  }

  freePrev(){
    let prev: any = {}
    prev['active_base_b2b_k'] = 0
    prev['active_base_b2b_d'] = 0
    prev['active_base_b2c_k'] = 0
    prev['active_base_b2c_d'] = 0
    prev['rev_data_b2b_k'] = 0
    prev['rev_data_b2b_d'] = 0
    prev['rev_data_b2c_k'] = 0
    prev['rev_data_b2c_d'] = 0
    return prev
  }

  makePrev(day: any){
    let prev: any = {}
    console.log(day)
    prev['active_base_b2b_k'] = this.active_base_b2b_k(Object.assign({}, day))
    prev['active_base_b2b_d'] = this.active_base_b2b_d(Object.assign({}, day))
    prev['active_base_b2c_k'] = this.active_base_b2c_k(Object.assign({}, day))
    prev['active_base_b2c_d'] = this.active_base_b2c_d(Object.assign({}, day))
    prev['rev_data_b2b_k'] = this.rev_data_b2b_k(Object.assign({}, day))
    prev['rev_data_b2b_d'] = this.rev_data_b2b_d(Object.assign({}, day))
    prev['rev_data_b2c_k'] = this.rev_data_b2c_k(Object.assign({}, day))
    prev['rev_data_b2c_d'] = this.rev_data_b2c_d(Object.assign({}, day))
    return prev
  }

  filterTable() {
    const accountsFilterFormData = {...this.accountsFilterForm.value}
    console.log()

    this.isLoading = true

    this.request.getFilterOwnRequest( accountsFilterFormData.date_from, accountsFilterFormData.date_to).subscribe(response => {

      this.reportsActiveData = response
      this.reportsActiveData = this.reportsActiveData.slice(2)
      // this.reportsActiveData[0].b2b_d_actv_count = accountsFilterFormData.b2b_d_actv_count
      // this.reportsActiveData[0].b2b_d_actv_price = accountsFilterFormData.b2b_d_actv_price
      // this.reportsActiveData[0].b2b_d_dis_count = accountsFilterFormData.b2b_d_dis_count
      // this.reportsActiveData[0].b2b_d_dis_price = accountsFilterFormData.b2b_d_actv_count
      // this.reportsActiveData[0].b2b_k_actv_count = accountsFilterFormData.b2b_k_actv_count
      // this.reportsActiveData[0].b2b_k_actv_price = accountsFilterFormData.b2b_k_actv_price
      // this.reportsActiveData[0].b2b_k_dis_count = accountsFilterFormData.b2b_k_dis_count
      // this.reportsActiveData[0].b2b_k_dis_price = accountsFilterFormData.b2b_k_dis_price
      // this.reportsActiveData[0].tn1_b2c_d_count = accountsFilterFormData.tn1_b2c_d_count
      // this.reportsActiveData[0].tn1_b2c_d_dis_count = accountsFilterFormData.tn1_b2c_d_dis_count
      // this.reportsActiveData[0].tn1_b2c_d_price = accountsFilterFormData.tn1_b2c_d_price
      // this.reportsActiveData[0].tn1_b2c_k_count = accountsFilterFormData.tn1_b2c_k_count
      // this.reportsActiveData[0].tn1_b2c_k_dis_count = accountsFilterFormData.tn1_b2c_k_dis_count
      // this.reportsActiveData[0].tn1_b2c_k_price = accountsFilterFormData.tn1_b2c_k_price
      // this.reportsActiveData[0].tn2_b2c_d_count = accountsFilterFormData.tn2_b2c_d_count
      // this.reportsActiveData[0].tn2_b2c_d_dis_count = accountsFilterFormData.tn2_b2c_d_dis_count
      // this.reportsActiveData[0].tn2_b2c_d_price = accountsFilterFormData.tn2_b2c_d_price
      // this.reportsActiveData[0].tn2_b2c_k_count = accountsFilterFormData.tn2_b2c_k_count
      // this.reportsActiveData[0].tn2_b2c_k_dis_count = accountsFilterFormData.tn2_b2c_k_dis_count
      // this.reportsActiveData[0].tn2_b2c_k_price = accountsFilterFormData.tn2_b2c_k_price
      // this.reportsActiveData[0].tn3_b2c_d_count = accountsFilterFormData.tn3_b2c_d_count
      // this.reportsActiveData[0].tn3_b2c_d_dis_count = accountsFilterFormData.tn3_b2c_d_dis_count
      // this.reportsActiveData[0].tn3_b2c_d_price = accountsFilterFormData.tn3_b2c_d_price
      // this.reportsActiveData[0].tn3_b2c_k_count = accountsFilterFormData.tn3_b2c_k_count
      // this.reportsActiveData[0].tn3_b2c_k_dis_count = accountsFilterFormData.tn3_b2c_k_dis_count
      // this.reportsActiveData[0].tn3_b2c_k_price = accountsFilterFormData.tn3_b2c_k_price
      // this.reportsActiveData[0].tn4_b2c_d_count = accountsFilterFormData.tn4_b2c_d_count
      // this.reportsActiveData[0].tn4_b2c_d_dis_count = accountsFilterFormData.tn4_b2c_d_dis_count
      // this.reportsActiveData[0].tn4_b2c_d_price = accountsFilterFormData.tn4_b2c_d_price
      // this.reportsActiveData[0].tn4_b2c_k_count = accountsFilterFormData.tn4_b2c_k_count
      // this.reportsActiveData[0].tn4_b2c_k_dis_count = accountsFilterFormData.tn4_b2c_k_dis_count
      // this.reportsActiveData[0].tn4_b2c_k_price = accountsFilterFormData.tn4_b2c_k_price
      this.isLoading = false
      this.reportsActiveData[0]['prev'] = this.freePrev()
      let prev = this.makePrev(this.reportsActiveData[0])
      for(let i = 1; i < this.reportsActiveData.length; i++){
        this.reportsActiveData[i]['prev'] = prev
        prev = this.makePrev(Object.assign({}, this.reportsActiveData[i]))
      }
      this.last_data = this.reportsActiveData[this.reportsActiveData.length - 1]

      for(let j = 0; j < this.reportsActiveData.length; j++){
        this.reportsActiveData[j]['B2C_Db'] = this.reportsActiveData[j].tn1_b2c_d_count + this.reportsActiveData[j].tn2_b2c_d_count + this.reportsActiveData[j].tn3_b2c_d_count + this.reportsActiveData[j].tn4_b2c_d_count
        this.reportsActiveData[j]['Chern_cost_B2B'] = this.reportsActiveData[j].b2b_k_dis_price + this.reportsActiveData[j].b2b_d_dis_price
        this.reportsActiveData[j]['Chern_cost_B2B'] = this.reportsActiveData[j].b2b_k_dis_price + this.reportsActiveData[j].b2b_d_dis_price
        this.reportsActiveData[j]['Cr1'] = this.reportsActiveData[j].Chern_cost_B2B == 0 ? 0 : this.reportsActiveData[j].b2b_k_dis_price / this.reportsActiveData[j].Chern_cost_B2B * 100
        this.reportsActiveData[j]['Chern_B2B'] = this.reportsActiveData[j].b2b_k_dis_count + this.reportsActiveData[j].b2b_d_dis_count
        this.reportsActiveData[j]['Cr2'] = this.reportsActiveData[j].Chern_B2B == 0 ? 0 : this.reportsActiveData[j].b2b_k_dis_count / this.reportsActiveData[j].Chern_B2B * 100
        this.reportsActiveData[j]['gross'] = this.reportsActiveData[j].b2b_d_actv_price + this.reportsActiveData[j].b2b_k_actv_price
        this.reportsActiveData[j]['Cr3'] = this.reportsActiveData[j].gross == 0 ? 0 : this.reportsActiveData[j].b2b_k_actv_price / this.reportsActiveData[j].gross * 100
        this.reportsActiveData[j]['grossBaseb2b'] = this.reportsActiveData[j].b2b_k_actv_count + this.reportsActiveData[j].b2b_d_actv_count
        this.reportsActiveData[j]['Cr4'] = this.reportsActiveData[j].grossBaseb2b == 0 ? 0 : this.reportsActiveData[j].b2b_k_actv_count / this.reportsActiveData[j].grossBaseb2b * 100
        this.reportsActiveData[j]['Cr5'] = this.reportsActiveData[j].Chern_cost_B2B == 0 ? 0 : this.reportsActiveData[j].b2b_d_dis_price / this.reportsActiveData[j].Chern_cost_B2B * 100
        this.reportsActiveData[j]['Cr6'] = this.reportsActiveData[j].Chern_B2B == 0 ? 0 : this.reportsActiveData[j].b2b_d_dis_count / this.reportsActiveData[j].Chern_B2B * 100
        this.reportsActiveData[j]['Cr7'] = this.reportsActiveData[j].gross == 0 ? 0 : this.reportsActiveData[j].b2b_d_actv_price / this.reportsActiveData[j].gross * 100
        this.reportsActiveData[j]['Cr8'] = this.reportsActiveData[j].grossBaseb2b == 0 ? 0 : this.reportsActiveData[j].b2b_d_actv_count / this.reportsActiveData[j].grossBaseb2b * 100
        this.reportsActiveData[j]['chernSgdb2c'] = this.reportsActiveData[j].tn1_b2c_k_dis_count + this.reportsActiveData[j].tn2_b2c_k_dis_count + this.reportsActiveData[j].tn3_b2c_k_dis_count + this.reportsActiveData[j].tn4_b2c_k_dis_count
        this.reportsActiveData[j]['Cr9'] = this.reportsActiveData[j].chernSgdb2c == 0 ? 0 : this.reportsActiveData[j].tn4_b2c_k_dis_count / this.reportsActiveData[j].chernSgdb2c * 100
        this.reportsActiveData[j]['grossRevb2c'] = this.reportsActiveData[j].tn1_b2c_k_price + this.reportsActiveData[j].tn2_b2c_k_price + this.reportsActiveData[j].tn3_b2c_k_price + this.reportsActiveData[j].tn4_b2c_k_price
        this.reportsActiveData[j]['Cr10'] = this.reportsActiveData[j].grossRevb2c == 0 ? 0 : this.reportsActiveData[j].tn4_b2c_k_price / this.reportsActiveData[j].grossRevb2c * 100

        console.log('qqqqq', this.reportsActiveData[j].b2b_k_actv_price / this.reportsActiveData[j].gross * 100)
      }

    },error=>{
     this.request.error(error)
    })


  }
  changeAndCreateData(){
    this.andCreateData = 'date'
  }
  changeAndCreateText(){
    if(this.andCreate == ''){
      this.andCreateData = 'text'
    }else{
      this.andCreateData = 'date'
    }
  }
  changeStartCreateData(){
    this.StartCreateData = 'date'
  }
  changeStartCreateText(){
    if(this.StartCreate == ''){
      this.StartCreateData = 'text'
    }else{
      this.StartCreateData = 'date'
    }
  }
  exportexcelAccount(): void {
    this.fileName = 'report-own.xlsx'
     /* table id is passed over here */
     let element = document.getElementById('excel-table-account');
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     var wscols = [
      {wch:8},
      {wch:40},
      {wch:20},
      {wch:20},
      {wch:20},
      {wch:20},
      {wch:30},
      {wch:30},
      {wch:30},
      {wch:30},
      {wch:40},
      {wch:30},
      {wch:30},
      {wch:10},
      {wch:20},
      {wch:10},
      {wch:15},
      {wch:40},
      {wch:40},
      {wch:20},
      {wch:15},
  ];

  ws['!cols'] = wscols;

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);

  }
}

<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Транзакции</h1>
        <!-- <button (click)="addButton()">{{ addText }}</button> -->
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="transactionsFilterForm">
            <input type="number" placeholder="Лицевой Счёт" formControlName="id">
            <input type="text" placeholder="Оператор" formControlName="operator">
            <input type="text" placeholder="Дата Транзакции" formControlName="txn_date">
            <input type="number" placeholder="ID Транзакции" formControlName="txn_id">
            <input type="text" placeholder="Комментарии" formControlName="comment">
            <input type="date" formControlName="start_date">
            <input type="date" formControlName="end_date">
            <select  formControlName="fireWall">
              <option *ngFor="let item of firewall" [value]="item.id">{{item.comment}}</option>
            </select>
            <select  formControlName="transaction_type_id">
              <option *ngFor="let item of tranzaktion" [value]="item.id">{{item.type}}</option>
            </select>
            <button (click)="filterTable()">ОК</button>
          </div>

          <div class="pagination_block">
            <!-- <button *ngIf="addTable" class="addButton" [disabled]="transactionsForm.invalid" [ngStyle]="{opacity: transactionsForm.invalid ? '.6' : '1'}" (click)="addNewTable()">OK</button> -->
            <!-- <input type="number" placeholder="0"> -->
          </div>
        </form>
      </div>

      <div class="table_container">
        <table class="resp-tab" >
          <thead>
            <tr>
              <th>Лиц.Счёт</th>
              <th>Оператор</th>
              <th>Сумма</th>
              <th>Тип транзакции</th>
              <th>Дата</th>
              <th>Дата Транзакции</th>
              <th>ID Транзакции</th>
              <th>Комментарии</th>
              <th>Файрволл</th>
              <th>ФИО</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="addTable" [formGroup]="transactionsForm" >
              <td></td>
              <td><input class="addtable" type="text" placeholder="Оператор"  formControlName="operator"></td>
              <td><input class="addtable" type="number" placeholder="Сумма" formControlName="sum"></td>
              <td><input class="addtable" type="date" placeholder="Дата" formControlName="date"></td>
              <td><input class="addtable" type="number" placeholder="Дата Транзакции" formControlName="txn_date"></td>
              <td><input class="addtable" type="number" placeholder="ID Транзакции" formControlName="txn_id"></td>
              <td><input class="addtable" type="number" placeholder="Комментарии" formControlName="comment"></td>
              <!-- <td><input class="addtable" type="number" placeholder="Файрволл" formControlName="comment"></td> -->
            </tr>

            <tr *ngFor="let transactions of transactionsData" [ngClass]="{'cancel': transactions.status==0}">
              <td><span>ID</span>
                <h1>{{ transactions.account_id }}</h1>
              </td>

              <td><span>Оператор</span>
                <h1>{{ transactions.operator }}</h1>
              </td>

              <td><span>Сумма</span>
                <h1>{{ transactions.summ }}</h1>
              </td>

              <td><span>Тип транзакции</span>
                <h1>{{t_type.get(transactions.transaction_type?.type) }}</h1>
              </td>

              <td><span>Дата</span>
                <h1>{{ transactions.date | date: 'dd-MM-yyyy' }}</h1>
              </td>

              <td><span>Дата Транзакции</span>
                <h1>{{transactions.txn_date | date: 'dd-MM-yyyy'}}</h1>
              </td>

              <td><span>ID Транзакции</span>
                <h1>{{ transactions.txn_id }}</h1>
              </td>

              <td><span>Комментарии</span>
                <h1>{{ transactions.comment }}</h1>
              </td>
              <td><span>Файрволл</span>
                <h1>{{ transactions.account.conf_firewall_id }}</h1>
              </td>
              <td style="width: 200px;"><span>ФИО</span>
                <h1>{{ transactions.account.fio }}</h1>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination_block">
        <h1>Сумма: {{ summ }}</h1>
        <pagination-controls nextLabel="Далее" previousLabel="Назад" (pageChange)="page = $event"></pagination-controls>
      </div>
      <table class="resp-tab" id="excel-table-account" style="display: none;">
        <thead>
          <tr>
            <th>Лиц.Счёт</th>
            <th>Оператор</th>
            <th>Сумма</th>
            <th>Тип транзакции</th>
            <th>Дата</th>
            <th>Дата Транзакции</th>
            <th>ID Транзакции</th>
            <th>Комментарии</th>
            <th>Файрволл</th>
            <th>ФИО</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="addTable" [formGroup]="transactionsForm" >
            <td></td>
            <td><input class="addtable" type="text" placeholder="Оператор"  formControlName="operator"></td>
            <td><input class="addtable" type="number" placeholder="Сумма" formControlName="sum"></td>
            <td><input class="addtable" type="date" placeholder="Дата" formControlName="date"></td>
            <td><input class="addtable" type="number" placeholder="Дата Транзакции" formControlName="txn_date"></td>
            <td><input class="addtable" type="number" placeholder="ID Транзакции" formControlName="txn_id"></td>
            <td><input class="addtable" type="number" placeholder="Комментарии" formControlName="comment"></td>
          </tr>

          <tr *ngFor="let transactions of transactionsData" [ngClass]="{'cancel': transactions.status==0}">
            <td>
              <h1>{{ transactions.account_id }}</h1>
            </td>

            <td>
              <h1>{{ transactions.operator }}</h1>
            </td>

            <td>
              <h1>{{ transactions.summ }}</h1>
            </td>

            <td>
              <h1>{{t_type.get(transactions.transaction_type?.type) }}</h1>
            </td>

            <td>
              <h1>{{ transactions.date | date: 'dd-MM-yyyy' }}</h1>
            </td>

            <td>
              <h1>{{ transactions.txn_date | date: 'dd-MM-yyyy' }}</h1>
            </td>

            <td>
              <h1>{{ transactions.txn_id }}</h1>
            </td>

            <td>
              <h1>{{ transactions.comment }}</h1>
            </td>
            <td>
              <h1>{{ transactions.account.conf_firewall_id }}</h1>
            </td>
            <td style="width: 200px;">
              <h1>{{ transactions.account.fio }}</h1>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination_block">
        <div class="exel_container">
          <button style="padding: 5px; cursor: pointer;" (click)="exportexcelAccount()">Транзакции</button>
          <!-- <button style="padding: 5px; cursor: pointer;" routerLink="/report3">Отчёт 3</button> -->
        </div>

      </div>
    </div>
  </div>





</div>

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-reports-archieve-page',
  templateUrl: './reports-archieve-page.component.html',
  styleUrls: ['./reports-archieve-page.component.scss']
})
export class ReportsArchievePageComponent implements OnInit {
  reportsActiveData: any = []
  isLoading = false
  numAccounts: any = 0

  constructor(private request: RequestsService) { }

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    let d = new Date()
    d.setMonth(d.getMonth() - 6)
    this.isLoading = true
    this.request.getFilterAccountsRequest('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', d.toISOString(),'','', '','','', '', '','', '', '').subscribe(response => {
      this.reportsActiveData = response
      this.isLoading = false
      this.numAccounts = this.reportsActiveData.length
    },error => {
      this.request.error(error)
    })
  }
}

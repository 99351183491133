import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

  deviceData: any = []
  deviceAddForm!: FormGroup
  deviceEditForm!: FormGroup
  deviceFilterForm!: FormGroup
  page: any
  search: any
  addTable = false
  editTable = false
  tableId: any
  isLoading = false
  permitActions = true

  constructor(private request: RequestsService) {}

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.deviceFilterForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      unit: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      score: new FormControl('', Validators.required),
    })

    this.deviceAddForm = new FormGroup({
      name: new FormControl('', Validators.required),
      unit: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      score: new FormControl('', Validators.required),
    })

    this.deviceEditForm = new FormGroup({
      id: new FormControl('',),
      name: new FormControl('', Validators.required),
      unit: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      score: new FormControl('', Validators.required),
    })



    var token: any = localStorage.getItem('access_token')
    var decoded: any = jwt_decode(token);
    console.log(decoded);


  }

  filterTable() {
    const deviceFilterFormData = {...this.deviceFilterForm.value}
    this.isLoading = true
    this.request.getFilterProductRequest(deviceFilterFormData.id,deviceFilterFormData.name, deviceFilterFormData.unit, deviceFilterFormData.price, deviceFilterFormData.score).subscribe( (response: any) => {
      this.deviceData = response.reverse()
      this.isLoading = false
    },error => {
      this.request.error(error)
    })
  }

  addNewTable() {
    const deviceAddFormData = {...this.deviceAddForm.value}
    this.isLoading = true
    this.request.postProductRequest(deviceAddFormData.name, deviceAddFormData.unit, deviceAddFormData.price, deviceAddFormData.score).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.deviceEditForm.patchValue(this.deviceData.filter( (res: any) => res.id ==  id)[0])
  }

  editNewTable() {
    const deviceEditFormData = {...this.deviceEditForm.value}
    this.isLoading = true
    this.request.putProductRequest(this.tableId,deviceEditFormData.name, deviceEditFormData.price, deviceEditFormData.unit, deviceEditFormData.score).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }


  deleteVlanRequest(id: string) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный ID: " + id)
    if(deleteConf == true) {
      this.request.deleteProuctRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }

}

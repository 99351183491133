<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Отчёт по возрасту</h1>
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="accountsFilterForm">
            <!-- <input type="text" placeholder="Лицевой Счёт" formControlName="id">
            <input type="text" placeholder="ФИО" formControlName="fio">
            <select class="addtable" formControlName="tarif_id">
              <option value="">Все</option>
              <option *ngFor="let tarifs of tarifsData" [value]="tarifs.id">{{ tarifs.name }}</option>
            </select>
            <input type="text" placeholder="Скорость" formControlName="speed_cf">
            <input type="text" placeholder="Адрес" formControlName="acc_info">
            <input type="text" placeholder="Паспорт" formControlName="passport"> -->
            <!-- <input type="date" placeholder="Год рождения" formControlName="age"> -->
            <!-- <select formControlName="gender">
              <option value="">Все</option>
              <option value="M">Man</option>
              <option value="F">Female</option>
            </select>
            <input type="text" placeholder="Ip Адрес" formControlName="ipaddress">
            <input type="text" placeholder="ONU/PORT" formControlName="comment">
            <select formControlName="status">
              <option value="">Все</option>
              <option>On</option>
              <option>Off</option>
            </select>
            <input type="date" formControlName="start_create">
            <input type="date" formControlName="end_create">
            <input type="date" formControlName="start_date">
            <input type="date" formControlName="end_date"> -->
            <!-- <button (click)="filterTable()">ОК</button> -->
          </div>

          <!-- <div class="pagination_block">
            <input type="number" placeholder="0">
          </div> -->
        </form>
      </div>

      <div class="table_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>Возраст</th>
              <th>Количество</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let Person of PersonAge">
              <td><span class="table_span">Возраст</span>
                <h1>{{ Person.age }}</h1>
              </td>

              <td><span class="table_span">Количество</span>
                <h1>{{ Person.count }}</h1>
              </td>


          </tbody>
        </table>
      </div>
      <div class="pagination_block">
        Количество аккаунтов: {{ numAccounts }}
      </div>
    </div>
  </div>
</div>

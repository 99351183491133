import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-transactions-page',
  templateUrl: './transactions-page.component.html',
  styleUrls: ['./transactions-page.component.scss']
})
export class TransactionsPageComponent implements OnInit {
  transactionsData: any = []
  transactionsForm!: FormGroup
  transactionsFilterForm!: FormGroup
  page: any
  search: any
  addTable = false
  editTable = false
  addText = 'Добавить'
  isLoading = false
  summ = 0
  fileName: any;
  firewall: any
  tranzaktion: any
  t_type: any
  constructor(private request: RequestsService) { }

  ngOnInit() {
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }
    this.t_type = new Map([
      ["online","Банк"],
      ["operators","Операторы"],
      ["balance correct","Корректировка баланса"],
      ["server transaction","Серверные транзакции"],
      ["reverse transaction","Возврат средств"],
      ["service transaction","Услуги"],
      ["product transaction","Продажа таваров"],
  ]);
    this.transactionsFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      operator: new FormControl('', Validators.required),
      txn_date: new FormControl('', Validators.required),
      txn_id: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      fireWall: new FormControl('', Validators.required),
      transaction_type_id: new FormControl('', Validators.required),
    })

    this.transactionsForm = new FormGroup({
      operator: new FormControl('', Validators.required),
      sum: new FormControl('', Validators.required),
      score: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      txn_date: new FormControl('', Validators.required),
      txn_id: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required)
    })

    // this.isLoading = true
    // this.request.getTransactionsRequest().subscribe(response => {
    //   this.transactionsData = response
    //   this.isLoading = false
    // }, error => {
    //   this.isLoading = false
    //   alert(error.error.Error)
    //   if(error.status == 401) {
    //    this.request.refreshRequest(localStorage.getItem('access_token')).subscribe( (response: any) => {
    //      localStorage.setItem('access_token', response.access_token)
    //      this.isLoading = false
    //      location.reload()
    //    })
    //   }
    // })
    this.request.getFireWalRequest().subscribe(res =>{
      this.firewall = res
    },error => {
      this.request.error(error)
    })
    this.request.getTranzaktionRequest().subscribe(res =>{
      this.tranzaktion = res
      console.log(this.tranzaktion)
    },error => {
      this.request.error(error)
    })
  }

  filterTable() {
    const transactionsFilterFormData = {...this.transactionsFilterForm.value}
    // console.log(transactionsFilterFormData.fireWall)
    if(transactionsFilterFormData.start_date && transactionsFilterFormData.end_date) {
      var start_date: any = new Date(transactionsFilterFormData.start_date)
      var end_date: any = new Date(transactionsFilterFormData.end_date)
      this.isLoading = true
      this.summ = 0
      this.request.getFilterTransactionsRequest(transactionsFilterFormData.id, transactionsFilterFormData.operator, transactionsFilterFormData.txn_date,  transactionsFilterFormData.txn_id, transactionsFilterFormData.comment,  start_date.toLocaleDateString().split('.').reverse().join('.'), end_date.toLocaleDateString().split('.').reverse().join('.'), transactionsFilterFormData.fireWall, transactionsFilterFormData.transaction_type_id).subscribe(response => {
        this.transactionsData = response
        this.isLoading = false
        this.transactionsData.forEach( (element: any) => {
          if (element.status >0){

            this.summ += element.summ
          }
        });
      },error => {
        this.request.error(error)
      })
    } else {
      this.isLoading = true
      this.summ = 0
      this.request.getFilterTransactionsRequest(transactionsFilterFormData.id, transactionsFilterFormData.operator, transactionsFilterFormData.txn_date,  transactionsFilterFormData.txn_id, transactionsFilterFormData.comment, '', '', transactionsFilterFormData.fireWall, transactionsFilterFormData.transaction_type_id).subscribe(response => {
        this.transactionsData = response
        this.isLoading = false
        this.transactionsData.forEach( (element: any) => {
          if (element.status >0){

            this.summ += element.summ
          }
        });
      },error => {
        this.request.error(error)
      })
    }
  }

  addButton() {
    this.addTable = !this.addTable
    if(this.addText == 'Добавить') {
      this.addText = 'Закрыть'
    } else if (this.addText == 'Закрыть') {
      this.addText = 'Добавить'
    }
  }

  addNewTable() {
    const transactionsFormData = {...this.transactionsForm.value}
    this.isLoading = true
    this.request.postTransactionsRequest(199, transactionsFormData.operator, transactionsFormData.sum, transactionsFormData.score, transactionsFormData.date, transactionsFormData.txn_date, transactionsFormData.txn_id, transactionsFormData.comment).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  exportexcelAccount(): void {
    this.fileName = 'Транзакции.xlsx'
     /* table id is passed over here */
     let element = document.getElementById('excel-table-account');
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     var wscols = [
      {wch:8},
      {wch:40},
      {wch:20},
      {wch:20},
      {wch:20},
      {wch:20},
      {wch:30},
      {wch:30},
      {wch:30},
      {wch:30},
      {wch:40},
      {wch:30},
      {wch:30},
      {wch:10},
      {wch:20},
      {wch:10},
      {wch:15},
      {wch:40},
      {wch:40},
      {wch:20},
      {wch:15},
  ];

  ws['!cols'] = wscols;

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);

  }

}

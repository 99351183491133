import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from '../all.service';
import jwt_decode from "jwt-decode";
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accaunts-page',
  templateUrl: './accaunts-page.component.html',
  styleUrls: ['./accaunts-page.component.scss'],
})
export class AccauntsPageComponent implements OnInit {
  accountsData: any = []
  report1Data: any = []
  report2Data: any = []
  report3Data: any = []
  tarifsData: any = []
  servicesData: any = []
  reportData: any = []
  paymentData: any = []
  creditData: any = []
  servicesLinkData: any = []
  additionalIpData: any = []
  additionalInfoData: any = []
  fireWallData: any = []
  fireWall4Table: any = []
  deviceData: any = []
  connectTypeData: any = []
  promotionData: any = []
  IPGroupData: any = []
  accountsAddForm!: FormGroup
  accountsEditForm!: FormGroup
  accountsFilterForm!: FormGroup
  addServiceForm!: FormGroup
  addPaymentForm!: FormGroup
  addCreditForm!: FormGroup
  addAdditionalIpForm!: FormGroup
  updateAdditionalIpForm!: FormGroup
  addAdditionalInfoForm!: FormGroup
  addExtandsForm!: FormGroup
  addsharesForm!: FormGroup
  addTable = false
  editTable = false
  isLoading = false
  uslugiModal = false
  paymentModal = false
  creditModal = false
  editCredit = false
  additionalIpModal = false
  additionalInfoModal = false
  extandsModal = false
  sharesModal = false
  editAdditionalInfo = false
  tarifModal = false
  permitActions = true
  tableId: any
  accountId:any
  page: any
  search: any
  serviceId: any
  tarifId: any
  status: any
  pcketId: any
  tarif: any = []
  numAccounts: any = 0
  summ = 0
  usersData: any = []
  fileName: any;
  typeData = 'text'
  binding = ''
  ageDate = 'text'
  age = ''
  startData = 'text'
  start = ''
  andCreateData = 'text'
  andCreate = ''
  StartCreateData ='text'
  StartCreate = ''
  packetModal = false
  packets: any = []
  packetsData: any = []
  addPacketForm!: FormGroup
  addRepostsForm!: FormGroup
  rpostModal = false
  changeTarifs!: FormGroup
  promotionForm!: FormGroup
  disabledForm!: FormGroup
  ip: any
  infoAccauntModal = false
  age_modal : any
  device_type_modal : any
  customr_type_modal : any
  connect_type_modal : any
  table_id_modal : any
  comment_1_modal : any
  gender_modal : any
  city_s: any
  district_s: any
  microdistrict_s: any
  street_s: any
  home_s: any
  apartment_s: any
  editingIp = false
  modalIp = false
  additionalGeneralIpModal = false
  city = ''
  promotionModal = false
  disabled = false
  disabledId: any
  macAddress: any
  macAddressIp: any
  suggestions: { mac_address: string }[] = [];
  filteredSuggestions: any = [];
  filteredSuggestionsIP: any = [];
  infoAccountArr: any = [];
  showSuggestionList = false;
  showSuggestionListIP = false;
  loaddingInfoAccountModal = false;
  firewallId: any
  IpinfoAccountModal = false
  mac: any
  constructor(private request: RequestsService, private route: ActivatedRoute) {}

  ngOnInit() {

    // this.route.queryParams.subscribe( res =>{
    //   // this.ip = res['ip'].split('.')
    //   localStorage.setItem('ip', res['ip'].split('.'))
    // })
    //  this.ip = localStorage.getItem('ip')?.split('.')
    console.log(new Date())
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }
    this.accountsFilterForm = new FormGroup({
      id: new FormControl('', Validators.required),
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      speed_cf: new FormControl('', Validators.required),
      phone_number: new FormControl('', Validators.required),
      ipaddress: new FormControl('', Validators.required),
      acc_info: new FormControl('', Validators.required),
      conf_firewall_id: new FormControl('', Validators.required),
      passport: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl('', Validators.required),
      customr_type: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      start_create: new FormControl('', Validators.required),
      end_create: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      page_size: new FormControl('', Validators.required),
      account_friend_id: new FormControl(''),
      city_s: new FormControl(''),
      district_s: new FormControl(''),
      microdistrict_s: new FormControl(''),
      street_s: new FormControl(''),
      home_s: new FormControl(''),
      apartment_s: new FormControl(''),
    })

    this.accountsAddForm = new FormGroup({
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      price_cf: new FormControl(1, Validators.required),
      speed_cf: new FormControl(1, Validators.required),
      phone_number: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      end_date: new FormControl(''),
      ipaddress1: new FormControl(''),
      ipaddress2: new FormControl(''),
      ipaddress3: new FormControl(''),
      ipaddress4: new FormControl(''),
      // ipaddress1: new FormControl(this.ip[0] == undefined ? '' : this.ip[0]),
      // ipaddress2: new FormControl(this.ip[1] == undefined ? '' : this.ip[1]),
      // ipaddress3: new FormControl(this.ip[2] == undefined ? '' : this.ip[2]),
      // ipaddress4: new FormControl(this.ip[3] == undefined ? '' : this.ip[3]),
      autoIpaddress: new FormControl(''),
      acc_info: new FormControl(''),
      conf_firewall_id: new FormControl(''),
      passport: new FormControl(''),
      age: new FormControl('', Validators.required),
      gender: new FormControl('M'),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl(1, Validators.required),
      customr_type: new FormControl('B2C', Validators.required),
      comment: new FormControl(''),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      table_id: new FormControl('4', Validators.required),
      connect_type: new FormControl(''),
      promo_id: new FormControl(''),
      friend_id: new FormControl(''),
      comment_1: new FormControl(''),
      city_s: new FormControl(''),
      district_s: new FormControl(''),
      microdistrict_s: new FormControl(''),
      street_s: new FormControl(''),
      home_s: new FormControl(''),
      apartment_s: new FormControl(''),
      mac_addresss: new FormControl(''),
    })

    this.accountsEditForm = new FormGroup({
      fio: new FormControl('', Validators.required),
      tarif_id: new FormControl('', Validators.required),
      price_cf: new FormControl(1, Validators.required),
      speed_cf: new FormControl(1, Validators.required),
      phone_number: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      ipaddress1: new FormControl(''),
      ipaddress2: new FormControl(''),
      ipaddress3: new FormControl(''),
      ipaddress4: new FormControl(''),
      autoIpaddress: new FormControl(''),
      acc_info: new FormControl(''),
      conf_firewall_id: new FormControl(''),
      passport: new FormControl(''),
      age: new FormControl('', Validators.required),
      gender: new FormControl(''),
      device: new FormControl('', Validators.required),
      overdraft: new FormControl(''),
      customr_type: new FormControl('', Validators.required),
      comment: new FormControl(''),
      connect_by: new FormControl('', Validators.required),
      contact_by: new FormControl('', Validators.required),
      table_id: new FormControl('', Validators.required),
      connect_type: new FormControl(''),
      promo_id: new FormControl(''),
      comment_1: new FormControl(''),
      city_s: new FormControl(''),
      district_s: new FormControl(''),
      microdistrict_s: new FormControl(''),
      street_s: new FormControl(''),
      home_s: new FormControl(''),
      apartment_s: new FormControl(''),
      mac_addresss: new FormControl(''),
    })

    this.addServiceForm = new FormGroup({
      created_at: new FormControl(''),
      name: new FormControl('1', Validators.required)
    })
    this.disabledForm = new FormGroup({
      info: new FormControl(''),
    })
    this.promotionForm = new FormGroup({
      promo_id: new FormControl(''),
    })
    this.changeTarifs = new FormGroup({
      update_date: new FormControl(''),
      tarifId: new FormControl(''),
      status: new FormControl(false),
      send_sms: new FormControl(false),
      rever_tarif: new FormControl(false),
      free: new FormControl(false),
    })
    this.addRepostsForm = new FormGroup({
      count: new FormControl('', Validators.required),
      product_id: new FormControl('', Validators.required)
    })


    this.addPaymentForm = new FormGroup({
      summa: new FormControl('', Validators.required),
      comment: new FormControl('')
    })

    this.addCreditForm = new FormGroup({
      comment: new FormControl(''),
      expire_at: new FormControl('')
    })

    this.addAdditionalIpForm = new FormGroup({
      ip: new FormControl(''),
      address: new FormControl(''),
      comment: new FormControl(''),
      mac_address: new FormControl(''),
    })
    this.updateAdditionalIpForm = new FormGroup({
      ip: new FormControl(''),
      address: new FormControl(''),
      comment: new FormControl(''),
      mac_address: new FormControl(''),
    })

    this.addAdditionalInfoForm = new FormGroup({
      ip: new FormControl(''),
    })

    this.addExtandsForm = new FormGroup({
      end_date: new FormControl('', Validators.required),
    })
    this.addsharesForm = new FormGroup({
      tarif: new FormControl('', Validators.required),
    })

    // this.isLoading = true
    // this.request.getAccountsRequest().subscribe( (response: any) => {
    //   this.accountsData = response
    //   this.isLoading = false
    // }, error => {
    //   this.isLoading = false
    //   alert(error.error.Error)
    //    if(error.status == 401) {
    //     this.request.refreshRequest(localStorage.getItem('refresh_token')).subscribe( (response: any) => {
    //       localStorage.setItem('access_token', response.access_token)
    //       this.isLoading = false
    //       location.reload()
    //     })
    //   }
    // })

    this.request.getTarifsRequest().subscribe( (response: any) => {
      this.tarifsData = response
      console.log(response, 'sdfsdfsdfsdf')
    },error=>{
      this.request.error(error)
     })

    this.request.getFilterUsersRequest('', '').subscribe( (response: any) => {
      this.usersData = response.reverse()
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
     this.request.getAccountDisabledHistory().subscribe((res: any)=> {
      console.log(res, 'resssssssssssssssssssssss')
    },error=>{
      console.log(error, 'eeeeee')
    })
    this.request.getFireWallRequest().subscribe(response => {
      this.fireWallData = response

    },error=>{
      this.request.error(error)
     })
    var token: any = localStorage.getItem('access_token')
    var decoded: any = jwt_decode(token);

    if (decoded.user_role == 'admin') {
      this.permitActions = true
    } else if (decoded.user_role == 'sales') {
      this.permitActions = false
    }

    // this.request.getReport1Request().subscribe(response => {
    //   this.report1Data = response
    // })

    // this.request.getReport2Request().subscribe(response => {
    //   this.report2Data = response
    // })

    // this.request.getReport3Request().subscribe(response => {
    //   this.report3Data = response
    // })

    this.request.getContentTypeRequest().subscribe(response => {
      this.connectTypeData = response
    },error=>{
      this.request.error(error)
     })

    this.request.getDeviceRequest().subscribe(response => {
      this.deviceData = response
    },error=>{
      this.request.error(error)
     })

    this.request.getPromotionRequest().subscribe(response => {
      this.promotionData = response
    },error=>{
      this.request.error(error)
     })

    this.request.getIPGroupRequest().subscribe(response => {
      this.IPGroupData = response
    },error=>{
      this.request.error(error)
     })

    this.addPacketForm = new FormGroup({
      account_id: new FormControl(''),
      additional_traffic_id: new FormControl(''),
    })

  }

  ipadress1(){
    let ipaddress1 = this.accountsAddForm.value.ipaddress1
    let ipaddressEdit1 = this.accountsEditForm.value.ipaddress1

    if(ipaddress1*1>255){
      this.accountsAddForm.controls['ipaddress1'].setValue("255")
    }
    if(ipaddressEdit1*1>255){
      this.accountsEditForm.controls['ipaddress1'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ipadress2(){
    let ipaddress2 = this.accountsAddForm.value.ipaddress2
    let ipaddressEdit2 = this.accountsEditForm.value.ipaddress2


    if(ipaddress2*1>255){
      this.accountsAddForm.controls['ipaddress2'].setValue("255")
    }
    if(ipaddressEdit2*1>255){
      this.accountsEditForm.controls['ipaddress2'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ipadress3(){
    let ipaddress3 = this.accountsAddForm.value.ipaddress3
    let ipaddressEdit3 = this.accountsEditForm.value.ipaddress3


    if(ipaddress3*1>255){
      this.accountsAddForm.controls['ipaddress3'].setValue("255")
    }
    if(ipaddressEdit3*1>255){
      this.accountsEditForm.controls['ipaddress3'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  ipadress4(){
    let ipaddress4 = this.accountsAddForm.value.ipaddress4
    let ipaddressEdit4 = this.accountsEditForm.value.ipaddress4

    if(ipaddress4*1>255){
      this.accountsAddForm.controls['ipaddress4'].setValue("255")
    }
    if(ipaddressEdit4*1>255){
      this.accountsEditForm.controls['ipaddress4'].setValue("255")
    }
    // if(a.length>2){
    // }
  }
  filterTable() {
    const accountsFilterFormData = {...this.accountsFilterForm.value}
    if( (accountsFilterFormData.start_create && accountsFilterFormData.end_create) && (accountsFilterFormData.start_date && accountsFilterFormData.end_date)) {
      var start_create: any = new Date(accountsFilterFormData.start_create)
      var end_create: any = new Date(accountsFilterFormData.end_create)
      var start_date: any = new Date(accountsFilterFormData.start_date)
      var end_date: any = new Date(accountsFilterFormData.end_date)
      this.isLoading = true
      this.request.getFilterAccountsRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, start_create.toLocaleDateString().split('.').reverse().join('.'), end_create.toLocaleDateString().split('.').reverse().join('.'), start_date.toLocaleDateString().split('.').reverse().join('.'), end_date.toLocaleDateString().split('.').reverse().join('.'), accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size,accountsFilterFormData.account_friend_id, accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
        let n=0;
        this.accountsData = []

        response.forEach( (element: any) => {
          this.accountsData[n] = element;
          this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
          n++;
        });
        // var a = new Date();
        // var b = new Date(0, 0, 90, 0, 0, 0, 0);

        // console.log(typeof(this.accountsData.end_date), this.accountsData.end_date)
        // if( a - this.accountsData.end_date >= b){
        //   this.accountsData.account_status?.status
        // }

        this.isLoading = false
        this.numAccounts = this.accountsData.length

    for (let i = 0; i < this.accountsData.length; i++) {
      this.summ += this.accountsData[i].tarif.price;

    }
    console.log(response[0].age, '11111rewfreweewrtfgwer')
      },error=>{
        this.request.error(error)
        alert(error.message)
       }
      )
    } else if (accountsFilterFormData.start_create && accountsFilterFormData.end_create) {
        this.isLoading = true
        var start_create: any = new Date(accountsFilterFormData.start_create)
        var end_create: any = new Date(accountsFilterFormData.end_create)
        this.request.getFilterAccountsRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, start_create.toLocaleDateString().split('.').reverse().join('.'), end_create.toLocaleDateString().split('.').reverse().join('.'), '', '', accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size, accountsFilterFormData.account_friend_id, accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
          let n=0;
          this.accountsData = []

          response.forEach( (element: any) => {
            this.accountsData[n] = element;
            this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
            n++;
          });
          this.isLoading = false
          this.numAccounts = this.accountsData.length
          for (let i = 0; i < this.accountsData.length; i++) {
            this.summ += this.accountsData[i].tarif.price;

          }
          console.log(response[0].age, '11111rewfreweewrtfgwer')

        },error=>{
          this.request.error(error)
          alert(error.message)
         })

    } else if (accountsFilterFormData.start_date && accountsFilterFormData.end_date) {
        this.isLoading = true
        var start_date: any = new Date(accountsFilterFormData.start_date)
        var end_date: any = new Date(accountsFilterFormData.end_date)
        this.request.getFilterAccountsRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, '', '', start_date.toLocaleDateString().split('.').reverse().join('.'), end_date.toLocaleDateString().split('.').reverse().join('.'), accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size, accountsFilterFormData.account_friend_id, accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
          let n=0;
          this.accountsData = []

          response.forEach( (element: any) => {
            this.accountsData[n] = element;
            this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
            n++;
          });
          this.isLoading = false
          this.numAccounts = this.accountsData.length
          for (let i = 0; i < this.accountsData.length; i++) {
            this.summ += this.accountsData[i].tarif.price;

          }
          console.log(response[0].age, '11111rewfreweewrtfgwer')
        },error=>{
          this.request.error(error)
          alert(error.message)
         })
    } else {
        this.isLoading = true
        this.request.getFilterAccountsRequest(accountsFilterFormData.id, accountsFilterFormData.fio, accountsFilterFormData.tarif_id, accountsFilterFormData.speed_cf, accountsFilterFormData.phone_number, accountsFilterFormData.ipaddress, accountsFilterFormData.acc_info, accountsFilterFormData.conf_firewall_id, accountsFilterFormData.passport, accountsFilterFormData.age, accountsFilterFormData.gender, accountsFilterFormData.device, accountsFilterFormData.overdraft, accountsFilterFormData.customr_type, accountsFilterFormData.comment, accountsFilterFormData.status, '', '', '', '', accountsFilterFormData.connect_by, accountsFilterFormData.contact_by, accountsFilterFormData.page_size, accountsFilterFormData.account_friend_id,  accountsFilterFormData.city_s, accountsFilterFormData.district_s, accountsFilterFormData.microdistrict_s, accountsFilterFormData.street_s, accountsFilterFormData.home_s, accountsFilterFormData.apartment_s,).subscribe( (response: any) => {
          let n=0;
          this.accountsData = []
          // ku bin

          var today = new Date()
          // const date2 = new Date('2023-04-27').getTime();
          for(let i = 0; i < response.length; i++){
            var tmp_date = new Date(response[i].end_date)
            console.log('efdseaffsaf')
            console.log('Vafoo:', today.getMonth() - tmp_date.getMonth()  >= 3 || today.getFullYear() - tmp_date.getFullYear() > 0, today.getMonth() - tmp_date.getMonth(), Math.abs(today.getFullYear() - tmp_date.getFullYear()) === 0)
            if (((today.getMonth() - tmp_date.getMonth())  >= 3 && Math.abs(today.getFullYear() - tmp_date.getFullYear()) === 0 )|| (today.getFullYear() - tmp_date.getFullYear()) > 0){
            response[i].account_status.status = "Архивный"

            }
          }

          response.forEach( (element: any) => {
            this.accountsData[n] = element;
            this.accountsData[n].fireWall4Table = this.fireWallData.filter( (res: any) => res.id ==  element.conf_firewall_id)[0];
            n++;
          });
          this.isLoading = false
          this.numAccounts = this.accountsData.length
          for (let i = 0; i < this.accountsData.length; i++) {
            this.summ += this.accountsData[i].tarif.price;

          }
          console.log(response[0].age, '11111rewfreweewrtfgwer')
        },error=>{
          this.request.error(error)
          alert(error.message)
         })
    }

  }
  changeTypedata() {
    this.typeData = 'date'
  }
  changeTypetext() {
    if(this.binding == ''){
      this.typeData = 'text'
    }else{
      this.typeData = 'date'
    }

  }
  changeAgeData(){
    this.ageDate = 'date'
  }
  changeAgeText(){
    if(this.age == ''){
      this.ageDate = 'text'
    }else{
      this.ageDate = 'date'
    }
  }
  changeStartData(){
    this.startData = 'date'
  }
  changeStartText(){
    if(this.start == ''){
      this.startData = 'text'
    }else{
      this.startData = 'date'
    }
  }
  changeAndCreateData(){
    this.andCreateData = 'date'
  }
  changeAndCreateText(){
    if(this.andCreate == ''){
      this.andCreateData = 'text'
    }else{
      this.andCreateData = 'date'
    }
  }
  changeStartCreateData(){
    this.StartCreateData = 'date'
  }
  changeStartCreateText(){
    if(this.StartCreate == ''){
      this.StartCreateData = 'text'
    }else{
      this.StartCreateData = 'date'
    }
  }
  addNewTable() {
    const accountsAddFormData = {...this.accountsAddForm.value}
    if(this.accountsAddForm.controls['ipaddress1'].value > 255 || this.accountsAddForm.controls['ipaddress2'].value > 255 || this.accountsAddForm.controls['ipaddress3'].value > 255 || this.accountsAddForm.controls['ipaddress4'].value > 255) {
      alert('Максимальное значение IP адресса не может превышать число 255')
    } else if( (this.accountsAddForm.controls['ipaddress1'].value == 255 && this.accountsAddForm.controls['ipaddress2'].value == 255 && this.accountsAddForm.controls['ipaddress3'].value == 255 && this.accountsAddForm.controls['ipaddress4'].value == 255) ) {
        alert('В IP адрессе не может быть 4 числа 255')
    } else {
        this.isLoading = true
        let ip_adress = `${this.accountsAddForm.controls['ipaddress1'].value}.${this.accountsAddForm.controls['ipaddress2'].value}.${this.accountsAddForm.controls['ipaddress3'].value}.${this.accountsAddForm.controls['ipaddress4'].value}`
        let age = new Date(accountsAddFormData.age)
        let end_date = new Date(accountsAddFormData.end_date)
        this.request.postAccountsRequest(accountsAddFormData.fio, accountsAddFormData.tarif_id, accountsAddFormData.price_cf, accountsAddFormData.speed_cf, accountsAddFormData.phone_number, end_date.toISOString(), ip_adress, accountsAddFormData.acc_info, accountsAddFormData.conf_firewall_id * 1, accountsAddFormData.passport, age.toISOString(), accountsAddFormData.gender, accountsAddFormData.device * 1, parseInt(accountsAddFormData.overdraft), accountsAddFormData.customr_type, accountsAddFormData.comment, accountsAddFormData.connect_by, accountsAddFormData.contact_by, accountsAddFormData.table_id * 1, accountsAddFormData.connect_type * 1, accountsAddFormData.promo_id * 1, accountsAddFormData.friend_id, accountsAddFormData.comment_1, accountsAddFormData.city_s,accountsAddFormData.district_s,accountsAddFormData.microdistrict_s,accountsAddFormData.street_s,accountsAddFormData.home_s,accountsAddFormData.apartment_s, accountsAddFormData.mac_addresss).subscribe(response => {
          this.isLoading = false
          // location.reload()
          // this.request.getNextIPRequest(accountsAddFormData.autoIpaddress).subscribe(response => {
          //   console.log(response);
          // },error=>{
          //   this.request.error(error)
          //  })
        }, error => {
          this.isLoading = false
            this.request.error(error)
          alert(error.error.Error)
        })
    }
  }

  openEditTable(id: number) {
    this.tableId = id
    this.editTable = true
    this.accountsEditForm.patchValue(this.accountsData.filter( (res: any) => res.id ==  id)[0])
    let age = new Date(this.accountsData.filter( (res: any) => res.id ==  id)[0].age)
    let ageFormat = age.toLocaleDateString().split('.')
    this.accountsEditForm.controls['age'].patchValue(`${ageFormat[2]}-${ageFormat[1]}-${ageFormat[0]}`)
    let end_date = new Date(this.accountsData.filter( (res: any) => res.id ==  id)[0].end_date)
    let endDateFormat = end_date.toLocaleDateString().split('.')
    this.accountsEditForm.controls['end_date'].patchValue(`${endDateFormat[2]}-${endDateFormat[1]}-${endDateFormat[0]}`)
    let activeItem = this.accountsData.filter( (res: any) => res.id ==  id)[0]
    let ip_adress = activeItem.ipaddress.split('.')
    this.accountsEditForm.controls['ipaddress1'].patchValue(ip_adress[0])
    this.accountsEditForm.controls['ipaddress2'].patchValue(ip_adress[1])
    this.accountsEditForm.controls['ipaddress3'].patchValue(ip_adress[2])
    this.accountsEditForm.controls['ipaddress4'].patchValue(ip_adress[3])
    this.accountsEditForm.controls['connect_by'].patchValue(activeItem.connect_user_id)
    this.accountsEditForm.controls['contact_by'].patchValue(activeItem.contact_user_id)
    this.accountsEditForm.controls['device'].patchValue(activeItem.device_type_id)
    this.accountsEditForm.controls['connect_type'].patchValue(activeItem.connect_type_id)
    this.accountsEditForm.controls['mac_addresss'].patchValue(activeItem.device_id)
  }

  editNewTable() {
    const accountsEditFormData = {...this.accountsEditForm.value}
    if(this.accountsEditForm.controls['ipaddress1'].value > 255 || this.accountsEditForm.controls['ipaddress2'].value > 255 || this.accountsEditForm.controls['ipaddress3'].value > 255 || this.accountsEditForm.controls['ipaddress4'].value > 255) {
      alert('Максимальное значение IP адресса не может превышать число 255')
    } else if( (this.accountsEditForm.controls['ipaddress1'].value == 255 && this.accountsEditForm.controls['ipaddress2'].value == 255 && this.accountsEditForm.controls['ipaddress3'].value == 255 && this.accountsEditForm.controls['ipaddress4'].value == 255) ) {
      alert('В IP адрессе не может быть 4 числа 255')
    } else {
      this.isLoading = true
      let ip_adress = `${this.accountsEditForm.controls['ipaddress1'].value}.${this.accountsEditForm.controls['ipaddress2'].value}.${this.accountsEditForm.controls['ipaddress3'].value}.${this.accountsEditForm.controls['ipaddress4'].value}`
      let age = new Date(accountsEditFormData.age)
      let end_date = new Date(accountsEditFormData.end_date)
      this.request.putAccountsRequest(this.tableId, accountsEditFormData.fio, accountsEditFormData.tarif_id, accountsEditFormData.price_cf, accountsEditFormData.speed_cf, accountsEditFormData.phone_number, end_date.toISOString(), ip_adress, accountsEditFormData.acc_info, accountsEditFormData.conf_firewall_id * 1, accountsEditFormData.passport, age.toISOString(), accountsEditFormData.gender, accountsEditFormData.device * 1, parseInt(accountsEditFormData.overdraft), accountsEditFormData.customr_type, accountsEditFormData.comment, accountsEditFormData.connect_by, accountsEditFormData.contact_by, accountsEditFormData.table_id * 1, accountsEditFormData.connect_type * 1, accountsEditFormData.promo_id * 1, accountsEditFormData.comment_1, accountsEditFormData.city_s,accountsEditFormData.district_s,accountsEditFormData.microdistrict_s,accountsEditFormData.street_s,accountsEditFormData.home_s,accountsEditFormData.apartment_s,accountsEditFormData.mac_addresss).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
    })
    }
  }

  deleteTable(id: number) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteAccountsRequest(id).subscribe(response => {
        this.isLoading = false
        location.reload()
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
          this.request.error(error)
      })
    } else {
      this.isLoading = false
    }
  }
  checkExpDate(date:string){
    return moment(date, moment.ISO_8601).isAfter(moment());
  }
  openUslugiModal(id: any) {
    this.serviceId = id
    this.uslugiModal = true
    this.isLoading = true
    this.request.getServiceLinkRequestByID(id).subscribe( (response: any) => {
      this.servicesLinkData = response

      console.log(response, '11111111111111111111111000000111 ====================>')
      this.isLoading = false
    },error=>{
      this.request.error(error)
     }

    )


    this.request.getServicesRequest().subscribe( (response: any) => {
      this.servicesData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }
  openRepostsModal(id: number) {
    this.serviceId = id
    this.rpostModal = true
    this.isLoading = true
    this.request.getReportsRequestByID(id).subscribe( (response: any) => {
      this.reportData = response
      console.log(response)
      this.isLoading = false

    })

    this.request.getReporteRequest().subscribe( (response: any) => {
      this.reportData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }
  showService(next_payment: any, expire_at: any){
    const expireAtDate = new Date(expire_at);
    const nextPaymentDate = new Date(next_payment);
    console.log(nextPaymentDate);
    console.log(expireAtDate);
    return nextPaymentDate<expireAtDate;
  }
  Service(id: any) {
    this.isLoading = true
    this.serviceId = this.servicesLinkData.id
    let deleteConf = confirm("Вы уверени что хотите Это сделать: " + id)
    if(deleteConf == true) {
      this.request.getServsRequestByID(id).subscribe(response => {
        this.isLoading = false
        this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
          location.reload();
          this.servicesLinkData = response
        })
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
          this.request.error(error)
      })

    }
    else {
      this.isLoading = false
    }
  }
  openPcketModal(id: any) {
    this.pcketId = id
    this.packetModal = true
    this.isLoading = true
    this.request.getPacketByID(id).subscribe( (response: any) => {
      this.packets = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })

    this.request.getPacketRequest().subscribe( (response: any) => {
      console.log(response)
      this.packetsData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }


  deletePacket(id: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteServiceLinkRequest(id).subscribe(response => {
        this.isLoading = false
        this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
          this.servicesLinkData = response
        })
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
          this.request.error(error)
      })
    } else {
      this.isLoading = false
    }
  }



  addNewService() {
    const addServiceFormData = {...this.addServiceForm.value}
    this.isLoading = true
    let created_at = new Date(addServiceFormData.created_at)
    this.request.posServiceLinkRequest(this.serviceId, created_at.toISOString(), addServiceFormData.name).subscribe(response => {
      this.isLoading = false
      this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
        this.servicesLinkData = response
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  addNewReporte() {
    const addNewReporteFormData = {...this.addRepostsForm.value}
    this.isLoading = true
    this.request.postReporteRequest(addNewReporteFormData.product_id, this.serviceId, addNewReporteFormData.count).subscribe(response => {
      this.isLoading = false
      this.request.getReportsRequestByID(this.serviceId).subscribe(response => {
        this.reportData = response
        this.rpostModal = false;
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.isLoading = false
      alert(error.error.Error)
        this.request.error(error)
    })
  }

  deleteService(id: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Лицевой Счёт: " + id)
    if(deleteConf == true) {
      this.request.deleteServiceLinkRequest(id).subscribe(response => {
        this.isLoading = false
        this.request.getServiceLinkRequestByID(this.serviceId).subscribe(response => {
          this.servicesLinkData = response
        },error=>{
          this.request.error(error)
         })
      }, error => {
        this.request.error(error)
        this.isLoading = false
        alert(error.error.Error)
      })
    } else {
      this.isLoading = false
    }
  }


  openPaymentModal(id: any) {
    this.serviceId = id
    this.paymentModal = true
  }

  addNewPayment() {
    const addPaymentFormData = {...this.addPaymentForm.value}
    this.isLoading = true
    this.request.postTransactions2Request(this.serviceId, addPaymentFormData.summa, addPaymentFormData.comment).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openCreditModal(id: any) {
    this.serviceId = id
    this.creditModal = true
    this.isLoading = true
    this.request.getcreditByID(id).subscribe( (response: any) => {
      this.creditData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }
  openTarifModal(id: any, tarif_id: any, status: any) {
    this.serviceId = id
    this.tarifId = tarif_id
    this.status  = status
    console.log(tarif_id, status)
    this.tarifModal = true

  }

  addNewCredit() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    console.log(expire_at.toISOString())
    this.request.postCreditRequest(this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  changeCredit(id: number) {
    this.editCredit = true
    let creditDataById = this.creditData.filter( (res: any) => res.id == id )[0]
    this.addCreditForm.patchValue(creditDataById)
    let expire_at = new Date(creditDataById.expire_at)
    let expire_atFormat = expire_at.toLocaleDateString().split('.')
    this.addCreditForm.controls['expire_at'].patchValue(`${expire_atFormat[2]}-${expire_atFormat[1]}-${expire_atFormat[0]}`)
    this.serviceId = creditDataById.id
  }



  addEditCredit() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.putCreditRequest(this.serviceId, this.serviceId, addCreditFormData.comment, addCreditFormData.expire_at).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
        this.editCredit = false
        this.addCreditForm.reset()
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openAddiitionalIpModal(id: any, firewallId: any) {
    this.serviceId = id
    this.firewallId = firewallId
    this.additionalGeneralIpModal = true
    this.isLoading = true
    this.request.getAdditionalIpRequests(id).subscribe( (response: any) => {
      this.additionalIpData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }
  openAddiitionalGeneralIpModal(id: any) {
    this.serviceId = id
    this.additionalIpModal = true

    this.isLoading = true
    this.request.getAdditionalIpRequests(id).subscribe( (response: any) => {
      this.additionalIpData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })
  }

  addNewAdditionalIp() {
    const addAdditionalIpFormData = {...this.addAdditionalIpForm.value}
    this.isLoading = true
    this.request.postAdditionalIpRequest(this.serviceId, addAdditionalIpFormData.ip,addAdditionalIpFormData.address,addAdditionalIpFormData.comment, addAdditionalIpFormData.mac_address).subscribe(response => {
      this.isLoading = false
      location.reload()
      this.request.getAdditionalIpRequest().subscribe( (response: any) => {
        this.additionalIpData = response
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  changeTarif() {
    const changeTarifs = {...this.changeTarifs.value}

    let end_date = new Date(changeTarifs.update_date)
    console.log(end_date)
    console.log('fffffffffffffffffffffffffff',this.serviceId, changeTarifs.tarifId, changeTarifs.status,end_date.toLocaleDateString(), changeTarifs.send_sms , changeTarifs.rever_tarif,changeTarifs.free,)
    this.isLoading = true
    this.request.postChangeTarifRequest(this.serviceId, changeTarifs.tarifId, end_date.toISOString(), this.status, changeTarifs.send_sms , changeTarifs.rever_tarif,changeTarifs.free,).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.isLoading = false
      this.request.error(error)
    })

  }

  updateAdditionalIp(id: any) {
    this.tableId = id
    this.editingIp = true
    this.modalIp = true
    this.updateAdditionalIpForm.patchValue(this.additionalIpData.filter( (res: any) => res.id ==  id)[0])
  }
  addAdditionalIpo() {
    this.editingIp = false
    this.modalIp = true;
  }
  updateIP(){
    const updateAdditionalIpFormData = {...this.updateAdditionalIpForm.value}
    this.request.putAccountsIpRequest(this.tableId,updateAdditionalIpFormData.address, updateAdditionalIpFormData.comment, updateAdditionalIpFormData.ip, updateAdditionalIpFormData.mac_address).subscribe(res=>{
      location.reload();
      console.log(res)
    }, err=> { alert(err)})
  }
  deleteAdditionalIp(id: any) {
    this.isLoading = true
    let deleteConf = confirm("Вы уверени что хотите удалить данный Ip: " + id)
    if(deleteConf == true) {
      this.request.deleteAdditionalIpRequest(id).subscribe(response => {
        this.isLoading = false
        this.request.getAdditionalIpRequest().subscribe(response => {
          this.additionalIpData = response
        },error=>{
          this.request.error(error)
         })
      }, error => {
        this.isLoading = false
        alert(error.error.Error)
        this.request.error(error)
      })
    } else {
      this.isLoading = false
    }
  }

  openAdditionalInfoModal(id: any) {
    this.serviceId = id
    this.additionalInfoModal = true
    this.isLoading = true
    this.request.getAdditionalInfoRequest().subscribe( (response: any) => {
      this.additionalInfoData = response
      this.isLoading = false
    },error=>{
      this.request.error(error)
     })

    // this.request.getUsersRequest().subscribe( (response: any) => {
    //   console.log(response);
    // })
  }

  addNewAdditionalInfo() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.postCreditRequest(this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  changeAdditionalInfo(id: number) {
    this.editCredit = true
    let creditDataById = this.creditData.filter( (res: any) => res.id == id )[0]
    this.addCreditForm.patchValue(creditDataById)
    let expire_at = new Date(creditDataById.expire_at)
    let expire_atFormat = expire_at.toLocaleDateString().split('.')
    this.addCreditForm.controls['expire_at'].patchValue(`${expire_atFormat[2]}-${expire_atFormat[1]}-${expire_atFormat[0]}`)
    this.serviceId = creditDataById.id
  }

  EditAdditionalInfo() {
    const addCreditFormData = {...this.addCreditForm.value}
    this.isLoading = true
    let expire_at = new Date(addCreditFormData.expire_at)
    this.request.putCreditRequest(this.serviceId, this.serviceId, addCreditFormData.comment, expire_at.toISOString()).subscribe(response => {
      this.isLoading = false
      this.request.getCreditRequest().subscribe( (response: any) => {
        this.creditData = response
        this.editCredit = false
        this.addCreditForm.reset()
      },error=>{
        this.request.error(error)
       })
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  openExtandsModal(id: any) {
    this.serviceId = id
    this.extandsModal = true
  }
  openPromotionModal(id: any) {
    this.serviceId = id
    this.promotionModal = true
  }

  openSharesModal(id: any, account_id : any) {
    this.serviceId = id
    this.accountId = account_id
    console.log(this.serviceId)
    this.sharesModal = true
    this.request.getshares(this.serviceId).subscribe(response =>{
    this.tarif = response
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  addNewExtands() {
    const addExtandsFormData = {...this.addExtandsForm.value}
    this.isLoading = true
    let end_date = new Date(addExtandsFormData.end_date)
    this.request.putExtensionRequest(this.serviceId, end_date.toISOString()).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  addNewPromotion() {
    const addPromotinFormData = {...this.promotionForm.value}
    console.log(this.serviceId, 'iddddddddd', addPromotinFormData.promo_id, 'iddddd_pro')
    this.request.postPromotioRequest(this.serviceId, +addPromotinFormData.promo_id).subscribe(res => {
      // location.reload()
    }, err => {
      this.request.error(err)
    })
  }

  refreshAccount(id: number) {
    this.isLoading = true
    this.request.getAccountRefresh(id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  lockAccountOff(id: number) {
    this.isLoading = true
    this.request.getAccountBlockOff(id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  lockAccountDisabledOpen(id: number) {
    this.disabled = true
    this.disabledId = id
  }
  lockAccountDisabled() {
    const disabledFormData = {... this.disabledForm.value}
    this.disabled = true
    this.isLoading = true
    this.request.getAccountDisabled(this.disabledId, disabledFormData.info).subscribe(response => {
      this.isLoading = false
      location.reload()
      console.log(response)
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }
  lockAccountOn(id: number) {
    this.isLoading = true
    this.request.getAccountBlockOn(id).subscribe(response => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      this.isLoading = false
      alert(error.error.Error)
    })
  }

  exportexcelAccount(): void {
      this.fileName = 'Аккаунт.xlsx'
       /* table id is passed over here */
       let element = document.getElementById('excel-table-account');
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       var wscols = [
        {wch:8},
        {wch:40},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:40},
        {wch:30},
        {wch:30},
        {wch:10},
        {wch:20},
        {wch:10},
        {wch:15},
        {wch:40},
        {wch:40},
        {wch:20},
        {wch:15},
    ];

    ws['!cols'] = wscols;

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);

    }

    addNewPacket(){
      const addPacketFormData = {...this.addPacketForm.value}
      this.request.postPacketRequest(this.pcketId, addPacketFormData.additional_traffic_id*1).subscribe(response =>
        {console.log(response)},error =>{
          this.request.error(error)
          alert(error.error.Error + ' !!!' )
        }
        )
    }
    addNewshares(){
      const addsharesFormdata = {...this.addsharesForm.value}
      this.request.postSharesRequest(this.accountId ,addsharesFormdata.tarif).subscribe(response =>{
        console.log(response)
        location.reload()
        },error =>{
          this.request.error(error)
          alert(error.error.Error + ' !!!' )
        }
        )
    }
    openInfoAccauntModal(age: any, gender: any, device_type: any, customr_type: any, connect_type: any, table_id: any, comment_1: any, city_s : any,district_s : any,microdistrict_s : any,street_s : any,home_s : any,apartment_s : any, ){
      this.infoAccauntModal = true
      console.log(
        age, "год рождения",
        gender, "пол",
        device_type, "дквайс",
        customr_type, "тип клиента",
        connect_type, "тип подключения",
        table_id, "тейбл ид",
        comment_1, "коментария",

      )

      this.age_modal = age
      this.gender_modal = gender
      this.device_type_modal = device_type
      this.customr_type_modal = customr_type
      this.connect_type_modal = connect_type
      this.table_id_modal = table_id
      this.comment_1_modal = comment_1
      this.city_s = city_s
      this.district_s = district_s
      this.microdistrict_s = microdistrict_s
      this.street_s = street_s
      this.home_s =home_s
      this.apartment_s = apartment_s
    }

    changeDeviceId(){

      const accountsAddFormData = {...this.accountsAddForm.value}


          this.request.getDevice(accountsAddFormData.mac_addresss).subscribe(res => {
              this.filteredSuggestions = res
              this.showSuggestionList = true
          });
      }
    addlistToDeviceId(deviceId: String){
      const accountsAddFormData = {...this.accountsAddForm.value}
      this.macAddress = deviceId
      accountsAddFormData.mac_addresss = deviceId
      console.log(accountsAddFormData.mac_addresss)
      this.showSuggestionList = false
    }
    changeIpMac(){

      const accountsAddFormData = {...this.addAdditionalIpForm.value}


          this.request.getDevice(accountsAddFormData.mac_address).subscribe(res => {
              this.filteredSuggestionsIP = res
              this.showSuggestionListIP = true
          }, error => {
            this.request.error(error)
          });
      }
      addlistToDeviceIdIpMac(deviceId: String){
        const accountsAddFormData = {...this.addAdditionalIpForm.value}
        this.macAddressIp = deviceId
        accountsAddFormData.mac_address = deviceId

        this.showSuggestionListIP = false
      }
      openIpinfoAccountModal(id: any){
        this.IpinfoAccountModal = true
        this.loaddingInfoAccountModal = true
        this.request.getInfoIpAccount(id).subscribe((res: any)=> {
          this.infoAccountArr = res
          this.loaddingInfoAccountModal = false



        }, err=> {
          this.request.error(err)
        })
      }
      clearPortId(id: any){
        this.request.postClearPort(id+'').subscribe((res: any)=> {
            alert('ok')
        }, err=> {
          this.request.error(err)
        })
    }
      clearPortMac(){
        this.request.postClearPortMac(this.mac+'').subscribe((res: any)=> {
            alert('ok')
        }, err=> {
          this.request.error(err)
        })
    }
  }


<div class="spinner_block" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div class="addModal_content" *ngIf="addTable">
    <div class="addModal_block">
      <i class="fas fa-times" (click)="addTable = false"></i>
      <form class="addForm">
        <input class="addtable" type="text" placeholder="Имя" formControlName="name">
        <button class="addButton">OK</button>
      </form>
    </div>
  </div>

  <div class="editModal_content" *ngIf="editTable">
    <div class="editModal_block">
      <i class="fas fa-times" (click)="editTable = false" ></i>
      <form class="editForm">
        <input class="addtable" type="text" placeholder="Имя" formControlName="name">
        <button class="editButton">ОК</button>
      </form>
    </div>
  </div>

  <div class="devide_block">
    <app-header></app-header>

    <div class="container">
      <div class="container_content">
        <div class="filter_content">
          <form class="filter_form">
            <div class="filter_block" [formGroup]="formRoutes">
              <input type="number" placeholder="ID" formControlName="id">
              <input type="text" placeholder="path" formControlName="path">
              <button (click)="addRoutes()">ОК</button>
            </div>

            <!-- <div class="pagination_block">
              <input type="number" placeholder="0">
            </div> -->
          </form>
        </div>

        <div class="table_container">
          <table class="resp-tab">
            <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th *ngIf="permitActions">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle class="c1" cx="10" cy="10" r="9.5" stroke="black"/>
                    <circle class="c2" cx="10" cy="10" r="3" fill="black"/>
                  </svg>
                </th>
              </tr>
              <tr *ngFor="let routes of routesFill">
                <td>{{routes.id}}</td>
                <td>{{routes.name}}</td>
              </tr>
            </thead>

          </table>
        </div>
        <div class="pagination_block">
          <pagination-controls nextLabel="Далее" previousLabel="Назад" (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>

<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Own report</h1>
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="accountsFilterForm">
            <input [type]="StartCreateData" formControlName="date_from" [(ngModel)]="StartCreate" placeholder="От" (focus)="changeStartCreateData()" (blur)="changeStartCreateText()">
            <input [type]="andCreateData" formControlName="date_to" (focus)="changeAndCreateData()" (blur)="changeAndCreateText()" [(ngModel)]="andCreate" placeholder="До">
            <button [disabled]="accountsFilterForm.invalid" [ngStyle]="{opacity: accountsFilterForm.invalid ? '.6' : '1'}" (click)="filterTable()">ОК</button>
          </div>

          <!-- <div class="pagination_block">
            <input type="number" placeholder="0">
          </div> -->
        </form>
      </div>
      <div class="table_container">
        <div class="reconciliation_content">
            <div class="reconciliation_title">
              <h1>Акт сверки</h1>
              <p>взаимных расчетов за период с 01.01.2022 по 29.05.2023 между Общество с ограниченной ответственностью "Нет Солюшенс" и ҶДММ "Таҳлил ва Машварат" по договору С покупателем</p>
            </div>
            <p>Мы, нижеподписавшиеся, ________________ Общество с ограниченной ответственностью "Нет Солюшенс", с одной стороны, <br>
              и ________________ ҶДММ "Таҳлил ва Машварат" _______________________, с другой стороны, <br>
              составили настоящий акт сверки в том, что состояние взаимных расчетов по данным учета следующее</p>
        </div>
        <table class="resp-tab">

          <thead>
            <tr>
              <th colspan="8">По данным Общество с ограниченной ответственностью "Нет Солюшенс", TJS</th>
              <th colspan="8">По данным ҶДММ "Таҳлил ва Машварат", TJS</th>
            </tr>
          </thead>
          <tbody>

            <tr >
              <td colspan="1" rowspan="1"><span class="table_span">Date</span>
                <h1>Дата</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Документ</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Дебет</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Кредит</h1>
              </td>
              <td colspan="1" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Дата</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Документ</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Дебет</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Кредит</h1>
              </td>
            </tr>
            <tr >
              <td colspan="1" rowspan="1"><span class="table_span" style="overflow-x: hidden;">Date</span>
                <h1>Сальдо на начало 1 января 2022 г.</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>600.00</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Кредит</h1>
              </td>
              <td colspan="1" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Сальдо на начало 1 января 2022 г.</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>600.00</h1>
              </td>
            </tr>
            <tr >
              <td colspan="1" rowspan="1"><span class="table_span" style="overflow-x: hidden;">Date</span>
                <h1>17.01.22</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Платежное поручение (входящее) 00000000025 от 17.01.2022</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>599.96</h1>
              </td>
              <td colspan="1" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>17.01.22</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Платежное поручение (входящее) 00000000025 от 17.01.2022</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>599.96</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
            </tr>
            <tr >
              <td colspan="1" rowspan="1"><span class="table_span" style="overflow-x: hidden;">Date</span>
                <h1>Обороты за период</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>4,800.00</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>5,400.15</h1>
              </td>
              <td colspan="1" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Обороты за период</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
            </tr>
            <tr >
              <td colspan="1" rowspan="1"><span class="table_span" style="overflow-x: hidden;">Date</span>
                <h1>Сальдо на конец 29 мая 2023 г.</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="1" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>0.15</h1>
              </td>
              <td colspan="3" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1>Сальдо на конец 29 мая 2023 г.</h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
              <td colspan="2" rowspan="1"><span class="table_span">Total Rev.B2C</span>
                <h1></h1>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="documentBottom_block">
          <div class="documentBottom_item">
            <p>По данным Общество с ограниченной ответственностью "Нет Солюшенс" <br> <span style="font-weight: bold;">на 29.05.2023 задолженность в пользу ҶДММ "Таҳлил ва Машварат" 0,15 <br> TJS (Ноль сомони 15 дирам)</span></p>
            <p>От Общество с ограниченной ответственностью "Нет Солюшенс" <br> ИНН 020047495</p>
            <pre>________________                   (_______________________)</pre>
            <pre>_________</pre>
            <pre>__________________________________ (_______________________)</pre>
            <p>М.П.</p>
          </div>
          <div class="documentBottom_item">
            <p>От ҶДММ "Таҳлил ва Машварат" <br> ИНН 040042707</p>
            <pre>________________                (_______________________)</pre>
            <pre>________________                (_______________________)</pre>
            <pre>___                              Руководитель</pre>
            <pre>_______________________________ (_______________________)</pre>
            <p>М.П.</p>
          </div>
        </div>
      </div>

        <!-- <div class="exel_container">
          <button style="padding: 5px; cursor: pointer;" (click)="exportexcelAccount()">export OwnReport</button>
        </div> -->


    </div>
  </div>
</div>

<div class="spinner_block" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="addModal_content" *ngIf="addTable">
  <div class="addModal_block">
    <i class="fas fa-times" (click)="addTable = false"></i>
    <form class="addForm" [formGroup]="deviceAddForm">
      <input type="text" placeholder="Логин" formControlName="login" class="addtable" style="margin-top: 150px;">
      <input type="text" placeholder="Имя" formControlName="first_name" class="addtable">
      <input type="text" placeholder="Фамилия" formControlName="last_name" class="addtable">
      <input type="text" placeholder="Отчество" formControlName="middle_name" class="addtable">
      <input type="text" placeholder="Пароль" formControlName="password" class="addtable">
      <input type="text" placeholder="Коментария" formControlName="comment" class="addtable">
      <input type="date" placeholder="Дата рождения" formControlName="birthday" class="addtable">
      <input type="text" placeholder="Место рождения" formControlName="birthplace" class="addtable">
      <input type="date" placeholder="Дата получения паспорта" formControlName="passport_issue_date" class="addtable">
      <input type="text" placeholder="Место получения паспорта" formControlName="passport_issue_place" class="addtable">
      <input type="text" placeholder="Номер паспорта" formControlName="passport_number" class="addtable">
      <input type="text" placeholder="Серийный номер паспорта" formControlName="passport_series" class="addtable">
      <input type="date" placeholder="Дата получения документа" formControlName="document_date" class="addtable">
      <input type="text" placeholder="Номер документа" formControlName="document_number" class="addtable">
      <input type="date" placeholder="Дата отключения" formControlName="till" class="addtable">
      <button class="addButton"  (click)="addNewTable()">OK</button>
    </form>
  </div>
</div>

<div class="editModal_content" *ngIf="editTable">
  <div class="editModal_block">
    <i class="fas fa-times" (click)="editTable = false" ></i>
    <form class="editForm" [formGroup]="deviceEditForm">
      <input type="text" placeholder="Логин" formControlName="login" class="addtable" style="margin-top: 150px;">
      <input type="text" placeholder="Имя" formControlName="first_name" class="addtable">
      <input type="text" placeholder="Фамилия" formControlName="last_name" class="addtable">
      <input type="text" placeholder="Отчество" formControlName="middle_name" class="addtable">
      <input type="text" placeholder="Пароль" formControlName="password" class="addtable">
      <input type="text" placeholder="Коментария" formControlName="comment" class="addtable">
      <input type="date" placeholder="Дата рождения" formControlName="birthday" class="addtable">
      <input type="text" placeholder="Место рождения" formControlName="birthplace" class="addtable">
      <input type="date" placeholder="Дата получения паспорта" formControlName="passport_issue_date" class="addtable">
      <input type="text" placeholder="Место получения паспорта" formControlName="passport_issue_place" class="addtable">
      <input type="text" placeholder="Номер паспорта" formControlName="passport_number" class="addtable">
      <input type="text" placeholder="Серийный номер паспорта" formControlName="passport_series" class="addtable">
      <input type="date" placeholder="Дата получения документа" formControlName="document_date" class="addtable">
      <input type="text" placeholder="Номер документа" formControlName="document_number" class="addtable">
      <!-- <input type="date" placeholder="Дата отключения" formControlName="till" class="addtable"> -->
      <button class="editButton"  (click)="editNewTable()">ОК</button>
    </form>
  </div>
</div>
<div class="editModal_content" *ngIf="newEditTable">
  <div class="editModal_block">
    <i class="fas fa-times" (click)="newEditTable = false" ></i>
    <form class="editForm" [formGroup]="deviceNewEditForm">
      <input type="date" placeholder="Дата отключения" formControlName="till" class="addtable">
      <button class="editButton"  (click)="newEditNewTable()">ОК</button>
    </form>
  </div>
</div>

<div class="devide_block">
  <app-header></app-header>

  <div class="container">
    <div class="container_content">
      <div class="title_block">
        <h1>Somon TV</h1>
        <button (click)="addTable = true" *ngIf="permitActions">Добавить</button>
        <!-- <div class="search_block">
          <i class="fa fas fa-search"></i>
          <input type="search" placeholder="Поиск" [(ngModel)]="search">
        </div> -->
      </div>

      <div class="filter_content">
        <form class="filter_form">
          <div class="filter_block" [formGroup]="deviceFilterForm">
            <input type="text" placeholder="Логин" formControlName="login">
            <input type="text" placeholder="Имя" formControlName="first_name">
            <input type="text" placeholder="Фамилия" formControlName="last_name">
            <input type="text" placeholder="Отчество" formControlName="middle_name">
            <input type="text" placeholder="Пароль" formControlName="password">
            <input type="text" placeholder="Коментария" formControlName="comment">
            <input type="date" placeholder="Дата рождения" formControlName="birthday">
            <input type="text" placeholder="Место рождения" formControlName="birthplace">
            <input type="date" placeholder="Дата получения паспорта" formControlName="passport_issue_date">
            <input type="text" placeholder="Место получения паспорта" formControlName="passport_issue_place">
            <input type="text" placeholder="Номер паспорта" formControlName="passport_number">
            <input type="text" placeholder="Серийный номер паспорта" formControlName="passport_series">
            <input type="date" placeholder="Дата получения документа" formControlName="document_date">
            <input type="text" placeholder="Номер документа" formControlName="document_number">
            <input type="date" placeholder="Дата отключения" formControlName="till">
            <button (click)="filterTable()">ОК</button>
          </div>

          <!-- <div class="pagination_block">
            <input type="number" placeholder="0">
          </div> -->
        </form>
      </div>

      <div class="table_container">
        <table class="resp-tab">
          <thead>
            <tr>
              <th>№</th>
              <th>Дата рождения</th>
              <th>Место рождения</th>
              <th>Комментария</th>
              <th>ID клиента</th>
              <th>Дата получения документа</th>
              <th>Номер документа</th>
              <th>Фамилия</th>
              <th>Имя</th>
              <th>Отчество</th>
              <th>Логин</th>
              <th>Дата получения паспорта</th>
              <th>Место получения паспорта</th>
              <th>Номер паспорта</th>
              <th>Серийный номер паспорта</th>
              <th>Пароль</th>
              <th>Персональный идентификатор</th>
              <th>Дата отключения</th>
              <th *ngIf="permitActions">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle class="c1" cx="10" cy="10" r="9.5" stroke="black"/>
                  <circle class="c2" cx="10" cy="10" r="3" fill="black"/>
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let device of deviceData"  [ngStyle]="{backgroundColor: device.deleted  ? 'red ' : ''}">
              <td >
                <h1>{{deviceData.indexOf(device)+1}} </h1>
              </td>
              <td><span>Дата рождения</span>
                <h1>{{ device.birthday | date: 'dd-MM-yyyy'}}</h1>
              </td>
              <td><span>Место рождения</span>
                <h1>{{ device.birthplace}}</h1>
              </td>

              <td><span>Комментария</span>
                <h1>{{ device.comment}}</h1>
              </td>
              <td><span>ID клиента</span>
                <h1>{{ device.customer_id}}</h1>
              </td>
              <td><span>Дата получения документа</span>
                <h1>{{ device.document_date | date: 'dd-MM-yyyy'}}</h1>
              </td>
              <td><span>Номер документа</span>
                <h1>{{ device.document_number}}</h1>
              </td>
              <td><span>Фамилия</span>
                <h1>{{ device.first_name}}</h1>
              </td>
              <td><span>Имя</span>
                <h1>{{ device.last_name}}</h1>
              </td>
              <td><span>Отчество</span>
                <h1>{{ device.middle_name}}</h1>
              </td>
              <td><span>Логин</span>
                <h1>{{ device.login}}</h1>
              </td>
              <td><span>Дата получения паспорта</span>
                <h1>{{ device.passport_issue_date | date: 'dd-MM-yyyy'}}</h1>
              </td>
              <td><span>Место получения паспорта</span>
                <h1>{{ device.passport_issue_place}}</h1>
              </td>
              <td><span>Номер паспорта</span>
                <h1>{{ device.passport_number}}</h1>
              </td>
              <td><span>Серийный номер паспорта</span>
                <h1>{{ device.passport_series}}</h1>
              </td>
              <td><span>Пароль</span>
                <h1>{{ device.password}}</h1>
              </td>
              <td><span>Персональный идентификатор</span>
                <h1>{{ device.personal_account_id}}</h1>
              </td>
              <td><span>Дата отключения</span>
                <h1>{{ device.till | date: 'dd-MM-yyyy'}}</h1>
              </td>

              <td *ngIf="permitActions"><span>Действия</span>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                 >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openEditTable(device.login, device.customer_id, device.personal_account_id)">
                    <mat-icon>edit</mat-icon>
                    <span>Изменить</span>
                  </button>
                  <button mat-menu-item (click)="openNewEditTable(device.login)">
                    <mat-icon>edit</mat-icon>
                    <span>Изменить дату окончания тарифа</span>
                  </button>

                  <button mat-menu-item (click)="deleteVlanRequest(device.login)">
                    <mat-icon>delete</mat-icon>
                    <span>Удалить</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination_block">
        <pagination-controls nextLabel="Далее" previousLabel="Назад" (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
  </div>
</div>

<div class="spinner_block" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  
  
  
  
  <div class="devide_block">
    <app-header></app-header>
    <div class="container">
      <div class="container_content">
        <div class="title_block">
          <h1>Заявка на смену тарифа</h1>
        </div>
  
        <div class="filter_content">
          <form class="filter_form">
            <div class="filter_block">
              <select name="" id="" placeholder="">
                <option value=""></option>
              </select>
              <select name="" id="" placeholder="">
                <option value=""></option>
              </select>
   
              <button>ОК</button>
            </div>
          </form>
        </div>
  
        <div class="table_container">
          <table class="resp-tab">
            <thead>
              <tr>
                <th>ЛС</th>
                <th>ФИО</th>
                <th>Тип</th>
                <th>Старый тариф</th>
                <th>Новый тариф</th>
                <th>Дата</th>
                <th>Статус</th>
               
              </tr>
            </thead>
            <tbody  >
                <tr *ngFor="let changeTarrif of changeTarrifs" >
                <td>
                    <h1>{{changeTarrif.account_id}}</h1>
                  </td>
    
                  <td>
                    <h1>{{changeTarrif.account.fio}}</h1>
                  </td>
    
                  <td>
                    <h1>{{changeTarrif.tarif.customr_type}}</h1>
                  </td>
    
                  <td>
                    <h1>{{changeTarrif.tarif_id}}</h1>
                  </td>
    
                  <td>
                    <h1>{{changeTarrif.tarif.name}}</h1>
                  </td>
    
                  <td>
                    <h1>{{changeTarrif.created_at | date}}</h1>
                  </td>
                  <td>
                    <h1 class="status" [style.background-color]="changeTarrif.status_id==2 ? 'green':  changeTarrif.status_id==1 ? 'yellow':  'red'">{{ changeTarrif.status_id?.status }}>{{changeTarrif.status_id==2 ? 'ON':  changeTarrif.status_id==1 ? 'ON':  'OFF'}}</h1>
                  </td>
                <td>
                  <button class="btn-close" (click)="tariffChange(changeTarrif.id, 2)" [disabled]="changeTarrif.status==2 || changeTarrif.status==0"><img src="./assets/img/ok.png" alt=""></button>
                </td>
                <td>
                    <button (click)="tariffChange(changeTarrif.id, 0)" [disabled]="changeTarrif.status==2 || changeTarrif.status==0"><img src="./assets/img/x.png" alt=""></button>
                </td>           
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination_block">
          <pagination-controls nextLabel="Далее" previousLabel="Назад"  (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
    </div>
    
  
  
</div>

  
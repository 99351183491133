import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RequestsService } from '../all.service';

@Component({
  selector: 'app-transaction-history-page',
  templateUrl: './transaction-history-page.component.html',
  styleUrls: ['./transaction-history-page.component.scss']
})
export class TransactionHistoryPageComponent implements OnInit {
  transactionHistoryData: any = []
  isLoading = false
  summPlus = 0
  summMinus = 0
  paymentModal = false
  addPaymentForm!: FormGroup
  serviceId: any
  constructor(private request: RequestsService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.addPaymentForm = new FormGroup({
      id: new FormControl(''),
      comment: new FormControl('')
    })
    if(localStorage.getItem('isDark')) {
      document.body.classList.remove('dark-theme')
    } else if(!localStorage.getItem('isDark')) {
      document.body.classList.toggle('dark-theme')
    }

    this.isLoading = true
    this.route.params.subscribe( (params: any) => {
      this.summPlus = 0
      this.summMinus = 0
      this.request.getFilterTransactionsRequest(params.id, '', '', '', '', '', '', '','').subscribe(response => {
        this.transactionHistoryData = response
        this.isLoading = false
        this.transactionHistoryData.forEach( (element: any) => {
          if(element.summ < 0 && element.status!=0) {
            this.summMinus -= -element.summ
          } else {
            this.summPlus += element.summ
          }
        });
      },error => {
        this.request.error(error)
      })
    })

  }
  cancelTransaction(id: any){

    this.serviceId = id
    console.log(id)
    this.paymentModal = true
  }
  addNewPayment(){
   const addPaymentFormData = {...this.addPaymentForm.value}
   this.request.cancelTransactionRequest(this.serviceId , addPaymentFormData.comment).subscribe( (response: any) => {
      this.isLoading = false
      location.reload()
    }, error => {
      this.request.error(error)
      console.log(error);
    })
  }
}
